
import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBriefcase, faBuilding, faEnvelope, faPhone, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
`;

const Row = styled.div`
  // display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
`;

const FormGroup = styled.div`
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
  ${'' /* font-family:"Poppins"; */}
  font-family:"Roboto";

`;

const InputWrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  padding-left: 30px;
  font-size: 16px;
  background: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: white;
    outline: none;
  }
`;
const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
`;
const Select = styled.select`
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  padding-left: 30px;
  font-size: 16px;
  background: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: white;
    outline: none;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  padding-left: 30px;
  resize: vertical;
  font-size: 16px;
  background: none;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    border-color: white;
    outline: none;
  }
`;

const ContactForm = () => {
  const [formData, setFormData] = useState({
    Name: '',
    Job: '',
    Company: '',
    Email: '',
    PhoneNumber: '',
    Country: '',
    AdditionalInformation: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const requiredFields = ['Name', 'Job', 'Company', 'Email'];
    const isEmptyField = requiredFields.some((field) => !formData[field]);

    if (isEmptyField) {
      window.alert('Please fill out all required fields before submitting.');
      return;
    }

    fetch('http://localhost:8081/candidate_contact_details', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === 'Form data inserted successfully') {
          window.alert('Form submitted successfully');
          setFormData({
            Name: '',
            Job: '',
            Company: '',
            Email: '',
            PhoneNumber: '',
            Country: '',
            AdditionalInformation: '',
          });
        } else {
          if (data.error) {
            window.alert(`Form submission failed: ${data.error}`);
          } else {
            window.alert('Form submission failed');
          }
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        window.alert('An error occurred during form submission');
      });
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <Row>
        <FormGroup>
          <Label className="color">
            <FontAwesomeIcon icon={faUser} /> Name
          </Label>
          <InputWrapper>
            <Input type="text"  style={{color:"white"}} name="Name" value={formData.Name} onChange={handleInputChange} />
          </InputWrapper>
        </FormGroup>
        <FormGroup>
          <Label className="color">
            <FontAwesomeIcon icon={faBriefcase} /> Job
          </Label>
          <InputWrapper>
            <Input type="text" style={{color:"white"}} name="Job" value={formData.Job} onChange={handleInputChange} />
          </InputWrapper>
        </FormGroup>
        <FormGroup>
          <Label className="color">
            <FontAwesomeIcon icon={faBuilding} /> Company
          </Label>
          <InputWrapper>
            <Input type="text"  style={{color:"white"}} name="Company" value={formData.Company} onChange={handleInputChange} />
          </InputWrapper>
        </FormGroup>
      </Row>
      <Row>
        <FormGroup>
          <Label className="color">
            <FontAwesomeIcon icon={faEnvelope} /> Email
          </Label>
          <Input type="email" style={{color:"white"}} name="Email" value={formData.Email} onChange={handleInputChange} />
        </FormGroup>
        <FormGroup>
          <Label className="color">
            <FontAwesomeIcon icon={faPhone} /> Phone
          </Label>
          <Input type="tel" style={{color:"white"}} name="PhoneNumber" value={formData.PhoneNumber} onChange={handleInputChange} />
        </FormGroup>
        <FormGroup>
          <Label className="color">
            <FontAwesomeIcon icon={faGlobe} /> Country
          </Label>
          <Input type="text" name="Country" style={{color:"white"}} value={formData.Country} onChange={handleInputChange}/>

        </FormGroup>
      </Row>


      <div className="text-center">
      <Link
        className="fusion-button button-flat fusion-button-default-size button-custom fusion-button-default button-1 fusion-button-default-span startNowButton custom-button"
        target="_self"
        to="/contact"
      >
        <span className="fusion-button-text">SUBMIT</span>
        <i className="fa-long-arrow-alt-right fas button-icon-right" aria-hidden="true"></i>
      </Link>
    </div>

    </FormContainer>
  );
};

export default ContactForm;

