import React from 'react'
import './Footer.css';
import Footer from './Footer';
const TermsOfUse = () => {
  return (
    <div pb-2 pt-2 style={{backgroundColor:"white"}} >
     <div class="container2">
  <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/footer/terms-of-use.jpg" alt="Snow" style={{height:"550px",width:"100%", transform: "skew-X(5deg)",paddingTop:"40px"}}/>
  <div class="centered1"> <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/Logos/rotate_Logo.gif" style={{width:"40%",margin:"40%"}}/></div>
  <div class="centered" style={{color:"white",fontSize:"50px",paddingTop:"2%"}}>TERMS OF USE</div>
</div> 

<div class="container" style={{backgroundColor:"#c1cce4"}}>
    <div class="row">
    <div class="mg-management">
    <h2>TERMS OF SERVICE</h2>
</div>
<div class="privacy-p">
<p>Welcome to sssbi.com (the “Website”) is an online platform through which users (“Users”) may use the services 
of our website to create, collate web services offered by sssbi.com ( ” The Service”) . By accepting or using 
the  service , you signify that you have read, to be the legal equivalent of a signed, written contract, and 
equally binding, understood and agree to be bound by the following terms of use (Terms ) and Conditions 
(“Agreement”) govern your use of our Service.</p>
<p>We reserve the right to change, alter or modify terms and conditions of the agreement at any time and such 
modifications shall be effective immediately upon the posting there of. Your continued usage of sssbi.com will
 keep you apprised of any changes in the agreement which constitutes the acceptance of changes. You agree to 
 review this agreement periodically ensures that you are aware of any 
 modifications done in this agreement.</p>
</div>
    </div>



    <div class="row">
<div class="mg-management"><h2>INTELLECTUAL PROPERTY & PROPRIETARY RIGHTS</h2></div>
<div class="privacy-p">
    <p>For the purpose of this agreement ” Intellectual Property rights” means all patent rights copyright 
        rights, work rights, moral rights, rights of publicity, trademark, trade dress and service mark rights, 
        goodwill, trade secret rights and other intellectual property rights as may now exist or hereafter come 
        into existence, and all applications therefore and registrations, renewals and extensions thereof, 
        under the laws of any state, country, territory or other jurisdiction.</p>
        <p>All material contained in this Service is protected by law, including, but not limited to, Indian 
            copyright law. Except as indicated, the entire content (including images, text, and look and feel 
            attributes) of sssbi.com  All rights reserved. Removing or altering the copyright notice on any 
            material on the Service is prohibited sssbi.com also owns a copyright service as a collective 
            work and/or compilation, and in the selection, coordination, arrangement, organization, and 
            enhancement of such content. Any commercial use of this content is prohibited without the prior 
            written consent of sssbi.com. All trademarks used at this web-site that are not the intellectual 
            property of sssbi.com are property of respective holders.</p>
    </div>
    </div>



    <div class="row">
<div class="mg-management"><h2>COMMUNICATIONS WITH OUR WEB SERVICE</h2></div>
<div class="privacy-p">
    <p>sssbi.com welcomes your feedback and suggestions about how to improve our Service. By transmitting 
        any suggestions, information, material, or other content (collectively, content) to sssbi.com, you 
        automatically grant Logic Software, Inc. the royalty-free, perpetual, irrevocable, non-exclusive 
        right and license to use, reproduce, modify, adapt, publish, translate, create derivative works 
        from, distribute, redistribute, transmit, perform, and display such content (in whole or part) 
        worldwide and/or to incorporate it in other works in any form, media, or technology now known 
        or later developed for the full term of any rights that may exist in such content. Further, 
        sssbi.com is free to use any ideas, concepts, know-how, techniques, and suggestions contained 
        in any communications you send to this Service for any purpose whatever, including but not 
        limited to creating and marketing products and/or services using such information.</p>
    </div>
    </div>



    <div class="row">
<div class="mg-management"><h2>NO SOLICITATION OR ADVICE</h2></div>
<div class="privacy-p">
    <p>This Service is designed to provide general information about sssbi.com and its products and services. 
        Information on the Service is not intended to constitute an offer to sell or a solicitation of any 
        particular product or service.</p>
    </div>
    </div>
 <div class="row">
<div class="mg-management"><h2>CONDUCT</h2></div>
<div class="privacy-p">
<p>You agree that you are not engaging in copying, distributing or disclosing any part of the service in any 
    medium. To upload, post or transmit any unlawful Content which is harmful, threatening, abusive, harassing, 
    defamatory, vulgar, obscene, libelous,invasive of other’s privacy, hateful, or racially, ethnically or 
    otherwise objectionable. Impersonate any person or entity, including, but not limited to, a sssbi.com 
    official, forum leader, guide or host, or falsely state or otherwise misrepresent your affiliation with 
    a person or entity. Uploading/ posting/transmitting any Content that you do not have a right to transmit 
    under any law or under contractual or fiduciary relationships (such as inside information, proprietary 
    and confidential information learned or disclosed as part of employment relationships or under nondisclosure 
    agreements).Uploading/ posting/transmitting any Content that infringes any patent, trademark, trade secret, 
    copyright or other proprietary rights of any party. Uploading/posting/transmitting any material that 
    contains software viruses or any other computer code, files or programs designed to interrupt, destroy 
    or limit the functionality of any computer software or hardware or telecommunications equipment. 
    Interfering or disrupting the Service or servers or networks connected to the Service, or disobey 
    any requirements, procedures, policies or regulations of networks connected to the Service. Violating 
    National/International laws applicable to local, state and nation intentionally or unintentionally. 
    Collecting or storing any personal information about other users. All the aspects of the service are 
    subject to change or elimination at our sole discretion sssbi.com reserves the right to interrupt the 
    service with or without prior notice for any reason or no reason. You agree that sssbi.com will not be 
    liable to you for any interruption of the Service, delay or failure to perform.</p>
    <p>You are solely responsible for your interactions with other sssbi.com Users. We reserve the right, 
        but have no obligation, to monitor disputes between you and other Users. We have no liability for
         your interactions with other users actions.</p>
    </div>
    </div>



    <div class="row">
<div class="mg-management"><h2>ERRORS AND OMISSIONS</h2></div>
<div class="privacy-p">
    <p>While we endeavour  to keep these materials up to date, sssbi.com cannot assume responsibility 
        for any errors or omissions in these materials. sssbi.com further does not warrant the accuracy 
        or completeness of the information, text, graphics, links or other items contained within these 
        materials. sssbi.com may make changes to these materials, or to the products or services described 
        herein, at any time without notice, and makes no commitment to update the information contained herein. 
        sssbi.com reserves the right to terminate your access to the Service in the event that you violate these 
        Terms and Conditions, or for any reason whatever.</p>
    </div>
    </div>



    <div class="row">
<div class="mg-management"><h2>INTERNET USAGE</h2></div>
<div class="privacy-p">
<p>Internet usage is solely at your own risk and is subject to all applicable provincial, national and 
    international laws and regulations. Neither sssbi.com. nor its affiliates will be liable for any loss 
    resulting from a cause over which they do not have direct control, including but not limited to failure 
    of electronic or mechanical equipment or communication lines, telephone or other interconnection problems, 
    computer viruses, unauthorized access, theft, operator errors, severe weather, earthquakes, natural 
    disasters, strikes or other labour problems, wars, or governmental restrictions..</p>
</div>
    </div>



    <div class="row">
<div class="mg-management"><h2>TERMS & TERMINATION OF SERVICE</h2></div>
<div class="privacy-p">
<p>sssbi.com services are subject to the terms and conditions of this Agreement, you are hereby granted 
a non-exclusive, limited, non-transferable, freely revocable, license to use the Service at all levels. 
You agree that in its sole discretion, may terminate your password, use of the Service or use of any other 
service, and remove and discard any Content within the Service, for any reason, including, without limitation, 
for lack of use or if we believe that you have violated or acted inconsistently with the letter or spirit of 
the Terms. We may also in its sole discretion and at any time discontinue providing the Service, or any part 
thereof, with or without notice. You agree that any termination of your access to the Service under any 
provision of this Terms may be effected without prior notice, and acknowledge and agree that we may 
immediately deactivate or delete all your information and files for further access to such files or 
the Service. Further, you agree that we are not be liable to you or any third-party for any termination 
of your access to the Service.</p>
</div>
    </div>



    <div class="row">
<div class="mg-management"><h2>DISCLAIMER OF WARRANTIES.</h2>
<h2>YOU EXPRESSLY UNDERSTAND AND AGREE THAT</h2>
</div>

<div class="privacy-p">
    <p>Your use of the service is at your sole risk. The service is provided on an “as is” and “as available” basis. Sssbi.com expressly disclaims all warranties of any kind, whether express or implied, including, but not limited
         to the implied warranties of merchantability, fitness for a particular purpose and non-infringement.</p>
    <p>Sssbi.com makes no warranty that the service will meet your requirements and doesn’t guarantee the 
        reliability, accuracy, timeliness, usefulness, adequacy, completeness or suitability of the service. 
        We does not warrant against human and machine errors, omissions, delays, interruptions or losses, 
        including loss of data. Sssbi.com does not guarantee or warrant that files available for downloading 
        from this online website will be free of infection by viruses, worms, trojan horses. This disclaimer 
        of warranty constitutes an essential part of this agreement.any material downloaded or otherwise 
        obtained through the use of the service is done at your own discretion and risk and that you will be 
        solely responsible for any damage to your computer system or loss of data that results from the 
        download of any such material. No advice or information, whether oral or written, obtained by you 
        from sssbi.com or through or from the service shall create any warranty not expressly stated in 
        the terms.</p>
    
    </div>
    </div>


    <div class="row">
<div class="mg-management"><h2>LIABILITY LIMITATION</h2></div>
<div class="privacy-p pb-4">
    <p>You expressly understand and agree that sssbi.com shall not be liable for any direct, 
        indirect, incidental, special, consequential or exemplary damages, including but not limited to, 
        damages for loss of profits, goodwill, use, data or other intangible losses 
        (even if sssbi.com has been advised of the possibility of such damages), resulting from: 
        (i) the use or the inability to use the service; 
        (ii) the cost of procurement of substitute goods and services resulting from any goods, data, 
        information or services purchased or obtained or messages received or transactions entered into 
        through or from the service; 
        (iii) unauthorized access to or alteration of your transmissions or data; 
        (iv) statements or conduct of any third party on the service; or 
        (v) any other matter relating to the service.</p>
    </div>
    </div>

    <div class="row">
<div class="mg-management"><h2>COPYRIGHT INFRINGEMENT</h2></div>
<div class="privacy-p pb-4">
    <p>All content included on this site, such as text, graphics, logos, button icons, images, audio clips, 
        video clips, digital downloads, data compilations, and software, is the property of sssbi.com or its 
        content suppliers and protected by india and international copyright laws. The compilation of all 
        content on this site is the exclusive property of sssbi.com and protected by india and international 
        copyright laws. All software used on this site is the property of sssbi.com or its software suppliers 
        and protected by india and international copyright laws.

</p>
    </div>
    </div>

    <div class="row">
<div class="mg-management"><h2>LEGAL COMPLIANCE AND GOVERNING LAW</h2></div>
<div class="privacy-p pb-4">
    <p>The terms and relationship between you and sssbi.com shall be governed by the laws without regard to 
        its conflict of law provisions. You and sssbi.com agree to submit to the personal and exclusive jurisdiction 
        of the courts located within the Court of India. The above terms and conditions shall be governed in 
        accordance with the statutes of India. The failure of sssbi.com to exercise or enforce any
         right or provision of the terms shall not constitute a waiver of such right or provision.</p>
    </div>
    </div>

    <div class="row">
<div class="mg-management"><h2>TRADEMARKS</h2></div>
<div class="privacy-p pb-4">
    <p>The logo, graphics, page headers, button icons, scripts, and service names are trademarks or trade dress 
        indicated on our site are trademarks of sssbi.com and hence it cannot be used in connection with any 
        product or service that is not sssbi.com , in any manner that is likely to cause confusion among users, 
        or in any manner that disparages or discredits our sites. All other trademarks not owned by us or its 
        affiliates that appear on this site are the property of their respective owners, who may or may not be 
        affiliated with, connected to, or sponsored by sssbi.com</p>
    </div>
    </div>
          </div>
          <br/>
<Footer />
    </div>
  )
}

export default TermsOfUse;
