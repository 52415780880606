import React from 'react'
import './Solutions.css';
import Footer from '../components/Footer';

const BiPlatform = () => {
  return (
      <div>
          
          <img src="../images/solutions/Data-Science-Service.jpg" alt="Nature" class="service-header-img"/>
        <div class="container-fluid">
  {/* <img src="../images/solutions/Data-Science-Service.jpg" alt="Nature" class="service-header-img"/> */}
  <div class="text-whitedata">
  <h1 class="text-size-head-white">BI Platform
Migration<br/>
Service</h1>
<h4 >A well-planned and executed migration strategy can help organizations <br/>
transition to a more advanced and feature-rich BI platform with<br/>
 minimal disruption and maximum benefit.</h4>
  </div>
</div>

<div class="container">
    <div class="row" style={{color:"rgb(125, 136, 155)",marginTop:"40px"}}>
      <div class="insight-matter"> <h1>What we do</h1>
        <p>SSS Business Intelligence platform migration solution is designed to ensure that data is 
            migrated accurately, without data loss, and with minimal disruption to business operations. ​​​​​</p>
    {/* <p>SSS Data Science as a Service (DSaaS) program helps organizations gain access to advanced analytics
     technologies, reduce costs, and accelerate their data-driven decision-making processes..​</p> */}
    </div> </div> 
</div>
<div class="container">
<div class="row">
    <div class="solutions-head">
        <h1>Key Features</h1>
    </div>
    <div class="col-lg-3"><p class="key-features-p">Analysis of existing<br/> environment​​</p></div>
    <div class="col-lg-3"><p class="key-features-p">Migration planning <br/>and configuration​​​</p></div>
    <div class="col-lg-3"><p class="key-features-p">Data & applications<br/> migration​​​</p></div>
    <div class="col-lg-3"><p class="key-features-p">Monitoring &<br/> optimization​​</p></div>
</div>
</div>

<div class="container-fluid key-benefits-bg-color">
    <div class="row">
        <div class="solutions-head">
            <h1>Key Benefits</h1>
        </div>
        <div class="col-md-3 key-margin3 row-center">
            <img src="./images/solutions/Improve-Data-Quality.png" alt="" class="key-benifits-img3"></img>
            <p class="key-benifits-p">Improved data quality​​</p>
        </div>
        <div class="col-md-3 key-margin3 row-center">
        <img src=" ./images/solutions/Increase-Efficiency.png" alt="" class="key-benifits-img3"></img>
        <p class="key-benifits-p">Increased functionality​​​</p>
        </div>
        <div class="col-md-3 key-margin3 row-center">
        <img src="./images/solutions/Better-User-Experience.png" alt="" class="key-benifits-img3"></img>
        <p class="key-benifits-p">Better user experience​</p>
        </div>
        <div class="col-md-3 key-margin3 row-center">
        <img src="./images/solutions/enhanced-security.png" alt="" class="key-benifits-img3"></img>
        <p class="key-benifits-p">Enhanced security​​​</p>
        </div>
        <div class="col-md-3 key-margin3 row-center">
        <img src="./images/solutions/lower-costs.png" alt="" class="key-benifits-img3"></img>
        <p class="key-benifits-p">Lower costs​​​​</p>
        </div>
        
        <div class="col-md-3 key-margin3 row-center">
        <img src="./images/solutions/Scalability.png" alt="" class="key-benifits-img3"></img>
        <p class="key-benifits-p">Scalability​​​​</p>
        </div>
        <div class="col-md-3 key-margin3 row-center">
        <img src="./images/solutions/better-integration.png" alt="" class="key-benifits-img3"></img>
        <p class="key-benifits-p">Better integration​​​​</p>
        </div>
    </div>
          </div>
          

           <Footer/>
      
    </div>
  )
}

export default BiPlatform
