import { useState } from 'react';
import ReactSimplyCarousel from 'react-simply-carousel';
import './Carousel.css';

function DevopsServicesCarousel() {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <div>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        forwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '30px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`>`}</span>,
        }}
        backwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '20px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`<`}</span>,
        }}
        responsiveProps={[
          {
            itemsToShow: 3,
            itemsToScroll: 3,
            minWidth: 780,
          },
        ]}
        speed={400}
        easing="linear"
      >

        <div style={{ width: 310, height: 650 }}>
        <div class="card bi-card-size">
                            <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/devops_services/Consulting-Strategy.png" class="rpa-img-top" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title text-body-color-black">DevOps Strategy <br/>Consulting</h5>
                                <ul class="card-text">
                                    <li>DevOps roadmap development</li>
                                    <li>Identification of key DevOps areas</li>
                                    <li>Customized DevOps solution design</li>
                                    <li>Alignment of DevOps goals with business objectives</li>
                                    <li>Assessment of existing processes and tools</li>
                                </ul>
                            </div>
                        </div>
        </div>
        <div style={{ width: 310, height: 650}}>
        <div class="card bi-card-size">
                            <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/devops_services/deployment.png" class="rpa-img-top" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title text-body-color-black">Continuous Integration<br/> and Deployment</h5>
                                <ul class="card-text">
                                    <li>Implementation of CI/CD pipelines</li>
                                    <li>Automated testing and validation</li>
                                    <li>Efficient code integration and merging</li>
                                    <li>Automated deployment to various environments</li>
                                    <li>Version Control System (VCS) Usage</li>
                                </ul>
                            </div>
                        </div>
        </div>
        <div style={{ width: 310, height: 650}}>
        <div class="card bi-card-size">
                            <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/devops_services/Automation.png" class="rpa-img-top" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title text-body-color-black">Infrastructure<br/> Automation</h5>
                                <ul class="card-text">
                                    <li>Infrastructure as Code (IaC) implementation</li>
                                    <li>Automated provisioning of resources</li>
                                    <li>Configuration management and version control</li>
                                    <li>Scalable and resilient infrastructure design</li>
                                    <li>Compliance and Security Automation</li>
                                </ul>
                            </div>
                        </div>
        </div>
        <div style={{ width: 310, height: 650 }}>
        <div class="card bi-card-size">
                            <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/devops_services/devops-service.png" class="rpa-img-top" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title text-body-color-black">DevOps as a<br/> Service</h5>
                                <ul class="card-text">
                                    <li>Full-stack DevOps support and management</li>
                                    <li>24/7 monitoring and incident response</li>
                                    <li>Regular performance optimization</li>
                                    <li>Security and compliance enforcement</li>
                                    <li>Continuous improvement and innovation</li>
                                </ul>
                            </div>
                        </div>
        </div>
        <div style={{ width: 310, height: 650}}>
        <div class="card bi-card-size">
                            <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/devops_services/devops-training.png" class="rpa-img-top" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title text-body-color-black">DevOps Training and Workshops</h5>
                                <ul class="card-text">
                                    <li>Customized DevOps training programs</li>
                                    <li>Hands-on workshops and simulations</li>
                                    <li>Toolchain familiarization and best practices</li>
                                    <li>Team collaboration and communication enhancement</li>
                                    <li>Foundational Concepts</li>
                                </ul>
                            </div>
                                              </div>
        </div>
        <div style={{ width: 310, height: 650 }}>
        <div class="card bi-card-size">
                            <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/devops_services/infrastructure.png" class="rpa-img-top" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title text-body-color-black">Infrastructure<br/> Automation</h5>
                                <ul class="card-text">
                                    <li>Infrastructure as Code (IaC) implementation</li>
                                    <li>Automated provisioning of resources</li>
                                    <li>Configuration management and version control</li>
                                    <li>Scalable and resilient infrastructure design</li>
                                    <li>Error Handling and Logging</li>
                                    <li>Testing and Validation</li>
                                </ul>
                            </div>
                        </div>
        </div>

      </ReactSimplyCarousel>
    </div>
  );
}

export default DevopsServicesCarousel;
