import { useState } from 'react';
import ReactSimplyCarousel from 'react-simply-carousel';
import './Carousel.css';

function CloudMigrationCarousel1() {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <div>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        forwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '30px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`>`}</span>,
        }}
        backwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '20px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`<`}</span>,
        }}
        responsiveProps={[
          {
            itemsToShow: 3,
            itemsToScroll: 1,
            minWidth: 768,
          },
        ]}
        speed={400}
        easing="linear"
      >
        {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}
        <div style={{ width: 300, height: 550 }}>
        <div class="card-body cloud-migration-card-body-colors" >
              <h5 class="card-title">Cloud Migration Roadmap Strategy Program​ </h5>
              <ul class="card-text1">
              <li>Assessment and Planning​</li>
              <li>Cloud Platform Selection</li>
              <li>Architecture and Design​</li>
              <li>Data Migration Strategy</li>
              <li> Application Refactoring and Optimization​</li>
              <li>Security and Compliance Planning</li>
              <li> Post-Migration Support and Optimization</li>
              <li>Testing and Validation</li>
              </ul>
            </div>
            </div>
        <div style={{ width: 300, height: 550}}>
        <div class="card-body cloud-migration-card-body-colors" >
            <h5 class="card-title">Cloud Migration Platform Implementation Program​</h5>
              <ul class="card-text1">
              <li>Reduce risk by migrating applications</li>
              <li>Utilize IaaS, PaaS, DWaaS, SaaS</li>
              <li>Fully flexible and scaleable solutions</li>
              <li>Configure, deploy, and migrate data​</li>
              <li>Ensure data quality, validation​</li>
              <li>Application Deployment and Configuration​</li>
              
                
                </ul>
            </div>
        </div>
        <div style={{ width: 300, height: 550}}>
        <div class="card-body cloud-migration-card-body-colors"  >
             
              <h5 class="card-title">Cloud Migration Hypercare Program​ </h5>
              <ul class="card-text1">
              <li>Immediate Issue Resolution​</li>
              <li>Monitoring and Performance Tuning​</li>
              <li>Communication and Stakeholder Management​</li>
              <li>Post-Migration Optimization,</li>
              <li>Documentation and Knowledge Transfer​</li>
              <li>On-demand experienced resource availability​</li>
              <li>Continuous Monitoring </li>

                </ul>
               </div>
        </div>
        <div style={{ width: 300, height: 550 }}>
        <div class="card-body cloud-migration-card-body-colors">
            <h5 class="card-title">Cloud Migration Platform Implementation Program​</h5>
              <ul class="card-text1">
              <li>Reduce risk by migrating applications</li>
              <li>Utilize IaaS, PaaS, DWaaS, SaaS</li>
              <li>Fully flexible and scaleable solutions</li>
              <li>Configure, deploy, and migrate data​</li>
              <li>Ensure data quality, validation​</li>
              <li>Application Deployment and Configuration​</li>
             
                
                </ul>
            </div>
        </div>
        <div style={{ width: 300, height: 550}}>
        <div class="card-body cloud-migration-card-body-colors">
             
              <h5 class="card-title">Cloud Migration Hypercare Program​ </h5>
              <ul class="card-text1">
              <li>Immediate Issue Resolution​</li>
              <li>Monitoring and Performance Tuning​</li>
              <li>Communication and Stakeholder Management​</li>
              <li>Post-Migration Optimization,</li>
              <li>Documentation and Knowledge Transfer​</li>
              <li>On-demand experienced resource availability​</li>
              <li>Continuous Monitoring </li>

                </ul>
            </div>

        </div>
        <div style={{ width: 300, height: 550 }}>
        <div class="card-body cloud-migration-card-body-colors">
            <h5 class="card-title">SSS Process Delivers Market-leading Cloud Migration Solutions​</h5>
              <ul class="card-text1">
             <li>Assessment questionnaire, adoption readiness report</li>
             <li>Migration options, Migration plan​</li>
             <li>Agile process, migration  teams</li>
             <li>Intial migration, provisioning</li>
             <li>Product migration, Optimization​</li>
             <li>Issue resolutions, training ops teams </li>
          
          

              </ul>
           </div>
        </div>
    
      </ReactSimplyCarousel>
    </div>
  );
}

export default CloudMigrationCarousel1;