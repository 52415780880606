



import React from 'react';
import './OurValues.css';
import Footer from '../components/Footer';
import ContactUss from './ContactUss';

const OurValues = () => {
  return (
      <div>
     
     
      <div className='container'>
        {/* <div className='text-center'>
           <h1 className='text-center' style={{ color: "blue", fontSize: "30px" }}>
            <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/Logos/rotate_Logo.gif" style={{width:"8%"}} />  OUR VALUES
        </h1> </div> */}
        <div className="bi-service">

<h1>
<img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/Logos/rotate_Logo.gif" style={{width:"8%"}} />
 Our Values
</h1>
</div>
               <div className="row" style={{padding:"10px"}}>
      <div className="col-md-4 values mb-1">
        <img
          decoding="async"
          width="100"
          height="100"
          title="1"
          src="https://sssbi-images.s3.ap-south-1.amazonaws.com/Values/enterprise.jpg"
          alt=""
        style={{marginLeft:"30%",}}
        />
      
      <div className="text">
        <h3 className="text-center mb-1 ">
          <p className='heading'>Entrepreneurial Spirit</p>
         
        </h3>
      </div>
      <div className="text">
        
        
              
               <p className="text-centers ">
         We stimulate progress by granting our team the autonomy to make choices,gain insights from setbacks, embrace uncertainty, and question conventional norms.
        </p>
      </div>
                  </div>
                  
                  <div className="col-md-4 values mb-1">
        <img
          decoding="async"
          width="100"
          height="100"
          title="1"
          src="https://sssbi-images.s3.ap-south-1.amazonaws.com/Values/person.jpg"
          alt=""
        style={{marginLeft:"30%",}}
        />
      


      <div className="text">
        <h3 className="text-center ">
          <p className='heading'>Passion for Everything We Do</p>
         
        </h3>
      </div>
      <div className="text">
       
              
<p className='text-centers'>
              Our determination and passion propel us onward, igniting our dedication to achieving the highest standards of excellence.</p>
                      </div>
                  
                      
                  </div>
                   <div className="col-md-4 values mb-1">
        <img
          decoding="async"
          width="100"
          height="100"
          title="1"
          src="https://sssbi-images.s3.ap-south-1.amazonaws.com/Values/knowledge.jpg"
          alt=""
        style={{marginLeft:"30%",}}
        />
      
      <div className="text ">
        <h3 className="text-center ">
          <p className='heading'>Commitment to Knowledge</p>
         
                           
                              
 

        </h3>
      </div>
      <div className="text ">
        
              
              <p className="text-centers">
         We flourish through perpetual learning, embracing technology, domain expertise, and all other facets of knowledge acquisition.
        </p>
      </div>
                  </div>
                  
                  
              </div>
        
              <div className="row" style={{padding:"8px"}}>
      <div className="col-md-4 values mb-1">
        <img
          decoding="async"
          width="100"
          height="100"
          title="1"
          src="https://sssbi-images.s3.ap-south-1.amazonaws.com/Values/respect.jpg"
          alt=""
        style={{marginLeft:"30%",}}
        />
       

      <div className="text" >
        <h3 className="text-center">
          <p className='heading'>Respect for Individuals</p>
          {/* <br />
          <p className='heading'>Individuals</p> */}
        </h3>
      </div>
      <div className="text">
  
              
              <p className="text-centers">
        At the heart of our work culture lies inclusivity, where we cherish and honor the distinctiveness of each individual.
        </p>
      </div>
                  </div>

                  <div className="col-md-4 values mb-1">
        <img
          decoding="async"
          width="100"
          height="100"
          title="1"
          src="https://sssbi-images.s3.ap-south-1.amazonaws.com/Values/Honesty.jpg"
          alt=""
        style={{marginLeft:"30%",}}
        />
       

      <div className="text" >
        <h3 className="text-center">
        <p className='heading'>Integrity and Honesty </p>
          {/* <br />
          <p className='heading'>Individuals</p> */}
        </h3>
      </div>
      <div className="text">
  
              
      <p className="text-centers" style={{textAlign:"left"}}>
        Integrity is our guiding principle in every interaction, whether with customers, colleagues, or the entire ecosystem.
        </p>
      </div>
                  </div>
          
                  <div className="col-md-4 values mb-1">
        <img
          decoding="async"
          width="100"
          height="100"
          title="1"
          src="https://sssbi-images.s3.ap-south-1.amazonaws.com/Values/positive.jpg"
          alt=""
        style={{marginLeft:"30%",}}
        />
       

      <div className="text" >
        <h3 className="text-center">
        <p className='heading'> Positive and Family Spirit</p>
          {/* <br />
          <p className='heading'>Individuals</p> */}
        </h3>
      </div>
      <div className="text">
  
      <p className="text-centers">
        We take delight in fostering a sense of fun and camaraderie within our team, extending this spirit to include SSSians, clients, and everyone who is part of our SSS family.
        </p>
      </div>
                  </div>
                  {/* <ContactUs/> */}
                  
              </div>
      </div>
      
      </div>

  )
}

export default OurValues
