import React from 'react'
import './Solutions.css';
import Footer from '../components/Footer';

const SupplyChain = () => {
  return (
    <div>
        {/* <div class="container-fluid"> */}
  <img src="../images/solutions/risk-management.jpg" alt="Nature" class="service-header-img"/>
  <div class="text-whitedata">
  <h1 class="text-size-head-white">Supply Chain Risk<br/>
Analytics</h1>
<h4 >Empower Agility with Supply Chain Risk Insights</h4>
  </div>
{/* </div> */}

<div class="container">
    <div class="row" style={{color:"rgb(125, 136, 155)",marginTop:"40px"}}>
      <div class="insight-matter"> <h1>What we do</h1>
        <p>It is crucial for organizations to understand the current health of their multi-tier partner network and to
             timely identify dependencies & possible threats around such network before they become critical. ​​​​​</p>
    <p>SSS supply chain risk analytics solution enables companies to monitor potential risk to their businesses including financial risk,
         reputational risk, transportation risk or risks due to natural calamity and geopolitical factors.​</p>
    </div> </div> 
</div>
<div class="container">
<div class="row">
    <div class="solutions-head">
        <h1>Our Key Aspects of Supply Chain Risk</h1>
    </div>
    <div class="col-lg-3"><p class="key-features-p">Risk
drivers​​</p></div>
    <div class="col-lg-3"><p class="key-features-p">Risk
impact​​​</p></div>
    <div class="col-lg-3"><p class="key-features-p">Risk
data sources​​​</p></div>
    <div class="col-lg-3"><p class="key-features-p">Risk
management​​</p></div>
</div>
</div>

<div class="container-fluid key-benefits-bg-color">
    <div class="row">
        <div class="solutions-head">
            <h1>Key Benefits</h1>
        </div>
        <div class="col-md-3 key-margin3 row-center">
            <img src=" ./images/solutions/tax.png" alt="" class="key-benifits-img3"></img>
            <p class="key-benifits-p">Rich-taxonomy​</p>
        </div>
        <div class="col-md-3 key-margin3 row-center">
        <img src=" ./images/solutions/Risk-scoring-methodology.png" alt="" class="key-benifits-img3"></img>
        <p class="key-benifits-p">Risk scoring methodology</p>
        </div>
        <div class="col-md-3 key-margin3 row-center">
        <img src="./images/solutions/Expert-Team.png" alt="" class="key-benifits-img3"></img>
        <p class="key-benifits-p">expert-team​</p>
        </div>
        <div class="col-md-3 key-margin3 row-center">
        <img src="./images/solutions/api.png" alt="" class="key-benifits-img3"></img>
        <p class="key-benifits-p">Third party API engagements​​​</p>
        </div>
    </div>
</div>
       <Footer/>
    </div>
  )
}

export default SupplyChain
