// import React, { useEffect, useState } from 'react';
// import './Sliders.css'; // Import your CSS file if you have styling

// const Sliders = () => {
//   const [currentSlide, setCurrentSlide] = useState(0);
//   const [touchStartPos, setTouchStartPos] = useState(0);

//   const testimonials = [
//     {
//       imgSrc: 'https://in.bmscdn.com/iedb/artist/images/website/poster/large/kartik-aaryan-1045198-08-12-2017-06-34-11.jpg',
//       name: 'Lorem P. Ipsum',
//       content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.'
//     },
//     // Add other testimonials in a similar structure
//   ];

//   useEffect(() => {
//     const testimTimer = setTimeout(() => {
//       playSlide(currentSlide + 1);
//     }, 4500);

//     return () => clearTimeout(testimTimer);
//   }, [currentSlide]);

//   const playSlide = (slide) => {
//     setCurrentSlide((prevSlide) => (slide < 0 ? testimonials.length - 1 : slide % testimonials.length));
//   };

//   const handleArrowClick = (direction) => {
//     playSlide(direction === 'left' ? currentSlide - 1 : currentSlide + 1);
//   };

//   const handleDotClick = (index) => {
//     playSlide(index);
//   };

//   const handleTouchStart = (e) => {
//     setTouchStartPos(e.changedTouches[0].clientX);
//   };

//   const handleTouchEnd = (e) => {
//     const touchEndPos = e.changedTouches[0].clientX;
//     const touchPosDiff = touchStartPos - touchEndPos;

//     if (touchPosDiff > 0 + 30) {
//       handleArrowClick('right');
//     } else if (touchPosDiff < 0 - 30) {
//       handleArrowClick('left');
//     }
//   };

//   return (
//     <div className="container">
//       <section id="testim" className="testim" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
//         <div className="testim-cover">
//           <div className="wrap">
//             <span id="right-arrow" className="arrow right fa fa-chevron-right" onClick={() => handleArrowClick('right')}></span>
//             <span id="left-arrow" className="arrow left fa fa-chevron-left" onClick={() => handleArrowClick('left')}></span>
//             <ul id="testim-dots" className="dots">
//               {testimonials.map((_, index) => (
//                 <li key={index} className={`dot ${index === currentSlide ? 'active' : ''}`} onClick={() => handleDotClick(index)}></li>
//               ))}
//             </ul>
//             <div id="testim-content" className="cont">
//               {testimonials.map((testimonial, index) => (
//                 <div key={index} className={index === currentSlide ? 'active' : ''}>
//                   <div className="img"><img src={testimonial.imgSrc} alt="" /></div>
//                   <h2>{testimonial.name}</h2>
//                   <p>{testimonial.content}</p>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Sliders;



// import React from 'react'
// import './Sliders.css'
// const Sliders = () => {
//   return (
//     <div>


// <div class="container">
//         <div class="row">
//             <div class="col-md-12">
//                 <div class="carousel slide" data-ride="carousel" id="quote-carousel">
                  
//                     <div class="carousel-inner text-center">
                       
//                         <div class="item active">
//                             <blockquote>
//                                 <div class="row">
//                                     <div class="col-sm-8 col-sm-offset-2">
//                                         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. !</p>
//                                         <small>Someone famous</small>
//                                     </div>
//                                 </div>
//                             </blockquote>
//                         </div>
                       
//                         <div class="item">
//                             <blockquote>
//                                 <div class="row">
//                                     <div class="col-sm-8 col-sm-offset-2">
//                                         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
//                                         <small>Someone famous</small>
//                                     </div>
//                                 </div>
//                             </blockquote>
//                         </div>
                     
//                         <div class="item">
//                             <blockquote>
//                                 <div class="row">
//                                     <div class="col-sm-8 col-sm-offset-2">
//                                         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. .</p>
//                                         <small>Someone famous</small>
//                                     </div>
//                                 </div>
//                             </blockquote>
//                         </div>
//                     </div>
                   
//                     <ol class="carousel-indicators">
//                         <li data-target="#quote-carousel" data-slide-to="0" class="active"><img class="img-responsive " src="https://s3.amazonaws.com/uifaces/faces/twitter/mantia/128.jpg" alt=""/>
//                         </li>
//                         <li data-target="#quote-carousel" data-slide-to="1"><img class="img-responsive" src="https://s3.amazonaws.com/uifaces/faces/twitter/adhamdannaway/128.jpg" alt=""/>
//                         </li>
//                         <li data-target="#quote-carousel" data-slide-to="2"><img class="img-responsive" src="https://s3.amazonaws.com/uifaces/faces/twitter/brad_frost/128.jpg" alt=""/>
//                         </li>
//                     </ol>

                  
//                     <a data-slide="prev" href="#quote-carousel" class="left carousel-control"><i class="fa fa-chevron-left"></i></a>
//                     <a data-slide="next" href="#quote-carousel" class="right carousel-control"><i class="fa fa-chevron-right"></i></a>
//                 </div>
//             </div>
//         </div>
//         <a class="btn btn-primary" href="http://thecodeblock.com/create-a-quote-testimonial-slider-using-bootstrap-carousel"><i class="fa fa-arrow-left"></i> Back to Article</a>
//     </div>
//     </div>
//   )
// }

// export default Sliders


// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'font-awesome/css/font-awesome.min.css';
// import './Sliders.css';

// const Sliders = () => {
//   return (
//     <div className="container">
//       <div className="row">
//         <div className="col-md-12">
//           <div className="carousel slide" data-ride="carousel" id="quote-carousel">
//             <div className="carousel-inner text-center">
//               <div className="item active">
//                 <blockquote>
//                   <div className="row">
//                     <div className="col-sm-8 col-sm-offset-2">
//                       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. !</p>
//                       <small>Someone famous</small>
//                     </div>
//                   </div>
//                 </blockquote>
//               </div>
//               <div className="item">
//                 <blockquote>
//                   <div className="row">
//                     <div className="col-sm-8 col-sm-offset-2">
//                       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
//                       <small>Someone famous</small>
//                     </div>
//                   </div>
//                 </blockquote>
//               </div>
//               <div className="item">
//                 <blockquote>
//                   <div className="row">
//                     <div className="col-sm-8 col-sm-offset-2">
//                       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
//                       <small>Someone famous</small>
//                     </div>
//                   </div>
//                 </blockquote>
//               </div>
//             </div>
//             <ol className="carousel-indicators">
//               <li data-target="#quote-carousel" data-slide-to="0" className="active"><img className="img-responsive " src="https://s3.amazonaws.com/uifaces/faces/twitter/mantia/128.jpg" alt="" /></li>
//               <li data-target="#quote-carousel" data-slide-to="1"><img className="img-responsive" src="https://s3.amazonaws.com/uifaces/faces/twitter/adhamdannaway/128.jpg" alt="" /></li>
//               <li data-target="#quote-carousel" data-slide-to="2"><img className="img-responsive" src="https://s3.amazonaws.com/uifaces/faces/twitter/brad_frost/128.jpg" alt="" /></li>
//             </ol>
//             <a data-slide="prev" href="#quote-carousel" className="left carousel-control"><i className="fa fa-chevron-left"></i></a>
//             <a data-slide="next" href="#quote-carousel" className="right carousel-control"><i className="fa fa-chevron-right"></i></a>
//           </div>
//         </div>
//       </div>
//       <a className="btn btn-primary" href="http://thecodeblock.com/create-a-quote-testimonial-slider-using-bootstrap-carousel"><i className="fa fa-arrow-left"></i> Back to Article</a>
//     </div>
//   );
// };

// export default Sliders;



// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'font-awesome/css/font-awesome.min.css';
// import './Sliders.css';

// const Sliders = () => {
//   const slides = [
//     {
//       quote: 'Lorem ipsum dolor sit amet...',
//       author: 'Someone famous 1',
//       image: 'https://s3.amazonaws.com/uifaces/faces/twitter/mantia/128.jpg',
//     },
//     {
//       quote: 'Lorem ipsum dolor sit amet...',
//       author: 'Someone famous 2',
//       image: 'https://s3.amazonaws.com/uifaces/faces/twitter/adhamdannaway/128.jpg',
//     },
//     {
//       quote: 'Lorem ipsum dolor sit amet...',
//       author: 'Someone famous 3',
//       image: 'https://s3.amazonaws.com/uifaces/faces/twitter/brad_frost/128.jpg',
//     },
//   ];

//   return (
//     <div className="container">
//       <div className="row">
//         <div className="col-md-12">
//           <div className="carousel slide" data-ride="carousel" id="quote-carousel">
//             <div className="carousel-inner text-center">
//               {slides.map((slide, index) => (
//                 <div key={index} className={`item ${index === 0 ? 'active' : ''}`}>
//                   <blockquote>
//                     <div className="row">
//                       <div className="col-sm-8 col-sm-offset-2">
//                         <p>{slide.quote}</p>
//                         <small>{slide.author}</small>
//                       </div>
//                     </div>
//                   </blockquote>
//                 </div>
//               ))}
//             </div>
//             <ol className="carousel-indicators">
//               {slides.map((_, index) => (
//                 <li key={index} data-target="#quote-carousel" data-slide-to={index} className={index === 0 ? 'active' : ''}>
//                   <img className="img-responsive" src={slides[index].image} alt="" />
//                 </li>
//               ))}
//             </ol>
//             <a data-slide="prev" href="#quote-carousel" className="left carousel-control"><i className="fa fa-chevron-left"></i></a>
//             <a data-slide="next" href="#quote-carousel" className="right carousel-control"><i className="fa fa-chevron-right"></i></a>
//           </div>
//         </div>
//       </div>
//       <a className="btn btn-primary" href="http://thecodeblock.com/create-a-quote-testimonial-slider-using-bootstrap-carousel">
//         <i className="fa fa-arrow-left"></i> Back to Article
//       </a>
//     </div>
//   );
// };

// export default Sliders;


// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'font-awesome/css/font-awesome.min.css';
// import './Sliders.css';

// const Sliders = () => {
//   const slides = [
//     {
//       quote: 'Lorem ipsum dolor sit amet...',
//       author: 'Someone famous 1',
//       image: 'https://s3.amazonaws.com/uifaces/faces/twitter/mantia/128.jpg',
//     },
//     {
//       quote: 'Duis aute irure dolor in reprehenderit...',
//       author: 'Someone famous 2',
//       image: 'https://s3.amazonaws.com/uifaces/faces/twitter/adhamdannaway/128.jpg',
//     },
//     {
//       quote: 'Ut enim ad minim veniam...',
//       author: 'Someone famous 3',
//       image: 'https://s3.amazonaws.com/uifaces/faces/twitter/brad_frost/128.jpg',
//     },
//   ];

//   return (
//     <div className="container">
//       <div className="row">
//         <div className="col-md-12">
//           <div className="carousel slide" data-ride="carousel" id="quote-carousel">
//             <div className="carousel-inner text-center">
//               {slides.map((slide, index) => (
//                 <div key={index} className={`item ${index === 0 ? 'active' : ''}`}>
//                   <blockquote>
//                     <div className="row">
//                       <div className="col-sm-8 col-sm-offset-2">
//                         <p>{slide.quote}</p>
//                         <small>{slide.author}</small>
//                       </div>
//                     </div>
//                   </blockquote>
//                 </div>
//               ))}
//             </div>
//             <ol className="carousel-indicators">
//               {slides.map((_, index) => (
//                 <li key={index} data-target="#quote-carousel" data-slide-to={index} className={index === 0 ? 'active' : ''}>
//                   <img className="img-responsive" src={slides[index].image} alt="" />
//                 </li>
//               ))}
//             </ol>
//             <a data-slide="prev" href="#quote-carousel" className="left carousel-control"><i className="fa fa-chevron-left"></i></a>
//             <a data-slide="next" href="#quote-carousel" className="right carousel-control"><i className="fa fa-chevron-right"></i></a>
//           </div>
//         </div>
//       </div>
//       <a className="btn btn-primary" href="http://thecodeblock.com/create-a-quote-testimonial-slider-using-bootstrap-carousel">
//         <i className="fa fa-arrow-left"></i> Back to Article
//       </a>
//     </div>
//   );
// };

// export default Sliders;



// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'font-awesome/css/font-awesome.min.css';
// import './Sliders.css'

// const Sliders = () => {
//   const quotes = [
//     {
//       text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. !',
//       author: 'Someone famous',
//       image: 'https://s3.amazonaws.com/uifaces/faces/twitter/mantia/128.jpg',
//     },
//     {
//       text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
//       author: 'Someone famous',
//       image: 'https://s3.amazonaws.com/uifaces/faces/twitter/adhamdannaway/128.jpg',
//     },
//     {
//       text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
//       author: 'Someone famous',
//       image: 'https://s3.amazonaws.com/uifaces/faces/twitter/brad_frost/128.jpg',
//     },
//   ];

//   return (
//     <div className="container">
//       <div className="row">
//         <div className="col-md-12">
//           <div className="carousel slide" data-ride="carousel" id="quote-carousel">
//             <div className="carousel-inner text-center">
//               {quotes.map((quote, index) => (
//                 <div key={index} className={`item ${index === 0 ? 'active' : ''}`}>
//                   <blockquote>
//                     <div className="row">
//                       <div className="col-sm-8 col-sm-offset-2">
//                         <p>{quote.text}</p>
//                         <small>{quote.author}</small>
//                       </div>
//                     </div>
//                   </blockquote>
//                 </div>
//               ))}
//             </div>

//             <ol className="carousel-indicators">
//               {quotes.map((_, index) => (
//                 <li key={index} data-target="#quote-carousel" data-slide-to={index} className={index === 0 ? 'active' : ''}>
//                   <img className="img-responsive" src={quotes[index].image} alt="" />
//                 </li>
//               ))}
//             </ol>

//             <a data-slide="prev" href="#quote-carousel" className="left carousel-control"><i className="fa fa-chevron-left"></i></a>
//             <a data-slide="next" href="#quote-carousel" className="right carousel-control"><i className="fa fa-chevron-right"></i></a>
//           </div>
//         </div>
//       </div>

//       <a className="btn btn-primary" href="http://thecodeblock.com/create-a-quote-testimonial-slider-using-bootstrap-carousel">
//         <i className="fa fa-arrow-left"></i> Back to Article
//       </a>
//     </div>
//   );
// };

// export default Sliders;



import React, { useEffect } from 'react';
import Swiper from 'swiper/bundle'; // Import the bundle version
import 'swiper/swiper-bundle.css'; 
import './Sliders.css'; // Import your custom styles if any

const Sliders = () => {
  const testimonialData = [
    {
      avatar: "https://img.freepik.com/free-photo/woman-with-long-hair-yellow-hoodie-with-word-music-it_1340-39068.jpg",
      name: "Simonette Lindermann",
      review: "Mind-blowing discovery! changed my routine. Essential for everyone. A vise advice to all interested. Can't imagine without it!"
    },
    {
      avatar: "https://img.freepik.com/free-photo/close-up-portrait-young-bearded-man-white-shirt-jacket-posing-camera-with-broad-smile-isolated-gray_171337-629.jpg",
      name: "Merilee Beal",
      review: "Unbelievable gem! Altered my life. A must-have now. Wholeheartedly advise it to everyone. An absolute game-changer"
    },
    // ... add more testimonial data
  ];

  useEffect(() => {
    const swiper = new Swiper('#craouselContainer', {
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: 2.3,
      loop: true,
      spaceBetween: 30,
      effect: "coverflow",
      coverflowEffect: {
        rotate: 0,
        depth: 800,
        slideShadows: true,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      autoplay: { delay: 5000 }
    });

    const handleResize = () => {
      if (window.innerWidth < 724) swiper.params.slidesPerView = 2;
      if (window.innerWidth > 501) swiper.params.slidesPerView = 2;
      if (window.innerWidth > 724) swiper.params.slidesPerView = 2.3;
      if (window.innerWidth < 501) swiper.params.slidesPerView = 1;
      swiper.update();
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      swiper.destroy();
    };
  }, []);

  return (
    <div id="craouselContainer" className="swiper-container" style={{ backgroundColor: "#9a99f2",display: "grid",
    placeItems: "center",
    position: "relative",
   
    }}>
      <div className="swiper-wrapper" id="slideHolder">
        {testimonialData.map((testimonial, index) => (
          <div key={index} className="swiper-slider">
            <div className="ImgHolder"><img src={testimonial.avatar} alt={`${testimonial.name} avatar`} /></div>
            <div className="ContentHolder">
              <h3>{testimonial.name}</h3>
              <p>{testimonial.review}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="swiper-pagination"></div>
    </div>
  );
};

export default Sliders;
