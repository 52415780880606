import React from 'react'
import './Solutions.css';
import Footer from '../components/Footer';

const ClouderaManaged = () => {
  return (
    <div>
        {/* <div class="container-fluid"> */}
  <img src="../images/solutions/cloudera-managed.jpg" alt="Nature" class="service-header-img"/>
  <div class="text-whitedata">
  <h1 class="text-size-head-white">Cloudera Managed<br/>
Services Program</h1>
<h4 >Reliable, Scalable, and Secure support for your Data Analytics Infrastructure</h4>
  </div>
{/* </div> */}

<div class="container">
    <div class="row" style={{color:"rgb(125, 136, 155)",marginTop:"40px"}}>
      <div class="insight-matter"> <h1>What we do</h1>
        <p>The Cloudera Managed Services program is essential for organizations to receive reliable,
             scalable, and secure support for their data analytics infrastructure.</p>
    <p>SSS Cloudera managed services program is designed to provide enterprise-grade support and
         operational service to organizations that run their applications on the Cloudera platform.​​​​</p>
    </div> </div> 
</div>
<div class="container">
<div class="row row-center">
    <div class="solutions-head">
        <h1>Key Features</h1>
    </div>
    <div class="col-lg-2"><p class="key-features-p">Onboarding
and setup​​</p></div>
    <div class="col-lg-2"><p class="key-features-p">Platform
configuration</p></div>
    <div class="col-lg-2"><p class="key-features-p">Data<br/>
integrationt​​​</p></div>
    <div class="col-lg-2"><p class="key-features-p">Environment
optimization​​​</p></div>
    <div class="col-lg-2"><p class="key-features-p">Consulting<br/>
services​​​</p></div>
    
</div>
</div>

<div class="container-fluid key-benefits-bg-color">
    <div class="row ">
        <div class="solutions-head">
            <h1>Key Benefits</h1>
        </div>
        <div class="col-md-2 key-margin1 row-center1">
            <img src="./images/solutions/24x7-Technical-Support-1.png" alt="" class="key-benifits-img"></img>
            <p class="key-benifits-p">24*7 technical support</p>
        </div>
        <div class="col-md-2 key-margin1 row-center1">
        <img src=" ./images/solutions/Performance-2.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Performance tuning</p>
        </div>
        <div class="col-md-2 key-margin1 row-center1">
        <img src="./images/solutions/System-Monitoring-2.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">System monitoring</p>
        </div>
        <div class="col-md-2 key-margin1 row-center1">
        <img src="./images/solutions/Increase-Efficiency.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Increased efficiency​​</p>
        </div>
        <div class="col-md-2 key-margin1 row-center1">
        <img src="./images/solutions/Improve-Performance-1.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Improved Performance​​</p>
        </div>
        
      
    </div>
</div>

           <Footer/>
      
    </div>
  )
}

export default ClouderaManaged
