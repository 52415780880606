import React from 'react'
import './Solutions.css';
import Footer from '../components/Footer';

const ControlTower = () => {
  return (
    <div>
        {/* <div class="container-fluid"> */}
  <img src="../images/solutions/control-tower.jpg" alt="Nature" class="service-header-img"/>
  <div class="text-whitedata">
  <h1 class="text-size-head-white">Control Towers</h1>
<h4 >Drive performance with sss’s Control Tower</h4>
  </div>
{/* </div> */}

<div class="container">
    <div class="row" style={{color:"rgb(125, 136, 155)",marginTop:"40px"}}>
      <div class="insight-matter"> <h1>What we do</h1>
        <p>Today organizations need a centralized platform to monitor, analyse, and act on data from various sources, such as sensors, IoT devices, and enterprise 
            systems in order to be able to manage and optimize their supply chain, logistics, and operations.</p>
    <p>SSS control tower solution provides a powerful tool for organizations looking to streamline their operations, 
        reduce costs, and improve their bottom line.​​​​</p>
    </div> </div> 
</div>

<div class="container-fluid key-benefits-bg-color">
    <div class="row ">
        <div class="solutions-head">
            <h1>Key Benefits</h1>
        </div>
        <div class="col-md-2 key-margin1 row-center1">
            <img src="./images/solutions/Real-time Visibility.png" alt="" class="key-benifits-img"></img>
            <p class="key-benifits-p">Real-time Visibility</p>
        </div>
        <div class="col-md-2 key-margin1 row-center1">
        <img src=" ./images/solutions/Advanced-Analytics.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Predictive analytics and risk mitigation</p>
        </div>
        <div class="col-md-2 key-margin1 row-center1">
        <img src="./images/solutions/Process-efficiency.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Process optimization</p>
        </div>
        <div class="col-md-2 key-margin1 row-center1">
        <img src="./images/solutions/Communication.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Collaboration and <br/>real-time communication​​</p>
        </div>
        <div class="col-md-2 key-margin1 row-center1">
        <img src="./images/solutions/Cost-Saving-4.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Cost Saving​​</p>
        </div>
        
      
    </div>
</div>
      

       <Footer/>
    </div>
  )
}

export default ControlTower
