

import React from 'react';
import { Link } from 'react-router-dom';
export default function CareerSection() {
  return (
    <div className="container" style={{paddingTop:"4%"}}>
      <div className="row">
        <div className="col-md-6">
          <h2 className="elementor-heading-title elementor-size-default" style={{color:"#02c4ce"}}>
            Are you looking for the next step in your career?
          </h2>
          {/* <p className="elementor-heading-title elementor-size-default" style={{ fontFamily: "poppins", fontWeight: "500", textAlign: "justify" }}> */}
            <p className="elementor-heading-title elementor-size-default" style={{textAlign:"justify",fontSize:"18px"}}>
           SSS is constantly forward-looking, with our primary goal being the expansion of our team. Our unwavering commitment centers on aiding our team members in achieving their objectives, all while delivering exceptional value to our customers.
            <br /><br />
           If you're on the lookout for a place where you can enhance your skills and make a meaningful impact on a thriving organization, SSS could be the ideal fit for you. Dive into our career openings by clicking below and don't hesitate to connect with us if you believe you're a suitable candidate.
          </p>


          <div className="text-center">
      <Link
        className="fusion-button button-flat fusion-button-default-size button-custom fusion-button-default button-1 fusion-button-default-span startNowButton custom-button"
        target="_self"
        to="/about-sss"
      >
        <span className="fusion-button-text">READ MORE</span>
        <i className="fa-long-arrow-alt-right fas button-icon-right" aria-hidden="true"></i>
      </Link>
    </div>
        </div>
        <div className="col-md-6">
          <img
            src="https://sssbi-images.s3.ap-south-1.amazonaws.com/Employee+Images/conference+3.jpg"
            alt="About Us"
            className="img-fluid"
            width="569"
            height="1000px"
          />
        </div>
      </div>
    </div>
  );
}
