import React from 'react'
import './Solutions.css';
import Footer from '../components/Footer';

const IndustialIot = () => {
  return (
    <div>
        {/* <div class="container-fluid"> */}
  <img src="../images/solutions/industrial-iot.png" alt="Nature" class="service-header-img"/>
  <div class="text-whitedata">
  <h1 class="text-size-head-white">Industrial IoT</h1>
<h4 >Amplify performance with cutting-edge Industrial IOT</h4>
  </div>
{/* </div> */}

<div class="container">
    <div class="row" style={{color:"rgb(125, 136, 155)",marginTop:"40px"}}>
      <div class="insight-matter"> <h1>What we do</h1>
        <p>To remain competitive in today’s world, industries need to predict failure of machinery, prevent 
            unplanned downtime and achieve higher levels of efficiency, productivity and performance. ​​​​​</p>
    <p>SSS Industrial IoT solution helps organizations identify optimization possibilities to boost bottom line and top 
        line growth of business by analysing and tracking real time streaming data from connected machines..​</p>
    </div> </div> 
</div>
<div class="container">
<div class="row">
    <div class="solutions-head">
        <h1>Our Key Aspects of Supply Chain Risk</h1>
    </div>
    <div class="col-lg-4"><p class="key-features-p">Intelligent
Connected Machines​​</p></div>
    <div class="col-lg-4"><p class="key-features-p">Advanced
Analytics​​​</p></div>
    <div class="col-lg-4"><p class="key-features-p">People​​​</p></div>
   
</div>
</div>

<div class="container-fluid key-benefits-bg-color">
    <div class="row row-radius">
        <div class="solutions-head">
            <h1>Key Benefits</h1>
        </div>
        <div class="col-md-3 key-margin1 row-center3">
            <img src=" ./images/solutions/Predictive-Analysis-4.png" alt="" class="key-benifits-img3"></img>
            <p class="key-benifits-p">Predictive Maintenance​</p>
        </div>
        <div class="col-md-3 key-margin1 row-center3">
        <img src=" ./images/solutions/Remote-Monitoring-4.png" alt="" class="key-benifits-img3"></img>
        <p class="key-benifits-p">Real Time Monitoring</p>
        </div>
        <div class="col-md-3 key-margin1 row-center3">
        <img src="./images/solutions/Process-efficiency.png" alt="" class="key-benifits-img3"></img>
        <p class="key-benifits-p">Asset Optimization​</p>
        </div>
        <div class="col-md-3 key-margin1 row-center3 ">
        <img src="./images/solutions/Increase-Efficiency.png" alt="" class="key-benifits-img3"></img>
        <p class="key-benifits-p">Improving Overall equipment effectiveness​​​</p>
        </div>
    </div>
</div>
      
           <Footer/>
    </div>
  )
}

export default IndustialIot
