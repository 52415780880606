import React from 'react';
import styled from 'styled-components';

const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 104%;
  max-width: 104%;
  margin-left: calc(-4% / 2);
  margin-right: calc(-4% / 2);
  height: calc(100vh - 0px);
  background-image: linear-gradient(rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%),
    url('https://SSS.com/wp-content/uploads/2022/12/CORPORATE-TIMELINE-SLIDER-BG.jpg');
  background-size: cover;
`;

const Heading = styled.h1`
  color: #fff;
  margin-bottom: 30px;
  line-height: 0.95;
  font-size: 63px;
  font-family: 'AmsiPro-Bold';
`;

const CorporateTimelineBanner = () => {
  return (
    <BannerContainer>
      <Heading>
        <strong>Corporate Timeline</strong>
      </Heading>
    </BannerContainer>
  );
};

export default CorporateTimelineBanner;
