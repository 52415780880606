import { useState } from 'react';
import ReactSimplyCarousel from 'react-simply-carousel';
import './Carousel.css';

function ManangedServicesCarousel1() {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <div>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        forwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '30px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`>`}</span>,
        }}
        backwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '20px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`<`}</span>,
        }}
        responsiveProps={[
          {
            itemsToShow: 3,
            itemsToScroll: 3,
            minWidth: 768,
          },
        ]}
        speed={400}
        easing="linear"
      >
        
        <div style={{ width: 300, height: 550}}>
        <div class="card-body cloud-card-body-colors1" >
              <h5 class="card-title">Advisory​ Managed Services​ </h5>
              <ul class="card-text1">
              <li>Define Scope​​</li>
              <li>Define Training Needs</li>
              <li>Define Project Charter, SLA, RACI Metrics​​</li>
              <li>Risk Assessment and Management​​</li>
              <li>Resource Management​</li>
              <li>Quality Assurance and Control</li>
              <li>Stakeholder Engagement</li>
              <li>Communication Plan</li>
              </ul>
            </div>
        </div>
        <div style={{ width: 300, height: 550}}>
        <div class="card-body cloud-card-body-colors1"  >
            <h5 class="card-title">Maintain​ Managed Services​</h5>
              <ul class="card-text1">
                <li>Continuous Improvement Initiatives</li>
              <li>Governance (Weekly Meetings, Monthly Service Delivery Review Meetings)​</li>
              <li>Documentation and Knowledge Sharing​</li>
              <li>On-Call Support​</li>
              <li>Incident Management​​</li>
              <li>Change Management​</li>
              <li>Measure all metrics for Productivity, Quality, Service Level​s​</li>
                </ul>
            </div>
        </div>
        <div style={{ width: 300, height: 550 }}>
        <div class="card-body cloud-card-body-colors1"  >
             
             <h5 class="card-title">Enhance Managed Services</h5>
             <ul class="card-text1">
             <li>Continuous Improvement with PCDA (Plan-Do-Check-Act) Methodology​</li>
             <li>Problem Management and Root Cause Analysis​</li>
             <li>Automation and Streamlining​​</li>
             <li>Update Run Book and SOP Documentation</li>
             <li>Performance Benchmarking</li>
             <li>Customer-Centric Approach</li>
               </ul>
              </div>
        </div>
        <div style={{ width: 300, height: 550}}>
        <div class="card-body cloud-card-body-colors1">
            <h5 class="card-title">Maintain Managed Services​​</h5>
              <ul class="card-text1">
                <li>Continuous Improvement Initiatives</li>
              <li>Governance (Weekly Meetings, Monthly Service Delivery Review Meetings)​</li>
              <li>Documentation and Knowledge Sharing​</li>
              <li>On-Call Support​</li>
              <li>Incident Management​​</li>
              <li>Change Management​</li>
              <li>Measure all metrics for Productivity, Quality, Service Level​s​</li>
                </ul>
            </div>
        </div>
        <div style={{ width: 300, height: 550 }}>
        <div class="card-body cloud-card-body-colors1"  >
            <h5 class="card-title">Enhance Managed Services</h5>
              <ul class="card-text1">
              <li>Continuous Improvement with PCDA (Plan-Do-Check-Act) Methodology​</li>
              <li>Problem Management and Root Cause Analysis​</li>
              <li>Automation and Streamlining​​</li>
              <li>Update Run Book and SOP Documentation</li>
              <li>Performance Benchmarking</li>
              <li>Customer-Centric Approach</li>
                </ul>
            </div>
        </div>
        <div style={{ width: 300, height: 550 }}>
        <div class="card-body cloud-card-body-colors1">
            <h5 class="card-title">Managed Services Competency Center​​</h5>
              <ul class="card-text1">
             <li>Technical Setup & System Access​</li>
             <li>Engage Subject Matter Experts (SMEs) for Infrastructure, Security & Compliance​​</li>
             <li>Standardized Processes & Frameworks</li>
             <li>Vendor and Third-Party Service Provider Management</li>
             <li>Monitor Service Level Agreements (SLAs)​</li>
             <li>Documentation & Training​</li>
           
              </ul>
           </div>
        </div>
    
      </ReactSimplyCarousel>
    </div>
  );
}

export default ManangedServicesCarousel1;