

import React, { useState } from 'react';
import { Button, Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ContactUss from '../components/ContactUss';
import Footer from '../components/Footer';
import './SuccessStories.css';

const imagesData = [
  {
    id: 1,
    category: 'All',
    category: 'BI Solutions',
    imageUrl: 'https://sssbi-images.s3.ap-south-1.amazonaws.com/success_stories/Migration-of-OWB-to-Informatica-5.jpg',
    bio: 'MIGRATION OF OWB TO IMFORMATICA',

   
  },
  {
    id: 2,
    category: 'All',
    category:'Enterprise IT',
    imageUrl: 'https://sssbi-images.s3.ap-south-1.amazonaws.com/success_stories/Order-Management-Analytics.jpg',
    bio: 'ORDER MANAGEMENT ANALYTICS',
  },
  {
    id: 3,
    category: 'All',
    category:'Enterprise IT',
    imageUrl: 'https://sssbi-images.s3.ap-south-1.amazonaws.com/success_stories/Implementation-of-SCM-BI-Apps-3.jpg',
    bio: 'IMPLEMENTATION OF SCM BI APPS',
  },
  {
    id: 4,
    category: 'All',
    category:'Web Services',
    imageUrl: 'https://sssbi-images.s3.ap-south-1.amazonaws.com/success_stories/LIVE-STREAMING-PROJECT-5.jpg',
    bio: 'LIVE STREAMING PROJECT',
  },

  {
    id: 5,
    category: 'ALL',
    category: 'BI Solutions',
    imageUrl: 'https://sssbi-images.s3.ap-south-1.amazonaws.com/success_stories/Implementation-of-BIDW-5.jpg',
    bio: 'IMPLEMENTATION OF DWBI',
  },
  {
    id: 6,
    category: 'ALL',
    category:'Web Services',
    imageUrl: 'https://sssbi-images.s3.ap-south-1.amazonaws.com/success_stories/Customer-Relationship-Management-5.jpg',
    bio: 'CUSTOMER RELATIONSHIP MANAGEMENT',
  },
  {
    id: 7,
    category: 'ALL',
    category: 'BI Solutions',
    imageUrl: 'https://sssbi-images.s3.ap-south-1.amazonaws.com/success_stories/UCCE-Migration-from-Informatica-to-SSIS.jpg',
    bio: 'UCCE MIGRATION FROM INFORMATICA TO SSIS',
  },
  {
    id: 8,
    category: 'ALL',
    category:'Web Services',
    imageUrl: 'https://sssbi-images.s3.ap-south-1.amazonaws.com/success_stories/E-COMMERCE-APPLICATION-5.jpg',
    bio: 'E-COMMERCE APPLICATION',
  },
  {
    id: 9,
    category: 'ALL',
    category:'Enterprise IT',
    imageUrl: 'https://sssbi-images.s3.ap-south-1.amazonaws.com/success_stories/Migration-of-ETL-Code-from-OWB-to-Informatica-2.jpg',
    bio: 'MIGRATION OF ETL CODE FROM OWB TO INFORMATICA',
  },
  {
    id: 10,
    category: 'ALL',
    category:'Web Services',
    imageUrl: 'https://sssbi-images.s3.ap-south-1.amazonaws.com/success_stories/INTERNAL-PORTAL-WEB-APPLICATION-5.jpg',
    bio: 'INTERNAL PORTAL WEB APPLICATION',
  },
   {
    id: 11,
    category: 'ALL',
    category:'Enterprise IT',
    imageUrl: 'https://sssbi-images.s3.ap-south-1.amazonaws.com/success_stories/UPGRADATION-OF-BI-apps-795-TO-7964-5.jpg',
    bio: 'BI APPS UPGRADE REIMPLEMENTATION FROM 7952 TO 7964',
  },
  {
    id: 12,
    category: 'ALL',
    category:'Technology Consulting',
    imageUrl: 'https://sssbi-images.s3.ap-south-1.amazonaws.com/success_stories/Biometric-Finger-Print-4.jpg',
    bio: 'BIOMETRIC FINGER PRINT',
  },
  {
    id: 13,
    category: 'ALL',
    category: 'BI Solutions',
    imageUrl: 'https://sssbi-images.s3.ap-south-1.amazonaws.com/success_stories/Financial-Analytics-Implementation-1.jpg',
    bio: 'FINANCIAL ANALYTICS IMPLEMENTATION',
  },
  {
    id: 14,
    category: 'ALL',
    category:'Enterprise Mobility',
    imageUrl: 'https://sssbi-images.s3.ap-south-1.amazonaws.com/success_stories/Fraud-Insight-driven-analytics-5.jpg',
    bio: 'FRAUD INSIGHT-DRIVEN ANALYTICS',
  },
  {
    id: 15,
    category: 'ALL',
    category:'Enterprise Mobility',
    imageUrl: 'https://sssbi-images.s3.ap-south-1.amazonaws.com/success_stories/Oracle-ODI-BI-Apps-Implementation.jpg',
    bio: 'ORACLE ODI BI APPS IMPLEMENTATION',
  },
  {
    id: 16,
    category: 'ALL',
    category:'Enterprise IT',
    imageUrl: 'https://sssbi-images.s3.ap-south-1.amazonaws.com/success_stories/Supply-chain-optimization-4.jpg',
    bio: 'SUPPLY CHAIN OPTIMIZATION',
  },
  {
    id: 17,
    category: 'ALL',
    category:'Enterprise IT',
    imageUrl: 'https://sssbi-images.s3.ap-south-1.amazonaws.com/success_stories/Data-Governance-and-Migration.jpg',
    bio: 'DATA GOVERNANCE AND MIGRATION',
  },


];


const SuccessStory = () => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [clickedButton, setClickedButton] = useState(null);

  const handleCategoryClick = (category, buttonId) => {
    setSelectedCategory(category);
    setClickedButton(buttonId);
  };

  const filteredImages = selectedCategory === 'All'
    ? imagesData
    : imagesData.filter(image => image.category === selectedCategory);

  return (
    <div>
      {/* <div className="container2">
        <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/Employee+Images/success+3.jpg" alt="Snow" style={{ height: "550px", width: "100%", transform: "skew-X(5deg)" }} />
     
      </div> */}

      <div className="container-fluid sucsDisplay"  style={{marginTop:'5vh'}}>
  <div className='row  ' >
   
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/Employee+Images/success+3.jpg" alt="Snow" className="service-header-imgs-sucs"  />
   
  </div>
</div>

      <Container>
        <Row>
          <Col md={12}>
            <p className="header-p">
              We work with our clients to develop platforms that will increase responsiveness, reduce IT costs and workload, improve performance, and drive better decision-making across the organization. For example, we worked with a leading Airlines Company which operates scheduled domestic and international passenger services, in developing a centralized data warehouse solution with BI reports at the enterprise level…
            </p>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col>
            <div className="category-buttons">
              <Container fluid>
                <Row style={{textAlign:"center",marginBottom:"2px"}}>
                  <Col md={1}>
                    <Button
                      className={clickedButton === 'All' ? 'clicked' : ''}
                      onClick={() => handleCategoryClick('All', 'All')}
                      style={{ marginLeft: "20px",marginBottom:"4px" }}  >
                      ALL SERVICES
                    </Button>
                  </Col>
                  <Col md={1}>
                    <Button
                      className={clickedButton === 'BI Solutions' ? 'clicked' : ''}
                      onClick={() => handleCategoryClick('BI Solutions', 'BI Solutions')}
                      style={{ marginLeft: "20px",marginBottom:"4px" }}  >
                      BI SOLUTIONS
                    </Button>
                  </Col>
                  <Col md={1}>
                    <Button
                      className={clickedButton === 'Enterprise IT' ? 'clicked' : ''}
                      onClick={() => handleCategoryClick('Enterprise IT', 'Enterprise IT')}
                      style={{ marginLeft: "18px",marginBottom:"4px" }} >
                      ENTERPRISE IT
                    </Button>
                  </Col>
                  <Col md={2}>
                    <Button
                      className={clickedButton === 'Enterprise Mobility' ? 'clicked' : ''}
                      onClick={() => handleCategoryClick('Enterprise Mobility', 'Enterprise Mobility')}
                      style={{ marginLeft: "26px" ,marginBottom:"4px"}}
                    >
                      ENTERPRISE MOBILITY
                    </Button>
                  </Col>
                  <Col md={2}>
                    <Button
                      className={clickedButton === 'Technology Consulting' ? 'clicked' : ''}
                      onClick={() => handleCategoryClick('Technology Consulting', 'Technology Consulting')}
                      style={{ marginLeft: "4px",marginBottom:"4px"  }}   >
                      TECHNOLOGY CONSULTING
                    </Button>
                  </Col>
                  <Col md={2}>
                    <Button
                      className={clickedButton === 'Web Services' ? 'clicked' : ''}
                      onClick={() => handleCategoryClick('Web Services', 'Web Services')}
                      style={{ marginLeft: "20px",marginBottom:"4px" }}
                    >
                      WEB SERVICES
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>

        <Row style={{ paddingTop: "10%" }}>
          {filteredImages.map(image => (
            <Col key={image.id} md={3} className="image-col">
              <div className="image-container">
                <Image src={image.imageUrl} alt={image.category} fluid />
                <div className="image-overlay">
                  <p className="image-bio">{image.bio}</p>
                  {image.category === 'BI Solutions' && (
                    <Link to="/bi" className="image-bio1">
                      <h6 style={{ color: "white", paddingLeft: "15px", marginBottom: "1px" }}>BI Solutions</h6>
                    </Link>
                  )}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>

      {/* <ContactUss /> */}
      <Footer />
    </div>
  );
};

export default SuccessStory;
