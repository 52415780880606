import { useState } from 'react';
import ReactSimplyCarousel from 'react-simply-carousel';
import './Carousel.css';

function CloudDataCarousel() {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <div>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        forwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            flexDirection:'row',
            color: 'white',
            cursor: 'pointer',
            fontSize: '30px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`>`}</span>,
        }}
        backwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            flexDirection:'row',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '20px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`<`}</span>,
        }}
        responsiveProps={[
          {
            itemsToShow: 3,
            itemsToScroll: 3,
            minWidth: 700,
          },
        ]}
        speed={400}
        easing="linear"
      >

        <div style={{ width: 310, height: 650 }}>
        <div class="card bi-card-size"  >
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/cloud/Cloud-Advisory-1.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black">Cloud Strategy Guidance​</h5>
      <ul class="card-text " >
        <li>Evaluating Current Infrastructure and Applications​</li>
        <li>Selecting the Appropriate Cloud Platform, Services, and Migration Strategy​</li>
        <li>Crafting Cloud Infrastructure and Application Architecture Designs​​​</li>
        <li>Adhering to Leading Industry Standards and Best Practices​</li>
       </ul> </div>
  </div>
        </div>
        <div style={{ width: 310, height: 650}}>
        <div class="card bi-card-size" >
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/cloud/Cloud-BI.png" class="rpa-img-top" alt="..." />
    <div class="card-body ">
      <h5 class="card-title text-body-color-black">Data Science and BI ​</h5>
      <ul class="card-text" >
      <li>Deliver Solutions Utilizing Relevant Cloud Services</li>
      <li>Acquire Services in Accordance with the Roadmap</li>
      <li>Harness Cloud Resources for Business Intelligence and Data Science Solutions</li>
      <li>Integrate In-House and Cloud-Based Resources Effectively​</li>


       </ul>
        </div>
  </div>
        </div>
        <div style={{ width: 310, height: 650}}>
        <div class="card bi-card-size ">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/cloud/Cloud-Managed-Services.png" class="rpa-img-top" alt="..." />
    <div class="card-body ">
      <h5 class="card-title text-body-color-black" >Cloud ​Managed Services​</h5>
      <ul class="card-text" >
      <li>Facilitates and Enhances DevOps Practices​​</li>
      <li>Efficiently Manages AWS, Azure, or GCP Cloud Platforms​</li>
      <li>Handles Large Data Volumes with Ease​</li>
      <li>Leverages Advanced Analytics Tools</li>
      <li>Offers Scalable and Flexible Infrastructure for Data Analytics​</li>

       </ul>
    </div>
  </div>
        </div>
        <div style={{ width: 310, height: 650 }}>
        <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/cloud/Cloud-BI.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
    <h5 class="card-title text-body-color-black">Data Science and BI </h5>
      <ul class="card-text" >
      <li>Deliver Solutions Utilizing Relevant Cloud Services</li>
      <li>Acquire Services in Accordance with the Roadmap</li>
      <li>Harness Cloud Resources for Business Intelligence and Data Science Solutions</li>
      <li>Integrate In-House and Cloud-Based Resources Effectively​</li>
      <li>Ensure Smooth Workload Migration</li>

       </ul>
        </div>
  </div>
        </div>
        <div style={{ width: 310, height: 650}}>
        <div class="card bi-card-size ">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/cloud/Cloud-Managed-Services.png" class="rpa-img-top" alt="..." />
    <div class="card-body ">
      <h5 class="card-title text-body-color-black" >Cloud ​Managed Services​</h5>
      <ul class="card-text" >
      <li>Facilitates and Enhances DevOps Practices​​</li>
      <li>Efficiently Manages AWS, Azure, or GCP Cloud Platforms​</li>
      <li>Handles Large Data Volumes with Ease​</li>
      <li>Leverages Advanced Analytics Tools</li>
      <li>Offers Scalable and Flexible Infrastructure for Data Analytics​</li>

       </ul>
    </div>
  </div>

        </div>
        <div style={{ width: 310, height: 650 }}>
        <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/cloud/Cloud-Migration.png" class="rpa-img-top" alt="..."/>
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Cloud Migration Assistance</h5>
      <ul class="card-text">
      <li>Thorough Exploration of Mainframe Applications, Batches, and Datasets​</li>
      <li>Migration to Big Data Platforms and Decommissioning of Mainframe</li>
      <li>Archiving Legacy Data on Hadoop and Elasticsearch</li>
      <li>Modernizing ETL (Extract, Transform, Load) Processes with Spark</li>
      {/* <li>Transferring Enterprise Data Warehousing (EDW) Workloads to Big Data and the Cloud​</li> */}
       </ul>
    </div>
  </div>

        </div>

      </ReactSimplyCarousel>
    </div>
  );
}

export default CloudDataCarousel;
