import React from 'react'
import './Solutions.css';
import Footer from '../components/Footer';

const SnowFlake = () => {
  return (
    <div>
        {/* <div class="container-fluid"> */}
  <img src="../images/solutions/snow-flake.webp" alt="Nature" class="service-header-img"/>
  <div class="text-whitedata">
  <h1 class="text-size-head-white">SnowFlake<br/>
Performance Tuning</h1>
<h4 >Ensure the best possible performance from your data analytics platform</h4>
  </div>
{/* </div> */}

<div class="container">
    <div class="row" style={{color:"rgb(125, 136, 155)",marginTop:"40px"}}>
      <div class="insight-matter"> <h1>What we do</h1>
        <p>Snowflake Performance Tuning is an essential process for organizations to ensure the best possible performance from their data analytics platform.​​​​</p>
    <p>SSS Snowflake Performance Tuning involves optimizing the cloud-based data warehousing platform
         for improved query performance, supporting complex analytics use cases
         and also faster & more accurate data analysis which is critical for handling large volumes of data.​​​​</p>
    </div> </div> 
</div>

<div class="container">
    <div class="row">
        <div class="solutions-head">
            <h1>Key Component</h1>
        </div>
        <div class="col-md-3"><p class="key-components-p">Onboarding / Setup</p></div>
        <div class="col-md-3"><p class="key-components-p">Operational Support</p></div>
        <div class="col-md-3"><p class="key-components-p">Continuous monitoring</p></div>
        <div class="col-md-3"><p class="key-components-p">Security / Compliance</p></div>
        <div class="col-md-4"><p class="key-components-p">Performance optimization</p></div>
        <div class="col-md-4"><p class="key-components-p">Training</p></div>
        <div class="col-md-4"><p class="key-components-p">Consulting services</p></div>
    </div>
</div>
<div class="container-fluid key-benefits-bg-color">
    <div class="row">
        <div class="solutions-head">
            <h1>Key Benefits</h1>
        </div>
        <div class="col-md-2 key-margin1 row-center">
            <img src="./images/solutions/Reduced-query.png" alt="" class="key-benifits-img"></img>
            <p class="key-benifits-p">Reduced query times</p>
        </div>
        <div class="col-md-2 key-margin1 row-center">
        <img src=" ./images/solutions/Minimal-system-downtime.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Minimal system downtime</p>
        </div>
        <div class="col-md-2 key-margin1 row-center">
        <img src="./images/solutions/increased-efficiency.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Increase Efficiency</p>
        </div>
        <div class="col-md-2 key-margin1 row-center">
        <img src="./images/solutions/Improved-Scalability.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Improved scalability​​</p>
        </div>
        <div class="col-md-2 key-margin1 row-center">
        <img src="./images/solutions/Increased-reliability.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Increased reliability​​</p>
        </div>
        
      
    </div>
</div>
       <Footer/>
    </div>
  )
}

export default SnowFlake
