import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Contact from "./Contact"; // Include your custom CSS file
import { Link } from "react-router-dom";
const DropdownMenu = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);
  const [isResourcesDropdownOpen, setIsResourcesDropdownOpen] = useState(false);
  const [isAboutUsDropdownOpen, setIsAboutUsDropdownOpen] = useState(false);

  const openResourcesDropdown = () => {
    setIsResourcesDropdownOpen(true);
  };

  const closeResourcesDropdown = () => {
    setIsResourcesDropdownOpen(false);
  };

  const openAboutUsDropdown = () => {
    setIsAboutUsDropdownOpen(true);
  };

  const closeAboutUsDropdown = () => {
    setIsAboutUsDropdownOpen(false);
  };
  const openServicesDropdown = () => {
    setIsServicesDropdownOpen(true);
  };

  const closeServicesDropdown = () => {
    setIsServicesDropdownOpen(false);
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  document.addEventListener("scroll", function () {
    const scrollPosition =
      (window.scrollY /
        (document.documentElement.scrollHeight - window.innerHeight)) *
      100;

    document.getElementById("pageBar").style.width = scrollPosition + "%";
  });

  return (
    <div>
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg fixed-top">
          <div className="container-fluid">
            <div class="pageProgress-container">
              <div
                class="pageProgress-bar"
                id="pageBar"
                style={{ width: "5.03686%" }}
              ></div>
            </div>

            {/* <a className="navbar-brand" href="/">
             <img src='https://sssbi-images.s3.ap-south-1.amazonaws.com/Logos/sss_final_logo_1910.gif' style={{width:"300px"}}/>
            </a> */}
            <Link className="navbar-brand" href="/">
              <img
                src="https://sssbi-images.s3.ap-south-1.amazonaws.com/Logos/SSS+Logo+2023+Bold.gif"
                style={{ width: "270px" }}
              />
            </Link>

            {/* <button
              className="navbar-toggler"
              type="button"

              onClick={toggleMenu}
              aria-expanded={isMenuOpen}
              aria-label="Toggle navigation"
              style={{margin: "18px"}}
            >
              <span className="navbar-toggler-icon"></span>
            </button> */}
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleMenu}
              aria-expanded={isMenuOpen}
              aria-label="Toggle navigation"
              style={{ marginLeft: "-40px" }}
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`}
              id="main_nav"
            >
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <Link
                    to="/"
                    className="nav-link"
                    onClick={() => {
                      toggleMenu();
                    }}
                  >
                    HOME
                  </Link>
                </li>
                {/* <li className="nav-item active">
                  <Link
                    to="/video-gallery"
                    className="nav-link"
                    onClick={() => {
                      toggleMenu();
                    }}
                  >
                    OUR PRODUCTS
                  </Link>
                </li> */}
                <li
                  className="nav-item dropdown"
                  onMouseEnter={openServicesDropdown}
                  onMouseLeave={closeServicesDropdown}
                >
                  <a className="nav-link" href="#">
                    SERVICES
                  </a>

                  <ul
                    className={`dropdown-menu ${
                      isServicesDropdownOpen ? "show" : ""
                    }`}
                  >
                    <li>
                      <Link
                        to="/data-engineering"
                        className="dropdown-item"
                        onClick={() => {
                          closeServicesDropdown();
                          toggleMenu();
                        }}
                      >
                        Data Engineering Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/data-analytics"
                        className="dropdown-item"
                        onClick={() => {
                          closeServicesDropdown();
                          toggleMenu();
                        }}
                      >
                        Data Analytics Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/cloud-data-services"
                        className="dropdown-item"
                        onClick={() => {
                          closeServicesDropdown();
                          toggleMenu();
                        }}
                      >
                        Cloud Data Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/data-science"
                        className="dropdown-item"
                        onClick={() => {
                          closeServicesDropdown();
                          toggleMenu();
                        }}
                      >
                        Data Science/AI/ML
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/app-development"
                        className="dropdown-item"
                        onClick={() => {
                          closeServicesDropdown();
                          toggleMenu();
                        }}
                      >
                        App Development
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/process-development"
                        className="dropdown-item"
                        onClick={() => {
                          closeServicesDropdown();
                          toggleMenu();
                        }}
                      >
                        Process Development
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/managed-services"
                        className="dropdown-item"
                        onClick={() => {
                          closeServicesDropdown();
                          toggleMenu();
                        }}
                      >
                        Managed Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/devops-services"
                        className="dropdown-item"
                        onClick={() => {
                          closeServicesDropdown();
                          toggleMenu();
                        }}
                      >
                        DevOps
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/cloud-migration"
                        className="dropdown-item"
                        onClick={() => {
                          closeServicesDropdown();
                          toggleMenu();
                        }}
                      >
                        Cloud Migration
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item active">
                  <Link
                    to="/our-products"
                    className="nav-link"
                    onClick={() => {
                      toggleMenu();
                    }}
                  >
                    OUR PRODUCTS
                  </Link>
                </li>

                <li
                  className="nav-item dropdown"
                  onMouseEnter={openResourcesDropdown}
                  onMouseLeave={closeResourcesDropdown}
                >
                  <a className="nav-link" href="#">
                    RESOURCES
                  </a>
                  <ul
                    className={`dropdown-menu ${
                      isResourcesDropdownOpen ? "show" : ""
                    }`}
                  >
                    <li>
                      <Link
                        to="/success-stories"
                        className="dropdown-item"
                        onClick={() => {
                          closeResourcesDropdown();
                          toggleMenu();
                        }}
                      >
                        Success Stories
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/blog" className="dropdown-item" onClick={() => { closeResourcesDropdown(); toggleMenu();}}>
                        Blog
                      </Link>

                    </li> */}
                    <li>
                      <Link
                        to="https://ssssupport.blogspot.com/2012/10/obiee-11g-r1-architecture.html"
                        className="dropdown-item"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          closeResourcesDropdown();
                          toggleMenu();
                        }}
                      >
                        Blog
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://community.fabric.microsoft.com/t5/user/viewprofilepage/user-id/773118"
                        className="dropdown-item"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          closeResourcesDropdown();
                          toggleMenu();
                        }}
                      >
                        Power BI Demo
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  className="nav-item dropdown"
                  onMouseEnter={openAboutUsDropdown}
                  onMouseLeave={closeAboutUsDropdown}
                >
                  <a className="nav-link" href="#">
                    ABOUT US
                  </a>
                  <ul
                    className={`dropdown-menu ${
                      isAboutUsDropdownOpen ? "show" : ""
                    }`}
                  >
                    <li>
                      <Link
                        to="/about-sss"
                        className="dropdown-item"
                        onClick={() => {
                          closeAboutUsDropdown();
                          toggleMenu();
                        }}
                      >
                        About SSS
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/Time-line"
                        className="dropdown-item"
                        onClick={() => {
                          closeAboutUsDropdown();
                          toggleMenu();
                        }}
                      >
                        Corporate Timeline
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Leadership"
                        className="dropdown-item"
                        onClick={() => {
                          closeAboutUsDropdown();
                          toggleMenu();
                        }}
                      >
                        Leadership Team
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/careers"
                        className="dropdown-item"
                        onClick={() => {
                          closeAboutUsDropdown();
                          toggleMenu();
                        }}
                      >
                        Careers
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/gallery"
                        className="dropdown-item"
                        onClick={() => {
                          closeAboutUsDropdown();
                          toggleMenu();
                        }}
                      >
                        Gallery
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to="/video-gallery"
                        className="dropdown-item"
                        onClick={() => {
                          closeAboutUsDropdown();
                          toggleMenu();
                        }}
                      >
                        Video Gallery
                      </Link>
                    </li> */}
                  </ul>
                </li>
                <li className="nav-item active">
                  <Link
                    to="/contact"
                    className="nav-link"
                    onClick={() => {
                      toggleMenu();
                    }}
                  >
                    CONTACT US
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default DropdownMenu;
