import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
`;

const SubmitButton = () => {
  return <Button type="submit">Submit</Button>;
};

export default SubmitButton;
