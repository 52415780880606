import { useState } from 'react';
import ReactSimplyCarousel from 'react-simply-carousel';
import './Carousel.css';

function DataScienceCarousel() {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <div>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        forwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '30px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`>`}</span>,
        }}
        backwardBtnProps={{
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '20px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`<`}</span>,
        }}
        responsiveProps={[
          {
            itemsToShow: 3,
            itemsToScroll: 3,
            minWidth: 780,
          },
        ]}
        speed={400}
        easing="linear"
      >
        {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}
        <div style={{ width: 310, height: 650 }}>
        <div class="card bi-card-size" >
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/dataScience/Advance-Analytics-Services.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black">Innovative Data Science/AI/ML Offerings</h5>
      <ul class="card-text" >
      <li>Formulate Data Science/AI/ML Services Strategy</li>
      <li>Install and Configure the Data Science/AI/ML Services Platform</li>
      <li>Create and Deploy Exploratory Data Science/AI/ML Models</li>
      <li>Implement Predictive Data Science/AI/ML Solutions</li>

       </ul>
        </div>
  </div>
        </div>
        <div style={{ width: 310, height: 650}}>
        <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/dataScience/AI-ML-Development-1.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >AI/ML Solution Development Services</h5>
      <ul class="card-text" >
      <li>Offer Customized Customer Experience Solutions</li>
      <li>Tailor AI Solutions to Individual Customer Requirements</li>
      <li>Integrate Chatbots and Automated Assistants for Communication</li>
      <li>Leverage Enterprise Application Engineering for Data Extraction</li>
       </ul>
    </div>
  </div>

        </div>
        <div style={{ width: 310, height: 650}}>
        <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/data-engineering/data-governance.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Data Governance and Security</h5>
      <ul class="card-text" >
      <li>Access Control and Authorization​​</li>
      <li>Data Encryption​</li>
      <li>Masking and Anonymization​</li>
      <li>Data Retention and Purging</li>
      <li>Offers scalable, flexible infrastructure for data engineering​</li>
      <li>Data Privacy Regulations Compliance</li>
       </ul>
    </div>
  </div>
        </div>
        <div style={{ width: 310, height: 650 }}>
        <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/dataScience/Advance-Analytics-Services.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
    <h5 class="card-title text-body-color-black">Innovative Data Science/AI/ML Offerings</h5>
      <ul class="card-text" >
      <li>Formulate Data Science/AI/ML Services Strategy</li>
      <li>Install and Configure the Data Science/AI/ML Services Platform</li>
      <li>Create and Deploy Exploratory Data Science/AI/ML Models</li>
      <li>Implement Predictive Data Science/AI/ML Solutions</li>

       </ul>
        </div>
  </div>
        </div>
        <div style={{ width: 310, height: 650}}>
        <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/dataScience/AI-ML-Development-1.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >AI/ML Solution Development Services</h5>
      <ul class="card-text" >
      <li>Offer Customized Customer Experience Solutions</li>
      <li>Tailor AI Solutions to Individual Customer Requirements</li>
      <li>Integrate Chatbots and Automated Assistants for Communication</li>
      <li>Leverage Enterprise Application Engineering for Data Extraction</li>
       </ul>
    </div>
  </div>

        </div>
        <div style={{ width: 310, height: 650 }}>
        <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/dataScience/Managed-Services.png" class="rpa-img-top" alt="..."/>
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Managed Data Science Services</h5>
      <ul class="card-text">
      <li>Offer Engineering Support to Customers</li>
      <li>Conduct Thorough Testing to Identify Bugs</li>
      <li>Collaborate with the Engineering Team to Report Bugs, Update, and Prepare Documentation</li>
      <li>Administer Data Science Products and Data Services</li>
      <li>Exploratory Data Analysis</li>
       </ul>
    </div>
  </div>
        </div>

      </ReactSimplyCarousel>
    </div>
  );
}

export default DataScienceCarousel;
