import React from "react";
import styled from "styled-components";
import ContactForm from "./ContactForm";
import { Link, useLocation } from "react-router-dom";

const BackgroundVideo = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  z-index: -1;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ContactUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const ContactUs = () => {
  const location = useLocation();

  return (
    <div>
      <div className="text-white">
        <h2 className="text-center1" style={{ fontSize: "30px" }}>
          Contact Us
        </h2>
        <p className="text-center contact-details">
          <span className="contact-location text-center1">
            <strong>India</strong> – Tirupati, Andhra Pradesh
          </span>
        </p>
      </div>

      <ContactUsContainer>
        <ContactForm />
      </ContactUsContainer>

      {/* <div style={{ textAlign: 'center', margin: '20px' }}>
        <Link to="/" style={{ textDecoration: 'none'}}>
          <button className='back' style={{ padding: '10px 20px',  border: 'none', borderRadius: '5px', cursor: 'pointer',color:"white" }}>
            Back to Home
          </button>
        </Link>
      </div> */}
      {/* <div class="bt-start-now1" style={{textAlign:"center"}}>
              <Link to="/" class="btn-start1" type="button" value="READ MORE" placeholder="START NOW"  target="" ><p>BACK TO HOME</p></Link>
            </div> */}
      {/* <div className="text-center">
      <Link
        className="fusion-button button-flat fusion-button-default-size button-custom fusion-button-default button-1 fusion-button-default-span startNowButton custom-button"
        target="_self"
        to="/"
      >
        <span className="fusion-button-text">Back To Home</span>
        <i className="fa-long-arrow-alt-right fas button-icon-right" aria-hidden="true"></i>
      </Link>
    </div> */}

      <BackgroundVideo>
        <video autoPlay muted loop>
          <source
            src="https://sssbi-images.s3.ap-south-1.amazonaws.com/Logos/bg-Footer.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </BackgroundVideo>
    </div>
  );
};

export default ContactUs;
