import React from 'react'
import './Solutions.css';
import Footer from '../components/Footer';

const ContractAbstraction = () => {
  return (
    <div>
        {/* <div class="container-fluid"> */}
  <img src="../images/solutions/contract-abstraction.jpg" alt="Nature" class="service-header-img"/>
  <div class="text-whitedata">
  <h1 class="text-size-head-white">Contract Abstraction</h1>
<h4 >Streamline contracts with automated abstraction</h4>
  </div>
{/* </div> */}

<div class="container">
    <div class="row" style={{color:"rgb(125, 136, 155)",marginTop:"40px"}}>
      <div class="insight-matter"> <h1>What we do</h1>
        <p>AI-powered contract abstraction is crucial for legal firms as it saves time, reduces costs, improves accuracy,
             and enables better decision-making, compliance, and competitive advantage.</p>
            <p>SSS Contract Abstraction tool is a user-friendly, AI based engine that allows legal
                 professionals to quickly abstract contracts with minimal assistance required from IT personnel. 
                 The AI-powered contract abstraction system has been trained to extract information from a
                 variety of contracts, including MSAs, NDAs, labour contracts, and IT outsourcing contracts.</p>
    </div> </div> 
</div>
<div class="container-fluid key-benefits-bg-color">
    <div class="row row-center">
        <div class="solutions-head">
            <h1>Key Benefits</h1>
        </div>
        <div class="col-md-2 key-margin">
            <img src="./images/solutions/Cost-Saving-4.png" alt="" class="key-benifits-img"></img>
            <p class="key-benifits-p1">Huge times and cost savings</p>
        </div>
        <div class="col-md-2 key-margin">
        <img src=" ./images/solutions/Accuracy-1 (1).png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p1">Proven accuracy and consistency</p>
        </div>
        <div class="col-md-2 key-margin">
        <img src="./images/solutions/Reduce-Attrition-Rate-1.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p1">Reduce Attrition Rate</p>
        </div>
        <div class="col-md-2 key-margin">
        <img src="./images/solutions/Cloud-Based1.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p1">On-premise or cloud-based​</p>
        </div>
        <div class="col-md-2 key-margin">
        <img src="./images/solutions/Decision-Making.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p1">Improved decision-making​</p>
        </div>
        <div class="col-md-2 key-margin">
        <img src="./images/solutions/Data-Accoutability.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p1">Better compliance</p>
        </div>
    </div>
</div>
      
       <Footer/>
    </div>
  )
}

export default ContractAbstraction
