import React from 'react'
import './Solutions.css';
import Footer from '../components/Footer';

const SnowFlakeManaged = () => {
  return (
    <div>
        {/* <div class="container-fluid"> */}
  <img src="../images/solutions/snowflake-managed.jpg" alt="Nature" class="service-header-img"/>
  <div class="text-whitedata">
  <h1 class="text-size-head-white">Snowflake Managed <br/>
Services Program</h1>
<h4 >Simplify Data Management with Snowflake Managed Services</h4>
  </div>
{/* </div> */}

<div class="container">
    <div class="row" style={{color:"rgb(125, 136, 155)",marginTop:"40px"}}>
      <div class="insight-matter"> <h1>What we do</h1>
        <p>Today, organizations need to prioritize achieving optimal performance and efficiency from their data warehousing platform, while reducing the 
            workload of their internal IT team so that they can concentrate on their core business operations.
​​​​​</p>  
  <p>SSS Snowflake Managed Services program helps organizations optimize their Snowflake environment, increase efficiency,
     and improve the overall performance of their data warehousing and analytics workflows.​</p>

    </div> </div> 
</div>
<div class="container">
<div class="row">
    <div class="solutions-head">
        <h1>Key Component</h1>
    </div>
    <div class="col-md-4"><p class="key-features-p">Assessment of current<br/> environment​​</p></div>
    <div class="col-md-4"><p class="key-features-p">Warehouse sizing <br/>and scaling​​​</p></div>
    <div class="col-md-4"><p class="key-features-p">Monitoring and <br/>diagnosing performance​​​</p></div>
    <div class="col-md-4"><p class="key-features-p">Managing <br/>workloads​​</p></div>
    <div class="col-md-4"><p class="key-features-p">Optimizing data <br/>loading and integration​​​</p></div>
    <div class="col-md-4"><p class="key-features-p">Ensuring security and <br/>access control​​​</p></div>
    
</div>
</div>

<div class="container-fluid key-benefits-bg-color">
    <div class="row ">
        <div class="solutions-head">
            <h1>Key Benefits</h1>
        </div>
        <div class="col-md-4 key-margin1 row-center2">
            <img src="./images/solutions/IT-Infrastructure-2 (1).png" alt="" class="key-benifits-img"></img>
            <p class="key-benifits-p">Reduced IT infrastructure cost</p>
        </div>
        <div class="col-md-4 key-margin1 row-center2">
        <img src=" ./images/solutions/Resources-Utilization-1.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Resource utilization optimization</p>
        </div>
        <div class="col-md-4 key-margin1 row-center2">
        <img src="./images/solutions/System-Monitoring-2.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Enhanced overall system efficiency</p>
        </div>
      
    </div>
</div>
      
       <Footer/>
    </div>
  )
}

export default SnowFlakeManaged
