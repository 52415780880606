import React from 'react'
import './Solutions.css';
import Footer from '../components/Footer';

const PowerBiMigration = () => {
  return (
    <div>
        {/* <div class="container-fluid"> */}
  <img src="../images/solutions/data-analytics.jpg" alt="Nature" class="service-header-img"/>
  <div class="text-whitedata">
  <h1 class="text-size-head-white">Data Analytics<br/>
Managed Services<br/>
Program</h1>
<h4 >Accelerate decision making with our data analytics solution</h4>
  </div>
{/* </div> */}

<div class="container">
    <div class="row" style={{color:"rgb(125, 136, 155)",marginTop:"40px"}}>
      <div class="insight-matter"> <h1>What we do</h1>
        <p>By outsourcing their data analytics processes, organizations can reduce costs, improve performance, and access the latest analytics technologies, allowing them to make data-driven decisions more quickly and effectively. sss’s Data Analytics Managed Services program helps organizations reduce costs, increase efficiency, and access specialized expertise,
             enabling them to focus on core business functions and gain competitive advantage.</p>
           
        </div> </div> 
</div>
<div class="container">
    <div class="row row-key-features">
        <div class="solutions-head"><h1>Key Features</h1></div>
        <div class="col-lg-4"><p class="key-components-p" >End-to-End managed services for analytics tools/platforms</p></div>
        <div class="col-lg-4"><p class="key-components-p" >24×7 monitoring, enhancement & support</p></div>
        <div class="col-lg-4"><p class="key-components-p" >Platform upgrades with <br/>accelerators</p></div>
    </div>
</div>
<div class="container-fluid key-benefits-bg-color">
    <div class="row row-center">
        <div class="solutions-head">
            <h1>Key Benefits</h1>
        </div>
        <div class="col-md-2 key-margin">
            <img src="./images/solutions/IT-Infrastructure-2 (1).png" alt="" class="key-benifits-img"></img>
            <p class="key-benifits-p1">Reduced IT infrastructure costs</p>
        </div>
        <div class="col-md-2 key-margin">
        <img src=" ./images/solutions/Expert-Team.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p1">Access to specialized expertise</p>
        </div>
        <div class="col-md-2 key-margin">
        <img src="./images/solutions/Scalability.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p1">Improved scalability</p>
        </div>
        <div class="col-md-2 key-margin">
        <img src="./images/solutions/Data-Security.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p1">Enhanced data security​​</p>
        </div>
        <div class="col-md-2 key-margin">
        <img src="./images/solutions/Reduce-Downtime-1.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p1">Reduced downtime​</p>
        </div>
        <div class="col-md-2 key-margin">
        <img src="./images/solutions/Cost-3.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p1">Predictable costs</p>
        </div>
    </div>
</div>
      

       <Footer/>
    </div>
  )
}

export default PowerBiMigration
