import React from 'react'
import './Solutions.css';
import Footer from '../components/Footer';

const Cloudera = () => {
  return (
    <div>
        {/* <div class="container-fluid"> */}
  <img src="../images/solutions/cloudera.jpg" alt="Nature" class="service-header-img"/>
  <div class="text-whitedata">
  <h1 class="text-size-head-white">Cloudera CDP<br/>
Migration</h1>
<h4 >Enhance Analytics with Cloudera CDP Migration Program</h4>
  </div>
{/* </div> */}

<div class="container">
    <div class="row" style={{color:"rgb(125, 136, 155)",marginTop:"40px"}}>
      <div class="insight-matter"> <h1>What we do</h1>
        <p>To stay ahead of future changes, a forward-thinking organization must continuously seek out
             the latest features and capabilities that can enhance their analytics and stream processing capabilities. ​​​​​</p>
    <p>SSS Cloudera CDP Migration Program helps organizations gain access to new features and
         functionalities that enhance their big data experience.​</p>
    </div> </div> 
</div>
<div class="container">
<div class="row">
    <div class="solutions-head">
        <h1>Key Features</h1>
    </div>
    <div class="col-lg-4"><p class="key-features-p">Assessment and planning​​</p></div>
    <div class="col-lg-4"><p class="key-features-p">Platform migration​​​</p></div>
    <div class="col-lg-4"><p class="key-features-p">Stabilization and support​​​</p></div>
    
</div>
</div>

<div class="container-fluid key-benefits-bg-color">
    <div class="row">
        <div class="solutions-head">
            <h1>Key Benefits</h1>
        </div>
        <div class="col-md-3 key-margin3 row-center">
            <img src="./images/solutions/Structured-methodologies.png" alt="" class="key-benifits-img3"></img>
            <p class="key-benifits-p">Structured methodologies​</p>
        </div>
        <div class="col-md-3 key-margin3 row-center">
        <img src=" ./images/solutions/Ease-of-migration.png" alt="" class="key-benifits-img3"></img>
        <p class="key-benifits-p">Ease of migration​​​</p>
        </div>
        <div class="col-md-3 key-margin3 row-center">
        <img src="./images/solutions/Process-efficiency.png" alt="" class="key-benifits-img3"></img>
        <p class="key-benifits-p">Process efficiency​</p>
        </div>
        <div class="col-md-3 key-margin3 row-center">
        <img src="./images/solutions/Time-saving.png" alt="" class="key-benifits-img3"></img>
        <p class="key-benifits-p">Time saving​​​</p>
        </div>
    </div>
</div>
      

       <Footer/>
    </div>
  )
}

export default Cloudera
