import React from 'react'
import './Solutions.css';
import Footer from '../components/Footer';

const DataScienceService = () => {
  return (
    <div>
        {/* <div class="container-fluid"> */}
  <img src="../images/solutions/Data-Science-Service.jpg" alt="Nature" class="service-header-img"/>
  <div class="text-whitedata">
  <h1 class="text-size-head-white">Data Science as a<br/>
Service</h1>
<h4 >Elevate Your Decision-Making with sss’s Data Science as a Service (DSaaS)</h4>
  </div>
{/* </div> */}

<div class="container">
    <div class="row" style={{color:"rgb(125, 136, 155)",marginTop:"40px"}}>
      <div class="insight-matter"> <h1>What we do</h1>
        <p>Data Science As A Service (DSaaS) is a cost-effective and scalable solution for organizations
             that do not have the resources or 
            expertise to build and maintain their own data science teams or infrastructure.​​​</p>
    <p>SSS Data Science as a Service (DSaaS) program helps organizations gain access to advanced analytics
     technologies, reduce costs, and accelerate their data-driven decision-making processes..​</p>
    </div> </div> 
</div>
<div class="container-fluid key-benefits-bg-color">
    <div class="row">
        <div class="solutions-head">
            <h1>Key Benefits</h1>
        </div>
        <div class="col-md-3 key-margin3 row-center">
            <img src="./images/solutions/Innovation.png" alt="" class="key-benifits-img3"></img>
            <p class="key-benifits-p">Increased innovation​</p>
        </div>
        <div class="col-md-3 key-margin3 row-center">
        <img src=" ./images/solutions/Cost-Reduction.png" alt="" class="key-benifits-img3"></img>
        <p class="key-benifits-p">Cost reduction​</p>
        </div>
        <div class="col-md-3 key-margin3 row-center">
        <img src="./images/solutions/Expert-Team.png" alt="" class="key-benifits-img3"></img>
        <p class="key-benifits-p">Access to specialized <br/>expertise​</p>
        </div>
        <div class="col-md-3 key-margin3 row-center">
        <img src="./images/solutions/Scalability.png" alt="" class="key-benifits-img3"></img>
        <p class="key-benifits-p">Improved scalability​​​</p>
        </div>
        <div class="col-md-3 key-margin3 row-center">
        <img src="./images/solutions/data-protection.png" alt="" class="key-benifits-img3"></img>
        <p class="key-benifits-p">Enhanced data <br/>protection​​​</p>
        </div>
        
        <div class="col-md-3 key-margin3 row-center">
        <img src="./images/solutions/Reduced-market.png" alt="" class="key-benifits-img3"></img>
        <p class="key-benifits-p">Reduced time to market​</p>
        </div>
        <div class="col-md-3 key-margin3 row-center">
        <img src="./images/solutions/Decision-Making.png" alt="" class="key-benifits-img3"></img>
        <p class="key-benifits-p">Improved decision making​</p>
        </div>
    </div>
</div>
      

       <Footer/>
    </div>
  )
}

export default DataScienceService
