import React from 'react'
import './Solutions.css';
import Footer from '../components/Footer';

const DataStrategy = () => {
  return (
    <div>
        {/* <div class="container-fluid"> */}
  <img src="../images/solutions/data-strategy.jpg" alt="Nature" class="service-header-img"/>
  <div class="text-whitedata">
  <h1 class="text-size-head-white">Data Strategy</h1>
<h4 >Get faster insight with high quality and resilience for constant changes</h4>
  {/* </div> */}
</div>

<div class="container">
    <div class="row" style={{color:"rgb(125, 136, 155)",marginTop:"40px"}}>
      <div class="insight-matter"> <h1>What we do</h1>
        <p>The acceleration of digital transformation has increased the demand for data and analytics 
            leaders to provide faster insights, with higher quality and resilience to withstand constant change.​​​</p>
       <p>SSS Data Strategy framework utilizes a methodology based on best practices that allows for
         the evaluation of current state capabilities on a wide scale, determination of the desired end state 
         for various data
         management domains, and delivery of a comprehensive set of actionable recommendations.​</p>
    </div> </div> 
</div>
<div class="container-fluid key-benefits-bg-color">
    <div class="row">
        <div class="solutions-head">
            <h1>Key Benefits</h1>
        </div>
        <div class="col-md-2 key-margin1 row-center">
            <img src="./images/solutions/Speed.png" alt="" class="key-benifits-img"></img>
            <p class="key-benifits-p">Improve Speed</p>
        </div>
        <div class="col-md-2 key-margin1 row-center">
        <img src=" ./images/solutions/Competitiveness.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Addressing Competitive Demand</p>
        </div>
        <div class="col-md-2 key-margin1 row-center">
        <img src="./images/solutions/Data-Literacy.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Fostering a Coluture<br/> of Data Literacy</p>
        </div>
        <div class="col-md-2 key-margin1 row-center">
        <img src="./images/solutions/Customer-Experience.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Improve Customer Experience​​</p>
        </div>
        <div class="col-md-2 key-margin1 row-center">
        <img src="./images/solutions/Data-Security.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Mitigate Data Security​​</p>
        </div>
        {/* <div class="col-md-2 key-margin">
        <img src="./images/solutions/Data-Literacy-2.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Ethical use and <br/>handling of data​​​</p>
        </div> */}
    </div>
</div>
      

       <Footer/>
    </div>
  )
}

export default DataStrategy
