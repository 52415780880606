import { useState } from 'react';
import ReactSimplyCarousel from 'react-simply-carousel';
import './Carousel.css';

function DataAnalyticsCarousel1() {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <div>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        forwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '30px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`>`}</span>,
        }}
        backwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '20px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`<`}</span>,
        }}
        responsiveProps={[
          {
            itemsToShow: 3,
            itemsToScroll: 3,
            minWidth: 768,
          },
        ]}
        speed={400}
        easing="linear"
      >
        {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}
        <div style={{ width: 300, height: 550 }}>
        <div class="card-body cloud-card-body-colors1" >
              <h5 class="card-title">Data Analytics Vigilance Program </h5>
              <ul class="card-text1">
              <li>Provision and Manage Infrastructure for analytics Solutions</li>
              <li>Refine and Optimize Models, Achieve KPIs, Identify Issues, and Track Changes</li>
              <li>Educate on Big Data Technologies for Informed Adoption</li>
              <li>Ensure Data Quality and Maintain Data Catalog</li>
              <li>Provide Support and Maintenance Services</li>
              </ul>
            </div>
            </div>
        <div style={{ width: 300, height: 550}}>
        <div class="card-body cloud-card-body-colors1"  >
            <h5 class="card-title">Transparent Analytics Framework</h5>
              <ul class="card-text1">
              <li>Promotes Transparency in Analytics Decision-Making</li>
              <li>Especially Beneficial in Healthcare, Finance, and Legal Sectors</li>
              <li>Incorporates Data Pre-processing</li>
              <li>Prioritizes Clear and Succinct Explanations</li>
              <li>Fosters Trust Among Users and Stakeholders</li>
                
                </ul>
            </div>
        </div>
        <div style={{ width: 300, height: 550}}>
        <div class="card-body cloud-card-body-colors1" >
             
              <h5 class="card-title">Text Extraction from Documents using NLP</h5>
              <ul class="card-text1">
              <li>Manual Contract Management is Time-Consuming, Costly, and Risky</li>
              <li>Contract Abstraction Identifies Key Terms, Clauses, Dates, and Relevant Data</li>
              <li>NLP Techniques Facilitate Information Extraction</li>
              <li>Web Scraping Involves Data Extraction</li>

                </ul>
               </div>
        </div>
        <div style={{ width: 300, height: 550 }}>
        <div class="card-body cloud-card-body-colors1"  >
            <h5 class="card-title">Transparent Analytics Framework</h5>
              <ul class="card-text1">
              <li>Promotes Transparency in Analytics Decision-Making</li>
              <li>Especially Beneficial in Healthcare, Finance, and Legal Sectors</li>
              <li>Incorporates Data Pre-processing</li>
              <li>Prioritizes Clear and Succinct Explanations</li>
              <li>Fosters Trust Among Users and Stakeholders</li>
                
                </ul>
            </div>
        </div>
        <div style={{ width: 300, height: 550}}>
        <div class="card-body cloud-card-body-colors1" >
             
             <h5 class="card-title">Text Extraction from Documents using NLP</h5>
             <ul class="card-text1">
             <li>Manual Contract Management is Time-Consuming, Costly, and Risky</li>
             <li>Contract Abstraction Identifies Key Terms, Clauses, Dates, and Relevant Data</li>
             <li>NLP Techniques Facilitate Information Extraction</li>
             <li>Web Scraping Involves Data Extraction</li>

               </ul>
              </div>

        </div>
        <div style={{ width: 300, height: 550 }}>
        <div class="card-body cloud-card-body-colors1" >
              <h5 class="card-title">Interactive Analytics Solutions</h5>
              <ul class="card-text1">
              <li>Identify the Customer Business and provide Tailored solutions using advanced Conversational Analytics solutions</li>
              <li>Developing Intelligent Digital Assistants</li>
              <li>Enhancing User Experience</li>
             
               </ul>
            </div>
        </div>
    
      </ReactSimplyCarousel>
    </div>
  );
}

export default DataAnalyticsCarousel1;