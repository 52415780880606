import { useState } from 'react';
import ReactSimplyCarousel from 'react-simply-carousel';
import './Carousel.css';

function DataEngineeringCarousel1() {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <div>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        forwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '30px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`>`}</span>,
        }}
        backwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '20px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`<`}</span>,
        }}
        responsiveProps={[
          {
            itemsToShow: 3,
            itemsToScroll: 3,
            minWidth: 768,
          },
        ]}
        speed={400}
        easing="linear"
      >
        
        <div style={{ width: 300, height: 550}}>
        <div class="card-body cloud-card-body-colors1" >
              <h5 class="card-title">Advisory​ Managed Services​ </h5>
              <ul class="card-text1">
              <li>Define Scope​​</li>
              <li>Define Training Needs</li>
              <li>Define Project Charter, SLA, RACI Metrics​​</li>
              <li>Risk Assessment and Management​​</li>
              <li>Resource Management​</li>
              <li>Quality Assurance and Control</li>
              <li>Stakeholder Engagement</li>
              <li>Communication Plan</li>
              </ul>
            </div>
        </div>
        <div style={{ width: 300, height: 550}}>
        <div class="card-body cloud-card-body-colors1"  >
              <h5 class="card-title">Process Development Implementation Program</h5>
              <ul class="card-text1">
                <li>Detailed Process Documentation</li>
                <li>Technology Integration</li>
                <li>Project Kickof</li>
                <li>Performance Measurement and KPIs</li>
                <li>Ongoing Support and Monitoring</li>
                <li>Performance Measurement and KPIs</li>
                </ul>
            </div>
        </div>
        <div style={{ width: 300, height: 550 }}>
        <div class="card-body cloud-card-body-colors1"  >
              <h5 class="card-title">Process Development Managed Services </h5>
              <ul class="card-text1">
                <li>Regulatory Compliance and Industry Standards</li>
                <li>Data-Driven Insights and Reporting</li>
                <li>Testing, Validation, and Performance Measurement</li>
                <li>Process Analysis and Design</li>
                <li>Assessment and Strategy Development</li>
                <li>Pilot Implementation</li>
                </ul>
               </div>
        </div>
        <div style={{ width: 300, height: 550}}>
        <div class="card-body cloud-card-body-colors1">
            <h5 class="card-title">Process Development Implementation Program</h5>
              <ul class="card-text1">
                <li>Detailed Process Documentation</li>
                <li>Technology Integration</li>
                <li>Project Kickof</li>
                <li>Performance Measurement and KPIs</li>
                <li>Ongoing Support and Monitoring</li>
                <li>Performance Measurement and KPIs</li>
                </ul>
            </div>
        </div>
        <div style={{ width: 300, height: 550 }}>
        <div class="card-body cloud-card-body-colors1"  >
              <h5 class="card-title">Process Development Managed Services </h5>
              <ul class="card-text1">
                <li>Regulatory Compliance and Industry Standards</li>
                <li>Data-Driven Insights and Reporting</li>
                <li>Testing, Validation, and Performance Measurement</li>
                <li>Process Analysis and Design</li>
                <li>Assessment and Strategy Development</li>
                <li>Pilot Implementation</li>
                </ul>
            </div>
        </div>
        <div style={{ width: 300, height: 550 }}>
        <div class="card-body cloud-card-body-colors1" >
              <h5 class="card-title">Process Development Accelerators</h5>
              <ul class="card-text1">
                <li>Process Templates and Frameworks</li>
                <li>Change Management Playbooks</li>
                <li>Workflow Automation Platforms</li>
                <li>Continuous Improvement Toolkits</li>
                <li>Collaboration and Communication Platforms</li>
                <li>Standardized Reporting Templates</li>
               </ul>
           </div>
        </div>
    
      </ReactSimplyCarousel>
    </div>
  );
}

export default DataEngineeringCarousel1;