import React from 'react'
import './ServicesStyle.css';
import Footer from '../components/Footer';
import TechnologyList from '../components/TechnologyList';
import DataEngineeringCarousel from './DataEngineeringCarousel';
import DataEngineeringCarousel1 from './DataEngineeringCarousel1';
import { Link } from 'react-router-dom';
const DataEngineering = () => {
  return (
    <div>

       <div className="container-fluid serviceDisplay"  style={{marginTop:'15vh'}}>
  <div className='row  ' >
    <div className='col'>
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/Service+page+sliders/Website+Sliders+Service+Page-01.jpg" alt="Nature" class="service-header-imgs-service"/>
    </div>
  </div>
</div>

<div class="container-fluid ">
  <div class="text-whitedata whitedata">
  <h1 class="text-size-head-white serviceDisplay-tag" >Data Engineering </h1>
<h4 class="text-size-head-white-h4 serviceDisplay-tags" >Innovations and Strategies for Seamless Data Integration, Transformation, and Analysis</h4>
  </div>

      </div>


<div class="container">
    <div class="row "  >
      <div class="insight-matters"> <h1>Insights that matter</h1>

            <p class="governance-data-size">Data engineering is an indispensable cornerstone in modern businesses' pursuit of leveraging data for operational excellence and strategic decision-making. It involves the meticulous process of collecting, organizing, and transforming raw data into meaningful insights that drive informed actions and foster innovation.
            </p>
<p class="governance-data-size">
At our core, we offer comprehensive data engineering services that span the entire data lifecycle. Our team comprises seasoned professionals and experts in data manipulation, integration, and architecture. We understand that data comes from diverse sources, both structured and unstructured, and our expertise lies in extracting its true value.</p>
             {/* <div class="bt-start-now">

              <a class="btn-start" type="button" value="START NOW" placeholder="START NOW" href="/contact" target=""><p>START NOW</p></a>
            </div> */}
             <div className="text-center">
      <Link
        className="fusion-button button-flat fusion-button-default-size button-custom fusion-button-default button-1 fusion-button-default-span startNowButton custom-button"
        target="_self"
        to="/contact"
      >
        <span className="fusion-button-text">START NOW</span>
        {/* <i className="fa-long-arrow-alt-right fas button-icon-right" aria-hidden="true"></i> */}
      </Link>
    </div>
          </div> </div>
</div>

<div class="container">
<div class="row">
    <div class="governance-data">
        <h1>Empowering Growth through Data Engineering Excellence</h1>

    <p class="governance-data-size">In the dynamic landscape of modern business, data engineering emerges as a pivotal force, shaping the way organizations harness the potential of their data. At SSS, we specialize in leveraging the power of data engineering to drive insights, innovation, and operational efficiency. </p><br/>
    <h6 class="challenges-size">Our data engineering services encompass the entire data lifecycle, from collection to transformation. With a team of seasoned experts, we are dedicated to helping you derive meaningful insights from your raw data. Our offerings include:</h6>
        </div>


<div class="col-md-6">
    <div class="challenges">
        {/* <h6 class="challenges-size">Our data engineering services encompass the entire data lifecycle, from collection to transformation. With a team of seasoned experts, we are dedicated to helping you derive meaningful insights from your raw data. Our offerings include:</h6> */}
       <br/>
        <ul>
       <p >  <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/>
      Data Variety and Complexity
    </p>
   <p> <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/>
      Data Volume and Scalability
    </p>
    <p> <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/>
     Data Quality and Cleansing
    </p>
    <p>  <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/>
      Data Security and Privacy
    </p>
{/*
                 <p> <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img1"/>
      Continuous Monitoring
                </p> */}


</ul>
</div></div>
<div class="col-md-6 ">
    <div class="interactive">
        {/* <h6 class="challenges-size">Amid the Covid-19 crisis, businesses rapidly embraced the cloud for digital transformation.
             Cloud technology-enabled remote work and offered significant benefits, including:</h6> */}
       <br/>
        <ul>

  <p class="interactive-margin">
   <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/>
   Reduces IT and infrastructure cost​
    </p>

   <p>  <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/>
      Scalability Collaboration efficiency​
    </p>
    <p> <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/>
       Flexibility of work practices​
    </p>
    <p>  <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/>
       Security and mobility​
    </p>
   <br/>
</ul>

  </div></div><br/>

<div class="container-fluid">
  <div class="row">
  <div class="bigdata">
    <h1> Data Engineering Practice – Services Offerings​</h1>
</div>
    <DataEngineeringCarousel/>
  </div>
</div>

   {/* <div class="container-fluid">
  <div class="row service-row1" >
<div class="bigdata">
    <h1> Data Engineering Practice – Services Offerings​</h1>
</div>

<div class="slide-show">
<div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">

    <div class="carousel-item active">
      <div class="card-group service-card-group" >
      <div class="card bi-card-size"  >
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/data-engineering/data-collection.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black">Data Collection and Ingestion​</h5>
      <ul class="card-text " >
        <li>Event Tracking and Tagging​</li>
        <li>User Behavior Analysis​</li>
        <li>Log Data Ingestion​</li>
                              <li>External Data Sources​</li>
                              <li>Data Enrichment​</li>
                              <li>Clickstream Data​</li>
                             <li> API Rate Limiting and Quotas</li>
       <li> Data Validation and Schema Evolution</li>
       </ul> </div>
  </div>
  <div class="card bi-card-size" >
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/data-engineering/data-transformation.png" class="rpa-img-top" alt="..." />
    <div class="card-body ">
      <h5 class="card-title text-body-color-black">Data Transformation and ETL ​</h5>

      <ul class="card-text" >
      <li>Provide solutions using appropriate Data Profiling for Data Engineering services</li>
      <li>Data Cleansing​</li>
      <li>Data Normalization and Standardization</li>
      <li>Data Enrichment and Augmentation​</li>
      <li>Data Aggregation and Summarization</li>

       </ul>
        </div>
  </div>
  <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/data-engineering/data-governance.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Data Governance and Security</h5>
      <ul class="card-text" >
      <li>Access Control and Authorization​​</li>
      <li>Data Encryption​</li>
      <li>Masking and Anonymization​</li>
      <li>Data Retention and Purging</li>
      <li>Offers scalable, flexible infrastructure for data engineering​</li>
      <li>Data Privacy Regulations Compliance</li>
       </ul>
    </div>
  </div>
 </div> </div>

 <div class="carousel-item">
      <div class="card-group service-card-group" >
      <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/data-engineering/Advance-Analytics-Services.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
    <h5 class="card-title text-body-color-black" >Data Analytics and Machine Learning ​</h5>
      <ul class="card-text">
      <li>Data Sampling and Splitting</li>
      <li>Data Preprocessing for ML​</li>
      <li>Data Versioning and Lineage for ML</li>
      <li>Data Labeling and Annotation​</li>
      <li>Data Bias and Fairness Assessment</li>

       </ul>
        </div>
  </div>
  <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/data-engineering/Data-Warehouse.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
    <h5 class="card-title text-body-color-black" >Data Warehousing ​Managed Services​</h5>
      <ul class="card-text">
      <li>Data Partitioning and Clustering​​</li>
      <li>Data Compression and Storage Optimization​</li>
      <li>ETL into Data Warehouse​</li>
      <li>Incremental Loading</li>
      <li>Data Compression and Storage Optimization​</li>
      <li>Regularly monitoring and optimizing query performance.</li>
       </ul>
    </div>
  </div>

  <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/data-engineering/Data-Visualization-Data-Storytelling-2.png" class="rpa-img-top" alt="..."/>
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Data Visualization<br/> Services</h5>
      <ul class="card-text">
      <li>Creating interactive dashboards that allow users to explore data, change parameters.​</li>
      <li>Developing custom visualizations that address specific business needs</li>
      <li>Integrating real-time data streams into visualizations</li>

      <li>Trend Analysis and Time Series Visualizations​</li>
       </ul>
    </div>
  </div>
 </div></div>
</div>
  <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div></div>
</div></div>  */}

<div class="container">
  <div class="row">
<div class="mg-management">
    <h1>Data Engineering – Programs | Frameworks | Accelerators</h1>
</div>
<DataEngineeringCarousel1/>
</div></div>

 {/* <div class="container">
  <div class="row">
<div class="mg-management">
    <h1>Data Engineering – Programs | Frameworks | Accelerators</h1>
</div>

<div id="myCarousel" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <div class="row" >
        <div class="col-lg-4" >
          <div class="card text ">
            <div class="card-body cloud-card-body-colors1">
              <h5 class="card-title">Data Engineering Roadmap Strategy Program​ </h5>
              <ul class="card-text">
              <li>Data Modeling​</li>
                 <li>Data ETL (Extract, Transform, Load)</li>
              <li>Data Warehousing</li>
               <li>Data Quality and Cleansing</li>
              <li>Data Integration</li>
                              <li>Workflow Automation</li>
                              <li>Performance Optimization</li>
                               <li>Data Governance</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4" >
          <div class="card text">
            <div class="card-body cloud-card-body-colors1" >
                            <h5 class="card-title"> Data Engineering Implementation Program​</h5>
                            <br/>
              <ul class="card-text">
              <li>Data Integration Framework</li>
              <li>streamlines ETL processes, reducing manual effort and increasing efficiency.</li>
              <li>Data quality through validation, cleansing, and error handling</li>
              <li>Processing and analyzing data in real-time streaming scenarios.​</li>
              <li>Big Data Processing Platform​</li>
              <li>Data Warehousing Solution​</li>

                </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card text">
            <div class="card-body cloud-card-body-colors1" >

                            <h5 class="card-title"> Data engineering Hypercare Program​ </h5>
                            <br/>
              <ul class="card-text">
              <li>Go-live and steady-state support​</li>
              <li>Continuous monitoring, incident management​</li>
              <li>Issue resolution, root cause analysis​</li>
              <li>Performance analysis</li>
              <li>Stakeholder communication​</li>
              <li>On-demand experienced resource availability​</li>

                </ul>
               </div>
          </div>
        </div>
      </div>
    </div>



    <div class="carousel-item ">
      <div class="row" >
        <div class="col-lg-4">
          <div class="card text " >
            <div class="card-body cloud-card-body-colors1" >
              <h5 class="card-title">Data Engineering Implementation Program​</h5>
              <ul class="card-text">
              <li>Assess data needs, define strategies, and align them with business goals.</li>
              <li>Designing effective data models for structured and unstructured data.</li>
              <li>Integrating data from multiple sources and designing ETL pipelines.</li>
              <li>Maintaining data accuracy, completeness, and consistency.​</li>
              <li>Implementing data warehouses for reporting and analysis.​</li>
              <li>Implement analytics solutions to extract insights from data​</li>

                </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card text">
            <div class="card-body cloud-card-body-colors1"  >

              <h5 class="card-title"> Data Engineering Hypercare Program​ </h5>
              <ul class="card-text">
              <li>Go-live and steady-state support​</li>
              <li>Continuous monitoring, incident management​</li>
              <li>Issue resolution, root cause analysis​</li>
              <li>Performance analysis</li>
              <li>Stakeholder communication​</li>
              <li>On-demand experienced resource availability​</li>

                </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4" >
          <div class="card text ">
            <div class="card-body cloud-card-body-colors1">
            <h5 class="card-title">Legacy System Modernization Program​</h5>
              <ul class="card-text">
             <li>Identify tech stack</li>
             <li>Assess legacy systems​</li>
             <li>Migrate EDW to the cloud</li>
             <li>Re-platform ETL processes</li>
             <li>Define modernization approach, data migration strategy​</li>
             <li>Integrate with other systems</li>
             <li>Implement data quality, validation​</li>


              </ul>
           </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
</div>
</div> */}

<div class="container">
    <div class="row" style={{marginTop:"0px",marginBottom:"30px"}}>
    <div class="mg-management">
    <h1>Our Technology Expertises</h1>
</div>

              <TechnologyList/>
    </div>
</div>


</div></div>




      <Footer/>
      </div>
  )
}

export default DataEngineering
