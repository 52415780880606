import { useState } from 'react';
import ReactSimplyCarousel from 'react-simply-carousel';
import './Carousel.css';

function DataEngineeringCarousel1() {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <div>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        forwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '30px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`>`}</span>,
        }}
        backwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '20px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`<`}</span>,
        }}
        responsiveProps={[
          {
            itemsToShow: 3,
            itemsToScroll: 3,
            minWidth: 768,
          },
        ]}
        speed={400}
        easing="linear"
      >
        {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}
        <div style={{ width: 300, height: 550 }}>
        <div class="card-body cloud-card-body-colors1">
              <h5 class="card-title">Data Engineering Roadmap Strategy Program​ </h5>
              <ul class="card-text1">
              <li>Data Modeling​</li>
                 <li>Data ETL (Extract, Transform, Load)</li>
              <li>Data Warehousing</li>
               <li>Data Quality and Cleansing</li>
              <li>Data Integration</li>
                              <li>Workflow Automation</li>
                              <li>Performance Optimization</li>
                               <li>Data Governance</li>
              </ul>
            </div>
            </div>
        <div style={{ width: 300, height: 550}}>
        <div class="card-body cloud-card-body-colors1" >
                            <h5 class="card-title"> Data Engineering Implementation Program​</h5>
                           
              <ul class="card-text1">
              <li>Data Integration Framework</li>
              <li>streamlines ETL processes, reducing manual effort and increasing efficiency.</li>
              <li>Data quality through validation, cleansing, and error handling</li>
              <li>Processing and analyzing data in real-time streaming scenarios.​</li>
              {/* <li>Big Data Processing Platform​</li> */}
              {/* <li>Data Warehousing Solution​</li> */}
                
                </ul>
            </div>
        </div>
        <div style={{ width: 300, height: 550}}>
        <div class="card-body cloud-card-body-colors1" >
             
                            <h5 class="card-title"> Data engineering Hypercare<br/> Program​ </h5>
                          
              <ul class="card-text1">
              <li>Go-live and steady-state support​</li>
              <li>Continuous monitoring, incident management​</li>
              <li>Issue resolution, root cause analysis​</li>
              <li>Performance analysis</li>
              <li>Stakeholder communication​</li>
              <li>On-demand experienced resource availability​</li>

                </ul>
               </div>
        </div>
        <div style={{ width: 300, height: 550 }}>
        <div class="card-body cloud-card-body-colors1" >
              <h5 class="card-title">Data Engineering Implementation <br/>Program​</h5>
              <ul class="card-text1">
              <li>Assess data needs, define strategies, and align them with business goals.</li>
              <li>Designing effective data models for structured and unstructured data.</li>
              <li>Integrating data from multiple sources and designing ETL pipelines.</li>
              <li>Maintaining data accuracy, completeness, and consistency.​</li>
              {/* <li>Implementing data warehouses for reporting and analysis.​</li>
              <li>Implement analytics solutions to extract insights from data​</li> */}
                
                </ul>
            </div>
        </div>
        <div style={{ width: 300, height: 550}}>
        <div class="card-body cloud-card-body-colors1"  >
             
              <h5 class="card-title"> Data Engineering Hypercare<br/> Program​ </h5>
              <ul class="card-text1">
              <li>Go-live and steady-state support​</li>
              <li>Continuous monitoring, incident management​</li>
              <li>Issue resolution, root cause analysis​</li>
              <li>Performance analysis</li>
              <li>Stakeholder communication​</li>
              <li>On-demand experienced resource availability​</li>

                </ul>
            </div>


        </div>
        <div style={{ width: 300, height: 550 }}>
        <div class="card-body cloud-card-body-colors1">
            <h5 class="card-title">Legacy System Modernization<br/> Program​</h5>
              <ul class="card-text1">
             <li>Identify tech stack</li>
             <li>Assess legacy systems​</li>
             <li>Migrate EDW to the cloud</li>
             <li>Re-platform ETL processes</li>
             <li>Define modernization approach, data migration strategy​</li>
             <li>Integrate with other systems</li>
             <li>Implement data quality, validation​</li>
      

              </ul>
           </div>
        </div>
    
      </ReactSimplyCarousel>
    </div>
  );
}

export default DataEngineeringCarousel1;