


import React from 'react';

const styles = {
 
  row: {
    margin: '0 -10px', 
    display: 'flex', 
    flexWrap: 'wrap', 
  },
  column: {
    width: '100%', 
    padding: '0 10px',
    boxSizing: 'border-box', 
  },
  card: {
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    padding: '12px',
      textAlign: 'start',
    display: '',

    fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
    fontWeight: '500',

      
    
  color:'black',
    backgroundColor: 'white',
    marginBottom:"5px"
    
    },
 
  
  
};

const ResponsiveColumnCards = () => {
  return (
    <div style={styles.body}>
  <div className='bi-service'>
           <h3>
            <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/Logos/rotate_Logo.gif" style={{width:"8%"}} />  Our Mission/Our Vision
        </h3> </div>
      <div style={styles.row}>
        <div style={styles.column}>
          <div style={styles.card}>
          <div className='bi-service'>
            <h3 className='text-center' style={{fontSize:'32px'}}>OUR MISSION</h3></div>
            <p className='mission' style={{fontSize:'18px',padding:"2px"}} >"At Srinivasan Software Solutions, we are dedicated to transforming your dream vision into reality. Our primary mission is to enhance customer value by providing seamless services that foster consistent business growth in a mutually beneficial partnership. We specialize in enriching proactive business needs by establishing reliable standards and maintaining a transparent work style. Our commitment is to empower your business and help you achieve success."</p>
            {/* <p>Some text</p> */}
          </div>
        </div>

        <div style={styles.column}>
          <div style={styles.card}>
          <div className='bi-service'>
            <h3 className='text-center' style={{fontSize:'32px'}}>OUR VISION</h3></div>
            <p className='mission-text' style={{fontSize:'18px',}}>"Srinivasan Software Solutions is dedicated to setting the standard for excellence. We are committed to delivering outstanding performance through meticulous planning and continuous innovation. Our exceptional team is at the core of our success, and we relentlessly pursue operational perfection with an unwavering focus on our customers. Our goal is to be the top choice for businesses of all sizes, helping them achieve and exceed their strategic objectives."</p>
            {/* <p>Some text</p> */}
          </div>
        </div>

      
      </div>
    </div>
  );
};

export default ResponsiveColumnCards;
