import React from 'react'
import './ServicesStyle.css';
import Footer from '../components/Footer';
import TechnologyList from '../components/TechnologyList';
import DevopsServicesCarousel from './DevopsServicesCarousel';
import DevopsServicesCarousel1 from './DevopsServicesCarousel1';
import { Link } from 'react-router-dom';
const DevopsServices = () => {
  return (
    <div>
      {/* <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/devops_services/DevOps.jpg" alt="Nature" class="service-header-img"/>
<div class="container-fluid">

  <div class="text-whitedata">
  <h1 class ="text-size-head-white" style={{fontSize:"44px"}}>Devops <br/>Services</h1>
<h4 class ="text-size-head-white-h4" >Unlocking Agility and Reliability: Journey into DevOps Excellence</h4>
  </div>
</div> */}

<div className="container-fluid serviceDisplay"  style={{marginTop:'15vh'}}>
  <div className='row  ' >
    <div className='col'>
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/Service+page+sliders/Website+Sliders+Service+Page-06.jpg" alt="Nature" class="service-header-imgs-service"/>
    </div>
  </div>
</div>

<div class="container-fluid ">
  <div class="text-whitedata whitedata">
  <h1 class="text-size-head-white serviceDisplay-tag" >Devops
Services</h1>
<h4 class="text-size-head-white-h4 serviceDisplay-tags" >Unlocking Agility and Reliability: Journey into DevOps Excellence</h4>
  </div>
 </div>


<div class="container">
    <div class="row" >
      <div class="insight-matters"> <h1>Insights that matter</h1>
     <p class="governance-data-size">DevOps catalyzes seamless collaboration, task automation, and accelerated innovation, culminating in heightened efficiency across Development and Operations domains. Embracing an automated delivery pipeline eradicates bottlenecks, streamlines processes, and effectively cuts costs through a swift transition to the continuous delivery and continuous integration paradigm.</p>

 <p class="governance-data-size">At SSS Solutions, we champion a holistic approach that fosters full transparency across the product development spectrum. Our strategy distributes responsibility harmoniously across teams while nurturing a shared goal-driven ethos. By harnessing the power of DevOps, we successfully bridge the traditionally wide chasm between development and operations units, resulting in the expeditious release of products to market.</p>
             {/* <div class="bt-start-now">   <a class="btn-start" type="button" value="START NOW" placeholder="START NOW" href="/contact" target=""><p>START NOW</p></a>
            </div> */}
             <div className="text-center">
      <Link
        className="fusion-button button-flat fusion-button-default-size button-custom fusion-button-default button-1 fusion-button-default-span startNowButton custom-button"
        target="_self"
        to="/contact"
      >
        <span className="fusion-button-text">START NOW</span>
        {/* <i className="fa-long-arrow-alt-right fas button-icon-right" aria-hidden="true"></i> */}
      </Link>
    </div>
          </div> </div>
</div>

<div class="container">
<div class="row">
    <div class="governance-data">
        <h1>Empowering Business Excellence through DevOps</h1>
<p class="governance-data-size">The evolution of DevOps has revolutionized the way organizations develop, deploy, and maintain software applications. By fostering collaboration between development and operations teams, DevOps accelerates the software development lifecycle and enhances business outcomes. The synergy between continuous integration, continuous delivery, and automation creates a robust environment that promotes efficiency, reliability, and growth.</p><br/>
        </div>


<div class="col-md-6">
    <div class="challenges">

     <h6 class="challenges-size">DevOps: Driving Excellence and Resilience </h6>
       <br/>
        <ul>
        <li class="li-flex dev-a1" >
        <div><img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/></div>
       <div><p style={{paddingTop:"0px"}}>Leveraging Data Insights for Informed Decision-making.</p></div>
    </li>
    <li class="li-flex dev-a1" >
        <div><img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/></div>
       <div><p style={{paddingTop:"0px"}}>Rapid Prototyping with Diverse Datasets for Innovation.</p></div>
    </li>
    <li class="li-flex dev-a1" >
        <div><img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/></div>
       <div><p style={{paddingTop:"0px"}}>Operational Excellence and Revenue Leakage Prevention.</p></div>
    </li>
    <li class="li-flex dev-a1" >
        <div><img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/></div>
       <div><p style={{paddingTop:"0px"}}>Elevating Customer Experience to Prevent Churn.</p></div>
    </li>
    <li class="li-flex dev-a1" >
        <div><img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/></div>
       <div><p style={{paddingTop:"0px"}}>Safeguarding Against Risks like Fraudulent Activities.</p></div>
    </li>


</ul>
</div></div>
<div class="col-md-6 ">
        <div class="interactive">

                          <h6 class="challenges-size">Unlocking Business Excellence through DevOps Solutions</h6>

        <br/>
            <ul>
            <li class="li-flex dev-b1" >
        <div><img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/></div>
       <div><p style={{paddingTop:"0px"}}>Comprehensive DevOps Capabilities for Seamless Integration, Deployment, and Monitoring.</p></div>
    </li>
    <li class="li-flex dev-b2" >
        <div><img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/></div>
       <div><p style={{paddingTop:"0px"}}>  Ensured Data Trust, Governance, and Quality Assurance.</p></div>
    </li>
    <li class="li-flex dev-b3" >
        <div><img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/></div>
       <div><p style={{paddingTop:"0px"}}>Robust Infrastructure for Complex Data Storage.</p></div>
    </li>
    <li class="li-flex dev-b4" >
        <div><img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/></div>
       <div><p style={{paddingTop:"0px"}}>Minimized Downtime and Uninterrupted Service Delivery.</p></div>
    </li>
    <li class="li-flex dev-b5" >
        <div><img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/></div>
       <div><p style={{paddingTop:"0px"}}>Seamless Collaboration Across DevOps Teams.</p></div>
    </li>

        <br/>
    </ul>

    </div></div>

    <div class="container-fluid">
  <div class="row">
<div class="mg-management">
<h1>DevOps Service Offerings</h1>
</div>
<DevopsServicesCarousel/>
</div></div>

   {/* <div class="container-fluid">


                      <div class="row service-row1">
    <div class="devops">
        <h1>DevOps Service Offerings</h1>
    </div>

    <div class="slide-show">
        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">

                <div class="carousel-item active">
                    <div class="card-group service-card-group">
                        <div class="card bi-card-size">
                            <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/devops_services/Consulting-Strategy.png" class="rpa-img-top" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title text-body-color-black">DevOps Strategy <br/>Consulting</h5>
                                <ul class="card-text">
                                    <li>DevOps roadmap development</li>
                                    <li>Identification of key DevOps areas</li>
                                    <li>Customized DevOps solution design</li>
                                    <li>Alignment of DevOps goals with business objectives</li>
                                    <li>Assessment of existing processes and tools</li>
                                </ul>
                            </div>
                        </div>
                        <div class="card bi-card-size">
                            <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/devops_services/deployment.png" class="rpa-img-top" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title text-body-color-black">Continuous Integration and Deployment</h5>
                                <ul class="card-text">
                                    <li>Implementation of CI/CD pipelines</li>
                                    <li>Automated testing and validation</li>
                                    <li>Efficient code integration and merging</li>
                                    <li>Automated deployment to various environments</li>
                                </ul>
                            </div>
                        </div>
                        <div class="card bi-card-size">
                            <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/devops_services/Automation.png" class="rpa-img-top" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title text-body-color-black">Infrastructure<br/> Automation</h5>
                                <ul class="card-text">
                                    <li>Infrastructure as Code (IaC) implementation</li>
                                    <li>Automated provisioning of resources</li>
                                    <li>Configuration management and version control</li>
                                    <li>Scalable and resilient infrastructure design</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="carousel-item">
                    <div class="card-group service-card-group">
                        <div class="card bi-card-size">
                            <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/devops_services/devops-service.png" class="rpa-img-top" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title text-body-color-black">DevOps as a<br/> Service</h5>
                                <ul class="card-text">
                                    <li>Full-stack DevOps support and management</li>
                                    <li>24/7 monitoring and incident response</li>
                                    <li>Regular performance optimization</li>
                                    <li>Security and compliance enforcement</li>
                                    <li>Continuous improvement and innovation</li>
                                </ul>
                            </div>
                        </div>
                        <div class="card bi-card-size">
                            <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/devops_services/devops-training.png" class="rpa-img-top" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title text-body-color-black">DevOps Training and Workshops</h5>
                                <ul class="card-text">
                                    <li>Customized DevOps training programs</li>
                                    <li>Hands-on workshops and simulations</li>
                                    <li>Toolchain familiarization and best practices</li>
                                    <li>Team collaboration and communication enhancement</li>
                                </ul>
                            </div>
                                              </div>
                                              <div class="card bi-card-size">
                            <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/devops_services/infrastructure.png" class="rpa-img-top" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title text-body-color-black">Infrastructure<br/> Automation</h5>
                                <ul class="card-text">
                                    <li>Infrastructure as Code (IaC) implementation</li>
                                    <li>Automated provisioning of resources</li>
                                    <li>Configuration management and version control</li>
                                    <li>Scalable and resilient infrastructure design</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>
</div>

                  </div>  */}

<div class="container-fluid">
  <div class="row">
<div class="mg-management">
    <h1>DevOps – Programs | Frameworks | Accelerators</h1>
</div>
<DevopsServicesCarousel1/>
</div></div>

{/* <div class="container">
  <div class="row">
<div class="mg-management">
    <h1>DevOps – Programs | Frameworks | Accelerators</h1>
</div>

<div id="myCarousel" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <div class="row" >
        <div class="col-lg-4" >
          <div class="card text ">
            <div class="card-body cloud-card-body-colors1">
              <h5 class="card-title">DevOps <br/>HyperCare <br/>Program </h5>
              <ul class="card-text">
              <li>Provide and manage infrastructure for DevOps solutions.</li>
              <li>Apply continuous integration and continuous deployment to optimize software solutions.</li>
              <li>Promote collaboration between development and operations teams for smoother workflows.</li>
              <li>Ensure consistency and reliability of deployments using versioning.</li>
              <li>Support and Maintenance.</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4" >
          <div class="card text">
            <div class="card-body cloud-card-body-colors1"  >
            <h5 class="card-title">Effective DevOps Implementation framework</h5>
              <ul class="card-text">
              <li>Valuable across various industries, including healthcare, finance, law, and more.</li>
              <li>Particularly valuable in the healthcare, finance, and law industries.</li>
              <li>Involves automation of build, test, deployment, and monitoring processes.</li>
              <li>Ensures reliable and consistent data for testing and deployment.</li>


                </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card text">
            <div class="card-body cloud-card-body-colors1"  >

              <h5 class="card-title">DevOps for Contract / Document Extraction using NLP Framework</h5>
              <ul class="card-text">
              <li>Manual contract handling is lengthy, expensive, and risky</li>
              <li>Contract abstraction extracts crucial terms, clauses, dates, and relevant data</li>
              <li>Incorporates iterative model improvements for better extraction accuracy.</li>
              <li>Seamlessly integrates with existing data processing pipelines.</li>

                </ul>
               </div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-item ">
      <div class="row" >
        <div class="col-lg-4">
          <div class="card text " >
            <div class="card-body cloud-card-body-colors1" >
              <h5 class="card-title"> DevOps Conversational<br/> AI</h5>
              <ul class="card-text">
              <li>DevOps enhances Conversational AI's ability to identify customer business needs.</li>
              <li>Conversational AI powered by DevOps creates intelligent digital assistants.</li>
              <li>DevOps methodologies enable an agile approach to assistant development.</li>

               </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4" >
          <div class="card text  ">
            <div class="card-body cloud-card-body-colors1" >
              <h5 class="card-title">Intelligent<br/> Automation</h5>
              <ul class="card-text">

                     <li>DevOps facilitates intelligent enterprise consulting for automation strategies.</li>
      <li>Leverages Data Science Technologies like R and Python to plan the automation journey.</li>
           <li>DevOps orchestrates the implementation of intelligent bots.</li>
  <li>Integrates automation platforms for seamless bot deployment.</li>



              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4" >
          <div class="card text ">
            <div class="card-body cloud-card-body-colors1">
              <h5 class="card-title">DevOps <br/>Strategy</h5>
      <ul class="card-text">
        <li>Assess development and operations needs/goals</li>
        <li>Define success criteria and key performance indicators (KPIs)</li>
        <li>Implement, deploy, and integrate software applications</li>
        <li>Integrate DevOps practices into business processes</li>
        <li>Optimize application performance and security</li>
        <li>Provide training, support</li>
      </ul>
           </div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-item ">
      <div class="row" >
        <div class="col-lg-4">
          <div class="card text " >
            <div class="card-body cloud-card-body-colors1" >
              <h5 class="card-title">DevOps Principles</h5>
              <ul class="card-text">
                <li>Collaboration between Dev and Ops teams</li>
          <li>Automation of development and operations tasks</li>
          <li>Continuous Integration (CI)</li>
          <li>Continuous Delivery (CD)</li>
          <li>Infrastructure as Code (IaC)</li>
          <li>Monitoring and Feedback loops</li>
          <li>Microservices and Containers</li>
          <li>Security integration</li>
                </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4" >
          <div class="card text  ">
            <div class="card-body cloud-card-body-colors1" >
              <h5 class="card-title">Benefits of DevOps</h5>
              <ul class="card-text">
             <li>Faster and more frequent releases</li>
          <li>Improved collaboration and communication</li>
          <li>Reduced manual intervention</li>
          <li>Increased efficiency</li>
          <li>Fast issue resolution</li>
          <li>Enhanced customer satisfaction</li>
          <li>Continuous improvement</li>

               </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4" >
          <div class="card text ">
            <div class="card-body cloud-card-body-colors1" >
            <h5 class="card-title">DevOps Tools </h5>
              <ul class="card-text">
              <li>Version control systems (e.g., Git)</li>
          <li>CI/CD tools (e.g., Jenkins, Travis CI)</li>
          <li>Configuration management tools (e.g., Ansible, Puppet)</li>
          <li>Containerization platforms (e.g., Docker, Kubernetes)</li>
          <li>Monitoring tools (e.g., Prometheus, Grafana)</li>
              </ul>
           </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
                      </div>
                  </div> */}

<div class="container">
    <div class="row" style={{marginTop:"0px",marginBottom:"30px"}}>
    <div class="bi-service">
    <h1>Our Technology Expertises</h1>
</div>

              <TechnologyList/>
    </div>
</div>





</div></div>


      {/* <ContactUss/> */}
      {/* <ContactForm/> */}
      <Footer/>
      </div>
  )
}

export default DevopsServices;
