// import React from "react";

// const YouTubeVideos = () => {
//   // Correctly formatted YouTube embed URLs
//   const videoUrls = [
//     "https://www.youtube.com/embed/5AjzfND8yfM",
//     "https://www.youtube.com/embed/5vXeC_yCo5o",
//     "https://www.youtube.com/embed/hvIzlquPDNs",
//     "https://www.youtube.com/embed/r1REA0NIsWo",
//   ];

//   return (
//     <div
//       style={{
//         display: "grid",
//         gridTemplateColumns: "repeat(2, 1fr)", // Always two videos per row
//         gap: "20px",
//         maxWidth: "1200px",
//         margin: "0 auto",
//       }}
//     >
//       {videoUrls.map((url, index) => (
//         <div key={index}>
//           <iframe
//             width="100%"
//             height="315"
//             src={url}
//             title={`YouTube video ${index + 1}`}
//             frameBorder="0"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//             allowFullScreen
//           ></iframe>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default YouTubeVideos;

import React from "react";

const YouTubeVideos = () => {
  // Correctly formatted YouTube embed URLs
  const videoUrls = [
    "https://www.youtube.com/embed/hHhfEAKPp1o?si=FqDyoCIubzzsok7L",
    "https://www.youtube.com/embed/r1REA0NIsWo?si=Lb1uBL6fJ9SJXfV6",
    "https://www.youtube.com/embed/hvIzlquPDNs?si=JfKiBf56Krt21yNk",
    "https://www.youtube.com/embed/5AjzfND8yfM?si=7CMzEz-pKJKzfLFc",
  ];

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)", // 2 videos per row
        gap: "20px",
        maxWidth: "1200px",
        margin: "0 auto",
        paddingTop: "10%",
      }}
    >
      {videoUrls.map((url, index) => (
        <div key={index}>
          <iframe
            width="560"
            height="315"
            src={url}
            title={`YouTube video ${index + 1}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      ))}
    </div>
  );
};

export default YouTubeVideos;
