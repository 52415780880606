import React from 'react'
import './Solutions.css';
import Footer from '../components/Footer';

const PowerBiMigration = () => {
  return (
    <div>
        {/* <div class="container-fluid"> */}
  <img src="../images/solutions/powerbi.webp" alt="Nature" class="service-header-img"/>
  <div class="text-whitedata">
  <h1 class="text-size-head-white">SSRS to Power BI<br/>
Migration<br/>
Migration</h1>
<h4 >Seamless Migration, Unleashing Data Insights with Advanced Analytics</h4>
  </div>
{/* </div> */}

<div class="container">
    <div class="row" style={{color:"rgb(125, 136, 155)",marginTop:"40px"}}>
      <div class="insight-matter"> <h1>What we do</h1>
        <p>To keep up with the fast pace of technological advancements in data analytics, it has become important for businesses to upgrade to the right business intelligence tools which can handle increasingly complex and dynamic data sets.</p>
            <p>SSS SSRS to Power BI migration program helps organizations with a smooth transition from SSRS to a more advanced and versatile business intelligence platform like Power BI to gain access to greater functionality, flexibility, and collaboration features.</p>
    </div> </div> 
</div>
{/* <div class="container">
    <div class="row">
        <div class="solutions-head"><h1>Key Component</h1></div>
        <div class="col-lg-4"><p class="key-components-p" >SSRS report</p></div>
        <div class="col-lg-4"><p class="key-components-p" >Migration engine</p></div>
        <div class="col-lg-4"><p class="key-components-p" >Migrated report</p></div>
    </div>
</div> */}
<div class="container-fluid key-benefits-bg-color">
    <div class="row">
        <div class="solutions-head">
            <h1>Key Benefits</h1>
        </div>
        <div class="col-md-2 key-margin row-center">
            <img src="./images/solutions/Advanced-Visualization.png" alt="" class="key-benifits-img"></img>
            <p class="key-benifits-p1">Advanced data visualization and analytics capabilities</p>
        </div>
        <div class="col-md-2 key-margin row-center">
        <img src=" ./images/solutions/Modern-Interface.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p1">Modern and intuitive user interface</p>
        </div>
        <div class="col-md-2 key-margin row-center">
        <img src="./images/solutions/Dynamic-Visualization.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p1">Easy to understand interactive and dynamic visualizations</p>
        </div>
        <div class="col-md-2 key-margin row-center">
        <img src="./images/solutions/Advanced-Modelling.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p1">Advanced data modelling​​</p>
        </div>
        <div class="col-md-2 key-margin row-center">
        <img src="./images/solutions/Exploration-Complex.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p1">Exploration of complex data sets​</p>
        </div>
        <div class="col-md-2 key-margin row-center">
        <img src="./images/solutions/integration-with-products.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p1">Integration with other Product</p>
        </div>
    </div>
</div>
      

       <Footer/>
    </div>
  )
}

export default PowerBiMigration
