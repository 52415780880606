import React from 'react'
import './Solutions.css';
import Footer from '../components/Footer';

const TableauMigration = () => {
  return (
    <div>
        {/* <div class="container-fluid"> */}
  <img src="../images/solutions/tableau-migration.jpeg" alt="Nature" class="service-header-img"/>
  <div class="text-whitedata">
  <h1 class="text-size-head-white">SSRS to Tableau <br/>
Migration</h1>
<h4 >Transform your analytics with seamless migration from SSRS to Tableau</h4>
  </div>
{/* </div> */}

<div class="container">
    <div class="row" style={{color:"rgb(125, 136, 155)",marginTop:"40px"}}>
      <div class="insight-matter"> <h1>What we do</h1>
        <p>To remain relevant in today’s rapidly evolving technological 
            landscape, it has become essential for organizations to migrate from SSRS to Tableau platform.</p>
            <p>SSS SSRS to Tableau migration program helps businesses gain access to advanced data visualization, interactive dashboards,
                 and data analysis capabilities that enable better insights, decision-making, and business outcomes.</p>
    </div> </div> 
</div>
<div class="container">
    <div class="row">
        <div class="solutions-head"><h1>Key Component</h1></div>
        <div class="col-lg-4"><p class="key-components-p" >SSRS report</p></div>
        <div class="col-lg-4"><p class="key-components-p" >Migration engine</p></div>
        <div class="col-lg-4"><p class="key-components-p" >Migrated report</p></div>
    </div>
</div>
<div class="container-fluid key-benefits-bg-color">
    <div class="row">
        <div class="solutions-head">
            <h1>Key Benefits</h1>
        </div>
        <div class="col-md-2 key-margin row-center">
            <img src="./images/solutions/Advanced-Analytics.png" alt="" class="key-benifits-img"></img>
            <p class="key-benifits-p1">Advanced analytics</p>
        </div>
        <div class="col-md-2 key-margin row-center">
        <img src=" ./images/solutions/Report-Sharing.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p1">Report Sharing</p>
        </div>
        <div class="col-md-2 key-margin row-center">
        <img src="./images/solutions/Increased-Customization.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p1">Increased customization</p>
        </div>
        <div class="col-md-2 key-margin row-center">
        <img src="./images/solutions/Improved-Collaboration.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p1">Improved collaboration​​</p>
        </div>
        <div class="col-md-2 key-margin row-center">
        <img src="./images/solutions/Data-Connection.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p1">Data connection​</p>
        </div>
        <div class="col-md-2 key-margin row-center">
        <img src="./images/solutions/Integration-Tools.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p1">Integration with Tools</p>
        </div>
    </div>
</div>
      

      <Footer/>
    </div>
  )
}

export default TableauMigration
