import React from 'react';
import './Flags.css';
import { Link } from 'react-router-dom';

const Flags = () => {
  return (
      <div>
          <section class="sss_flag_wrapper">
      <div class="container">
         {/* <div class="heading">
            <h6 class="display-3 text-center text-uppercase font-weight-bold" style={{opacity:" 0.3;"}}>Our Services Across</h6>
            <h4 class="h1 text-center font-weight-bold mt-n5 text-uppercasre">CONTINENT</h4>
         </div> */}
         <div class="bi-service">
    <h1>Our Services Across<br/>the Globe</h1>
</div>

         <div class="row m-0">
            <div class="col-lg-6 col-md-12 col-sm-12 col-12">
               <div class="left_sss">
                  <div class="flag_icon">
                     <div class="sss_flag">
                        <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/Logos/india-new.svg" alt="india"/>
                     </div>
                     <p class="text-center">INDIA</p>
                  </div>
                  <h5>Srinivasan Software Solutions Pvt Ltd</h5>
                  {/* <!-- <div>
                                <a href="mailto:info@sssindia.com">info@sssindia.com</a>
                            </div>
                            <div class="magi_tb15">
                                <a href="tel:099660 62884">099660 62884</a>
                            </div> --> */}
                  <div class="sss_address">
                     <p> 1st Floor, Near HDFC Bank, Anna Rao Circle, Tirupati, Andhra Pradesh 517501</p>
                  </div>
               </div>
                      </div>
                       <div class="col-lg-6 col-md-12 col-sm-12 col-12">
               <div class="left_sss">
                              <div class="flag_icon">

                     <div class="sss_flag">
                                      <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/Logos/canada+flag.png" alt="india" style={{width:"100%"}} />
                     </div>
                     <p class="text-center">CANADA</p>
                  </div>
                  <h5>srinivasan software solutions Pvt Ltd</h5>
                  {/* <!-- <div>
                                <a href="mailto:info@sssindia.com">info@sssindia.com</a>
                            </div>
                            <div class="magi_tb15">
                                <a href="tel:099660 62884">099660 62884</a>
                            </div> --> */}
                  <div class="sss_address">
                     <p> 238 Fleetwood Dr Oshawa, L1K3E7, Ontario, Canada</p>
                  </div>
               </div>
            </div>

                  </div>
                  <br/>
         {/* <div class="sss_demo_info" style={{textAlign:"center"}}>
            <button class="btn btn-primary book_demo" data-toggle="modal" data-target="#onload_form" href='/contact'>Contact Us</button>
         </div> */}

              {/* <div class="bt-start-now1" style={{textAlign:"center"}}>
              <a class="btn-start1" type="button" value="READ MORE" placeholder="START NOW" href="/contact" target="" ><p>CONTACT US</p></a>
            </div> */}
          <div className="text-center">
      <Link
        className="fusion-button button-flat fusion-button-default-size button-custom fusion-button-default button-1 fusion-button-default-span startNowButton custom-button"
        target="_self"
        to="/contact"
      >
        <span className="fusion-button-text">CONTACT US</span>
        <i className="fa-long-arrow-alt-right fas button-icon-right" aria-hidden="true"></i>
      </Link>
    </div>

      </div>
   </section>


    </div>
  )
}

export default Flags
