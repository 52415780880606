import React from "react";
import "./Footers.css";
import "./Footer.css";

import { useEffect } from "react";
import { Link } from "react-router-dom";

const Footers = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component mounts
  }, []);
  return (
    <div>
      <footer>
        <div class="content">
          <div class="top">
            <div class="logo-details">
              {/* <img src='https://sssbi-images.s3.ap-south-1.amazonaws.com/Logos/SSS+Logo+2023+Bold.gif' style={{width:"270px"}} /> */}
              <img
                src="https://sssbi-images.s3.ap-south-1.amazonaws.com/Logos/rotate_Logo.gif"
                style={{ width: "45%" }}
              />
            </div>
            <div class="media-icons">
              <a href="https://www.facebook.com/srinivasansoftwaresolutionsPvt.Ltd?__hstc=38642556.ecd9f67d91817388a3cf5ae23855f7f1.1461569992317.1464353863423.1464581077900.32&__hssc=38642556.10.1464581077900&__hsfp=781976375">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a href="https://www.twitter.com/jamesqquick">
                <i class="fab fa-twitter"></i>
              </a>
              <a href="https://www.instagram.com/srinivasansoftwaresolutions/">
                <i class="fab fa-instagram"></i>
              </a>
              <a href="https://in.linkedin.com/company/srinivasansoftwaresolutions">
                <i class="fab fa-linkedin-in"></i>
              </a>
              <a href="https://www.youtube.com/watch?v=RlleTJnVSqo">
                <i class="fab fa-youtube"></i>
              </a>
            </div>
          </div>
          <div class="link-boxes">
            <ul class="box">
              <li class="link_name">Company</li>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/contact">Contact us</a>
              </li>
              <li>
                <a href="/about-sss">About us</a>
              </li>
              <li>
                <a href="/">Get started</a>
              </li>
            </ul>
            <ul class="box">
              <li class="link_name">Services</li>
              <li>
                <a href="/data-engineering">Data Engineering Services</a>
              </li>
              <li>
                <a href="/data-analytics">Data Analytics Services</a>
              </li>
              <li>
                <a href="/cloud-data-services">Cloud Data Services</a>
              </li>
              <li>
                <a href="/data-science">Data Science/AI/ML</a>
              </li>
              <li>
                <a href="/app-development">App Development</a>
              </li>
              <li>
                <a href="/process-development">Process Development</a>
              </li>
              <li>
                <a href="/managed-services">Managed Services</a>
              </li>
              <li>
                <a href="/devops-services">DevOps</a>
              </li>
              <li>
                <a href="/cloud-migration">Cloud Migration</a>
              </li>
            </ul>
            {/* <ul class="box">
          <li class="link_name">Account</li>
          <li><a href="/"></a></li>
          <li><a href="/"></a></li>
          <li><a href="/"></a></li>
          <li><a href="/"></a></li>
          <li><a href="/"></a></li>
        </ul> */}
            <ul class="box">
              <li class="link_name">About us</li>
              <li>
                <a href="/about-sss">About SSS</a>
              </li>
              <li>
                <a href="/Time-line">Corporate Timeline</a>
              </li>
              <li>
                <a href="/Leadership">Leadership Team</a>
              </li>
              <li>
                <a href="/gallery">Gallery</a>
              </li>
              <li>
                <a href="/careers">Careers</a>
              </li>
            </ul>

            <ul class="box input-box">
              <li class="link_name">Subscribe</li>
              <li>
                <input type="text" placeholder="Enter your email" />
              </li>
              <li>
                <input type="button" value="Subscribe" />
              </li>
            </ul>
          </div>
        </div>
        <div class="bottom-details">
          <div class="bottom_text">
            <span class="copyright_text">
              Copyright © 2009 <a href="https://sssbi.com/">SSS.</a>All rights
              reserved
            </span>
            <span class="policy_terms">
              <Link to="/terms-of-use">Privacy policy</Link>
              <Link to="/privacy-policy">Terms & condition</Link>
            </span>
          </div>
        </div>

        <Link
          to="https://api.whatsapp.com/send?phone=[7207213437]&amp;text=hello"
          class="whatsappButton"
          id="whatsappButton"
          style={{ display: "inline", opacity: "1.0798" }}
        >
          <i class="fa-brands fa-whatsapp"></i>
        </Link>

        <Link
          to="#"
          class="scrollToTop"
          id="scrollToTop"
          style={{ display: "inline", opacity: "1.12235;" }}
        >
          <i class="fa-solid fa-angle-up"></i>
        </Link>
      </footer>
    </div>
  );
};

export default Footers;
