import React from 'react'
import './Solutions.css';
import Footer from '../components/Footer';

const DataScienceService = () => {
  return (
    <div>
        {/* <div class="container-fluid"> */}
  <img src="../images/solutions/ai-roadmap.jpg" alt="Nature" class="service-header-img"/>
  <div class="text-whitedata">
  <h1 class="text-size-head-white">AI Roadmap<br/>
Service</h1>
<h4 >Actionable Recommendations for Data Management Excellence</h4>
  </div>
{/* </div> */}

<div class="container">
    <div class="row" style={{color:"rgb(125, 136, 155)",marginTop:"40px"}}>
      <div class="insight-matter"> <h1>What we do</h1>
        <p>To succeed in the rapidly evolving AI-ML landscape today, organizations must develop a comprehensive strategy that addresses key components such as data infrastructure, talent development, and governance, and continually refine and adapt it to keep pace with the latest advancements. ​​​​</p>
    <p>SSS Advanced AL-ML strategy utilizes a methodology based on best practices that allows for the evaluation of current state capabilities, determination of the desired end state for various data management enablers, and delivery of a comprehensive set of actionable recommendations.​​​​</p>
    </div> </div> 
</div>
<div class="container-fluid key-benefits-bg-color">
    <div class="row">
        <div class="solutions-head">
            <h1>Key Benefits</h1>
        </div>
        <div class="col-md-2 key-margin1 row-center">
            <img src="./images/solutions/Increase-Efficiency.png" alt="" class="key-benifits-img"></img>
            <p class="key-benifits-p">Maximize Organizational Efficiency</p>
        </div>
        <div class="col-md-2 key-margin1 row-center">
        <img src=" ./images/solutions/Strategy.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Effective Business Strategy</p>
        </div>
        <div class="col-md-2 key-margin1 row-center">
        <img src="./images/solutions/Execution.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Enabling Successful Execution</p>
        </div>
        <div class="col-md-2 key-margin1 row-center">
        <img src="./images/solutions/Robust-business.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Develop Robust Business Case​​</p>
        </div>
        <div class="col-md-2 key-margin1 row-center">
        <img src="./images/solutions/Align-Internal.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Align Internal Capabilities​​</p>
        </div>
        
      
    </div>
</div>
      
   <Footer/>
   
   
    </div>
  )
}

export default DataScienceService
