import { useState } from 'react';
import ReactSimplyCarousel from 'react-simply-carousel';
import './Carousel.css';

function CloudDataCarousel() {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <div>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        forwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '30px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`>`}</span>,
        }}
        backwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '20px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`<`}</span>,
        }}
        responsiveProps={[
          {
            itemsToShow: 3,
            itemsToScroll: 3,
            minWidth: 780,
          },
        ]}
        speed={400}
        easing="linear"
      >

        <div style={{ width: 310, height: 650 }}>
        <div class="card bi-card-size" >
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/managed/BI-Managed-Services-3.png" class="rpa-img-top" alt="..." />

    <div class="card-body">
      <h5 class="card-title text-body-color-black" >BI Managed<br/> Services​​</h5>
      <ul class="card-text">
        <li>BI/DV Platform Upgrade </li>
        <li>Development & Administration support </li>
        <li>DV Tool support ​​​​</li>
        <li>Report Refresh Services for DV Tools (e.g., Tableau, Power BI)​​​</li>
        <li>BI tool Enhancement ​</li>
        <li>Collaboration on creating a BI roadmap to align with your evolving business goals.</li>
       </ul>
        </div>
  </div>
        </div>
        <div style={{ width: 310, height: 650}}>
        <div class="card bi-card-size" >
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/managed/Big-Data-Managed-Services.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black">Big Data Managed <br/> Services​​</h5>
      <ul class="card-text">
      <li>End-to-End Managed Services for Big Data Platforms</li>
      <li>Data Lake Administration Services​</li>
      <li>Administration for Data Lake, Data Marts, and Data Warehouses</li>
      <li>Hadoop Platform Administration​</li>
      <li>Implementation and Upgrades</li>
      {/* <li>Big Data Management and Reporting</li> */}
      {/* <li>Support Services​</li> */}

       </ul>
        </div>
  </div>
        </div>
        <div style={{ width: 310, height: 650}}>
        <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/managed/RPA-Managed-Services-1.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >RPA Managed <br/> Services​​</h5>
      <ul class="card-text" >
    <li> Comprehensive End-to-End Managed Services for RPA Tools</li>
    <li>Professional Administration Services for Tools and Bots</li>
    <li>Optimization and Management of RPA Infrastructure</li>
    <li>Continuous RPA Enhancements for Improved Efficiency</li>
    <li>Vigilant Performance Monitoring Services</li>
       </ul>
    </div>
  </div>
        </div>
        <div style={{ width: 310, height: 650 }}>
        <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/managed/Big-Data-Managed-Services.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
    <h5 class="card-title text-body-color-black" >Big Data Managed <br/> Services​​</h5>
      <ul class="card-text">
      <li>End-to-End Managed services for Big Data platforms​</li>
      <li>Administration services for Data Lake​</li>
      <li>Data Lake, Data Marts, DWH Admin</li>
      <li>Hadoop platform administration​</li>
      <li>Implementation/Upgrad</li>
      <li>Big Data Management & Reporting</li>
      <li>Support​</li>

       </ul>
        </div>
  </div>
        </div>
        <div style={{ width: 310, height: 650}}>
        <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/managed/RPA-Managed-Services-1.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black">RPA Managed <br/> Services​​</h5>
      <ul class="card-text">
      <li>End-to-End Managed services for RPA tools​​</li>
      <li>Administration services for tools/bots​</li>
      <li>Optimize & manage RPA infrastructure​​</li>
      <li>RPA enhancements for continuous improvement</li>
      <li>Performance Monitoring​​​</li>
       </ul>
    </div>
  </div>

        </div>
        <div style={{ width: 310, height: 650 }}>
        <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/managed/Data-Science-Managed-Services+(1).png" class="rpa-img-top" alt="..."/>
    <div class="card-body">
    <h5 class="card-title text-body-color-black" >Data Science Managed Services​​</h5>
      <ul class="card-text" >
      <li>End-to-End Managed Services for Data Science & Analytics</li>
      <li>Leverage Predictive Analytics for Real-Time Insights</li>
      <li>Enhance Customer Experience</li>
      <li>Optimize Data Infrastructure</li>
      <li>Customized Analytics Solutions</li>
      <li>Continuous Monitoring and Improvement</li>

       </ul>
    </div>
  </div>
        </div>

      </ReactSimplyCarousel>
    </div>
  );
}

export default CloudDataCarousel;
