// import React, {Component} from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// // import "./App.css";

// class Clients extends Component{
//   render(){
//     const settings = {
//       dots: true,
//       infinite: true,
//       slidesToShow: 3,
//       slidesToScroll: 1,
//       autoplay: true,
//       speed: 2000,
//       autoplaySpeed: 2000,
//       cssEase: "linear"
//     };
//     return (
      
//       <div className="mainContainer">
//         {/* <h2>Auto Play</h2> */}
           

//          <div class="container">
//     <div className="row" style={{marginTop:"0px",paddingBottom:"50px",justifyContent:"flex-start",backgroundColor:""}}>
//     <div className="bi-service">
//     <h1>Our Clients</h1>
// </div>
//         <h5 style={{ lineHeight: "40px" }}>
//           {/* <h5 style={{fontFamily:"Poppins",lineHeight:"40px"}}> */}
//         Our 100+ experienced digital engineering, data and insights, and cloud
//         engineers are helping 100+ global industry leaders deliver excellence
//         for their clients.</h5>
//         <Slider {...settings}>
//           <div className="container">
//             <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-01.png" style={{width:"70%"}} />
//           </div>
//           <div className="container">
//             <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-02.png" style={{width:"70%"}}  />
//           </div>
//           <div className="container">
//             <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-03.png" style={{width:"70%"}}  />
//           </div>
//           <div className="container">
//             <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-04.png" style={{width:"70%"}}  />
//           </div>
//           <div className="container">
//             <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-05.png"  style={{width:"70%"}} />
//           </div>
//           <div className="container">
//             <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-06.png"  style={{width:"70%"}}/>
//               </div>
//               <div className="container">
//             <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-07.png"  style={{width:"70%"}}/>
//           </div>
//               <div className="container">
//             <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-08.png"  style={{width:"70%"}}/>
//               </div>
//               <div className="container">
//             <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/econnect+(1).png"  style={{width:"70%"}}/>
//               </div>
//               <div className="container">
//             <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-10.png"  style={{width:"70%"}}/>
//               </div>
//               <div className="container">
//             <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-11.png"  style={{width:"70%"}}/>
//               </div>
              
//         </Slider>
//           </div>
//         </div>
//         </div>
//     );
//   }
// }

// export default Clients;




// import React, { Component } from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "./Clients.css"; // Import your custom CSS file for Clients styling

// class Clients extends Component {
//   render() {
//     const settings = {
//       dots: true,
//       infinite: true,
//       slidesToShow: 3,
//       slidesToScroll: 1,
//       autoplay: true,
//       speed: 2000,
//       autoplaySpeed: 2000,
//       cssEase: "linear",
//     };

//     return (
//       <div className="mainContainer">
//         <div className="containers" style={{paddingLeft:"5%",paddingRight:"5%"}}>
//           <div
//             className="row"
//             style={{
//               marginTop: "0px",
//               paddingBottom: "50px",
//               justifyContent: "flex-start",
//               backgroundColor: "",
//             }}
//           >
//             <div className="bi-service">
//               <h1>Our Clients</h1>
//             </div>
//             <h5 style={{ lineHeight: "40px" }}>
//               Our 100+ experienced digital engineering, data and insights, and
//               cloud engineers are helping 100+ global industry leaders deliver
//               excellence for their clients.
//             </h5>
//             <Slider {...settings}>
//             <div className="containers">
//             <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-01.png" style={{width:"50%"}} />
//           </div>
//           <div className="containers">
//             <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-02.png" style={{width:"50%"}}  />
//           </div>
//           <div className="containers">
//             <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-03.png" style={{width:"50%"}}  />
//           </div>
//           <div className="containers">
//             <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-04.png" style={{width:"50%"}}  />
//           </div>
//           <div className="containers">
//             <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-05.png"  style={{width:"50%"}} />
//           </div>
//           <div className="containers">
//             <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-06.png"  style={{width:"50%"}}/>
//               </div>
//               <div className="containers">
//             <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-07.png"  style={{width:"50%"}}/>
//           </div>
//               <div className="containers">
//             <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-08.png"  style={{width:"50%"}}/>
//               </div>
//               <div className="containers">
//             <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/econnect+(1).png"  style={{width:"50%"}}/>
//               </div>
//               <div className="containers">
//             <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-10.png"  style={{width:"50%"}}/>
//               </div>
//               <div className="containers">
//             <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-11.png"  style={{width:"50%"}}/>
//               </div>
              
//             </Slider>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default Clients;




import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Clients.css"; // Import your custom CSS file for Clients styling
import { Link } from 'react-router-dom';
class Clients extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className="mainContainer">
        <div className="containers" style={{ paddingLeft: "5%", paddingRight: "5%" }}>
          <div
            className="row"
            style={{
              marginTop: "0px",
              paddingBottom: "50px",
              justifyContent: "flex-start",
              backgroundColor: "",
            }}
          >
            <div className="bi-service">
              <h1>Our Clients</h1>
            </div>
            <h5 style={{ lineHeight: "40px" }}>
              Our 100+ experienced digital engineering, data and insights, and
              cloud engineers are helping 100+ global industry leaders deliver
              excellence for their clients.
            </h5>
            <Slider {...settings}>
              <div className="containers">
              <Link to="https://www.techmahindra.com/en-in/?f=3275019639" target="_blank" rel="noopener noreferrer">
                <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-01.png" style={{ width: "70%" }} />
                </Link>
              </div>
              <div className="containers">
              <Link to="https://visionaize.com/" target="_blank" rel="noopener noreferrer">
                <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-02.png" style={{ width: "70%" }} /></Link>
              </div>
              <div className="containers">
              <Link to="https://valetwireless.ca/" target="_blank" rel="noopener noreferrer">
                <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-03.png" style={{ width: "70%" }} /></Link>
              </div>
              <div className="containers">
              <Link to="https://www.usp.org/" target="_blank" rel="noopener noreferrer">
                <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-04.png" style={{ width: "70%" }} /></Link>
              </div>
              <div className="containers">
              <Link to="https://www.tudaap.in/" target="_blank" rel="noopener noreferrer">
                <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-05.png" style={{ width: "70%" }} /></Link>
              </div>
              <div className="containers">
              <Link to="https://kaptura.co/" target="_blank" rel="noopener noreferrer">
                <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-06.png" style={{ width: "70%" }} /></Link>
              </div>
              <div className="containers">
              <Link to="https://prakat.com/" target="_blank" rel="noopener noreferrer">
                <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-07.png" style={{ width: "70%" }} /></Link>
              </div>
              <div className="containers">
              <Link to="https://www.axiscades.com/" target="_blank" rel="noopener noreferrer">
                <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-08.png" style={{ width: "70%" }} /></Link>
              </div>
              <div className="containers">
              <Link to="/" target="_blank" rel="noopener noreferrer">
                <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/econnect+(1).png" style={{ width: "70%" }} /></Link>
              </div>
              <div className="containers">
              <Link to="https://www.justperform.com/" target="_blank" rel="noopener noreferrer">
                <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-10.png" style={{ width: "70%" }} /></Link>
              </div>
              <div className="containers">
              <Link to="https://www.hdfcbank.com/" target="_blank" rel="noopener noreferrer">
                <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/clients_sss/New+SSS+clients+logos+edited-11.png" style={{ width: "70%" }} /></Link>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}

export default Clients;
