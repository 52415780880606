import React from 'react'
import './ServicesStyle.css';
import Footer from '../components/Footer';
import TechnologyList from '../components/TechnologyList';
import ManangedServicesCarousel from './ManangedServicesCarousel';
import ManangedServicesCarousel1 from './ManangedServicesCarousel1';
import { Link } from 'react-router-dom';
const ManangedServices = () => {
  return (
    <div>
       {/* <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/managed/managed-services.jpg" alt="Nature" class="service-header-img"/>
<div class="container-fluid">
  <div class="text-whitedata">
  <h1 class="text-size-head-white" style={{fontSize:"44px"}}>Managed<br/>
Services</h1>
<h4 class="text-size-head-white-h4" >Unlocking Your Business Potential Through Managed Excellence</h4>
  </div>
</div> */}

<div className="container-fluid serviceDisplay"  style={{marginTop:'15vh'}}>
  <div className='row  ' >
    <div className='col'>
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/Service+page+sliders/Website+Sliders+Service+Page-08.jpg" alt="Nature" class="service-header-imgs-service"/>
    </div>
  </div>
</div>

<div class="container-fluid ">
  <div class="text-whitedata whitedata">
  <h1 class="text-size-head-white serviceDisplay-tag" >Managed
Services</h1>
<h4 class="text-size-head-white-h4 serviceDisplay-tags" >Unlocking Your Business Potential Through Managed Excellence</h4>
  </div>
 </div>


<div class="container">
    <div class="row" >
      <div class="insight-matters"> <h1>Insights that matter</h1>
     <p class="governance-data-size">In the face of relentless data growth, organizations are increasingly turning to
       outsourced, dependable data service teams for maintenance, support, and performance optimization.</p>
 <p class="governance-data-size"> SSS empowers your organization's core capacity for change and growth by establishing best
   practices and creating reusable methodologies tailored to your needs.</p>
             {/* <div class="bt-start-now">   <a class="btn-start" type="button" value="START NOW" placeholder="START NOW" href="/contact" target=""><p>START NOW</p></a>
            </div> */}
             <div className="text-center">
      <Link
        className="fusion-button button-flat fusion-button-default-size button-custom fusion-button-default button-1 fusion-button-default-span startNowButton custom-button"
        target="_self"
        to="/contact"
      >
        <span className="fusion-button-text">START NOW</span>
        {/* <i className="fa-long-arrow-alt-right fas button-icon-right" aria-hidden="true"></i> */}
      </Link>
    </div>
          </div> </div>
</div>

<div class="container">
<div class="row">
    <div class="governance-data">
        <h1>Need for Managed Services​</h1>

    <p class="governance-data-size">
    In today's dynamic business landscape, organizations must adapt to evolving needs and transformations.
     The decision between outsourcing Data Analytics services and building an in-house team has always posed
      a significant challenge. A well-executed Managed Service can provide your organization with a proficient
       IT department and the expertise of seasoned professionals, ensuring seamless support for business
       operations and users. </p><br/>
        </div>


<div class="col-md-6">
    <div class="challenges">
        <h6 class="challenges-size">Challenges Organizations Encounter with In-House IT</h6>
       <br/>
        <ul>
        <li class="li-flex man-a1" >
        <div><img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/></div>
       <div><p style={{paddingTop:"0px"}}>Embracing the pace of innovation.</p></div>
    </li>
    <li class="li-flex man-a2" >
        <div><img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/></div>
       <div><p style={{paddingTop:"0px"}}>Transitioning from reactive to proactive.</p></div>
    </li>
    <li class="li-flex man-a3" >
        <div><img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/></div>
       <div><p style={{paddingTop:"0px"}}>Struggling to meet expanding demands.</p></div>
    </li>
    <li class="li-flex man-a4" >
        <div><img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/></div>
       <div><p style={{paddingTop:"0px"}}> Service Addressing cost concerns.</p></div>
    </li>
    <li class="li-flex man-a5" >
        <div><img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/></div>
       <div><p style={{paddingTop:"0px"}}>Overcoming availability and scalability issues.</p></div>
    </li>
    <li class="li-flex man-a6" >
        <div><img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/></div>
       <div><p style={{paddingTop:"0px"}}>Shifting focus to core business challenges.</p></div>
    </li>

</ul>
</div></div>
<div class="col-md-6 ">
    <div class="interactive">
        <h6 class="challenges-size">Partnering with us as your trusted Managed Service Provider </h6>
       <br/>
        <ul>
        <li class="li-flex man-b1" >
        <div><img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/></div>
       <div><p style={{paddingTop:"0px"}}>A Scalable and Skilled IT Services Team.</p></div>
    </li>
    <li class="li-flex man-b2" >
        <div><img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/></div>
       <div><p style={{paddingTop:"0px"}}>Cost Savings from Eliminating Hiring Expenses.</p></div>
    </li>
    <li class="li-flex man-b3" >
        <div><img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/></div>
       <div><p style={{paddingTop:"0px"}}>Cost Savings from Eliminating Training Expenses.</p></div>
    </li>
    <li class="li-flex man-b4" >
        <div><img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/></div>
       <div><p style={{paddingTop:"0px"}}>Support Across Multiple Time Zones.</p></div>
    </li>
    <li class="li-flex man-b5" >
        <div><img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/></div>
       <div><p style={{paddingTop:"0px"}}>Robust Backup and Disaster Recovery Solutions.</p></div>
    </li>
    <li class="li-flex man-b6" >
        <div><img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img"/></div>
       <div><p style={{paddingTop:"0px"}}>A Reliable Single Point of Contact.</p></div>
    </li>


    <br/>

    <br/>
</ul>

  </div></div>

  <div class="container-fluid">
  <div class="row service-row1" >
<div class="bigdata">
    <h1>Managed Services – Offerings​</h1>
</div>
<ManangedServicesCarousel/>
</div></div>

   {/* <div class="container-fluid">
  <div class="row service-row1" >
<div class="bigdata">
    <h1>Managed Services – Offerings​</h1>
</div>

<div class="slide-show">
<div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">

    <div class="carousel-item active">
      <div class="card-group rpa-service-card-size" >
      <div class="card bi-card-size" >
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/managed/BI-Managed-Services-3.png" class="rpa-img-top" alt="..." />

    <div class="card-body">
      <h5 class="card-title text-body-color-black" >BI Managed<br/> Services​​</h5>
      <ul class="card-text">
        <li>BI/DV Platform Upgrade </li>
        <li>Development & Administration support </li>
        <li>DV Tool support ​​​​</li>
        <li>Report Refresh Services for DV Tools (e.g., Tableau, Power BI)​​​</li>
        <li>BI tool Enhancement ​</li>
        <li>Collaboration on creating a BI roadmap to align with your evolving business goals.</li>
       </ul>
        </div>
  </div>
  <div class="card bi-card-size" >
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/managed/Big-Data-Managed-Services.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black">Big Data Managed <br/> Services​​</h5>
      <ul class="card-text">
      <li>End-to-End Managed Services for Big Data Platforms</li>
      <li>Data Lake Administration Services​</li>
      <li>Administration for Data Lake, Data Marts, and Data Warehouses</li>
      <li>Hadoop Platform Administration​</li>
      <li>Implementation and Upgrades</li>


       </ul>
        </div>
  </div>
  <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/managed/RPA-Managed-Services-1.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >RPA Managed <br/> Services​​</h5>
      <ul class="card-text" >
    <li> Comprehensive End-to-End Managed Services for RPA Tools</li>
    <li>Professional Administration Services for Tools and Bots</li>
    <li>Optimization and Management of RPA Infrastructure</li>
    <li>Continuous RPA Enhancements for Improved Efficiency</li>
    <li>Vigilant Performance Monitoring Services</li>
       </ul>
    </div>
  </div>


      </div>
    </div>


    <div class="carousel-item">
      <div class="card-group rpa-service-card-group">
      <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/managed/Big-Data-Managed-Services.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
    <h5 class="card-title text-body-color-black" >Big Data Managed <br/> Services​​</h5>
      <ul class="card-text">
      <li>End-to-End Managed services for Big Data platforms​</li>
      <li>Administration services for Data Lake​</li>
      <li>Data Lake, Data Marts, DWH Admin</li>
      <li>Hadoop platform administration​</li>
      <li>Implementation/Upgrad</li>
      <li>Big Data Management & Reporting</li>
      <li>Support​</li>

       </ul>
        </div>
  </div>
  <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/managed/RPA-Managed-Services-1.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black">RPA Managed <br/> Services​​</h5>
      <ul class="card-text">
      <li>End-to-End Managed services for RPA tools​​</li>
      <li>Administration services for tools/bots​</li>
      <li>Optimize & manage RPA infrastructure​​</li>
      <li>RPA enhancements for continuous improvement</li>
      <li>Performance Monitoring​​​</li>
       </ul>
    </div>
  </div>

  <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/managed/Data-Science-Managed-Services+(1).png" class="rpa-img-top" alt="..."/>
    <div class="card-body">
    <h5 class="card-title text-body-color-black" >Data Science Managed Services​​</h5>
      <ul class="card-text" >
      <li>End-to-End Managed Services for Data Science & Analytics</li>
      <li>Leverage Predictive Analytics for Real-Time Insights</li>
      <li>Enhance Customer Experience</li>
      <li>Optimize Data Infrastructure</li>
      <li>Customized Analytics Solutions</li>
      <li>Continuous Monitoring and Improvement</li>

       </ul>
    </div>
  </div>

 </div>
    </div>
</div>
  <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div></div>
</div></div>  */}

<div class="container-fluid">
  <div class="row">
<div class="mg-management">
    <h1>Managed Services Program</h1>
    <ManangedServicesCarousel1/>
</div>
</div></div>

{/* <div class="container">
  <div class="row">
<div class="mg-management">
    <h1>Managed Services Program</h1>
</div>

<div id="myCarousel" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <div class="row" >
        <div class="col-lg-4" >
          <div class="card text ">
            <div class="card-body cloud-card-body-colors1" >
              <h5 class="card-title">Advisory​ Managed Services​ </h5>
              <ul class="card-text">
              <li>Define Scope​​</li>
              <li>Define Training Needs</li>
              <li>Define Project Charter, SLA, RACI Metrics​​</li>
              <li>Risk Assessment and Management​​</li>
              <li>Resource Management​</li>
              <li>Quality Assurance and Control</li>
              <li>Stakeholder Engagement</li>
              <li>Communication Plan</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4" >
          <div class="card text">
            <div class="card-body cloud-card-body-colors1"  >
            <h5 class="card-title">Maintain​ Managed Services​</h5>
              <ul class="card-text">
                <li>Continuous Improvement Initiatives</li>
              <li>Governance (Weekly Meetings, Monthly Service Delivery Review Meetings)​</li>
              <li>Documentation and Knowledge Sharing​</li>
              <li>On-Call Support​</li>
              <li>Incident Management​​</li>
              <li>Change Management​</li>
              <li>Measure all metrics for Productivity, Quality, Service Level​s​</li>
                </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card text">
            <div class="card-body cloud-card-body-colors1"  >

              <h5 class="card-title">Enhance Managed Services</h5>
              <ul class="card-text">
              <li>Continuous Improvement with PCDA (Plan-Do-Check-Act) Methodology​</li>
              <li>Problem Management and Root Cause Analysis​</li>
              <li>Automation and Streamlining​​</li>
              <li>Update Run Book and SOP Documentation</li>
              <li>Performance Benchmarking</li>
              <li>Customer-Centric Approach</li>
                </ul>
               </div>
          </div>
        </div>
      </div>
    </div>



    <div class="carousel-item ">
      <div class="row" >
        <div class="col-lg-4">
          <div class="card text " >
            <div class="card-body cloud-card-body-colors1">
            <h5 class="card-title">Maintain Managed Services​​</h5>
              <ul class="card-text">
                <li>Continuous Improvement Initiatives</li>
              <li>Governance (Weekly Meetings, Monthly Service Delivery Review Meetings)​</li>
              <li>Documentation and Knowledge Sharing​</li>
              <li>On-Call Support​</li>
              <li>Incident Management​​</li>
              <li>Change Management​</li>
              <li>Measure all metrics for Productivity, Quality, Service Level​s​</li>
                </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card text">
            <div class="card-body cloud-card-body-colors1"  >
            <h5 class="card-title">Enhance Managed Services</h5>
              <ul class="card-text">
              <li>Continuous Improvement with PCDA (Plan-Do-Check-Act) Methodology​</li>
              <li>Problem Management and Root Cause Analysis​</li>
              <li>Automation and Streamlining​​</li>
              <li>Update Run Book and SOP Documentation</li>
              <li>Performance Benchmarking</li>
              <li>Customer-Centric Approach</li>
                </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4" >
          <div class="card text ">
            <div class="card-body cloud-card-body-colors1">
            <h5 class="card-title">Managed Services Competency Center​​</h5>
              <ul class="card-text">
             <li>Technical Setup & System Access​</li>
             <li>Engage Subject Matter Experts (SMEs) for Infrastructure, Security & Compliance​​</li>
             <li>Standardized Processes & Frameworks</li>
             <li>Vendor and Third-Party Service Provider Management</li>
             <li>Monitor Service Level Agreements (SLAs)​</li>
             <li>Documentation & Training​</li>

              </ul>
           </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
</div></div> */}

<div class="container">
    <div class="row" style={{marginTop:"0px",marginBottom:"30px"}}>
    <div class="bi-service">
    <h1>Our Technology Expertises</h1>
</div>


              <TechnologyList/>
    </div>
</div>




</div></div>





      <Footer/>
      </div>
  )
}

export default ManangedServices
