import { useState } from 'react';
import ReactSimplyCarousel from 'react-simply-carousel';
import './Carousel.css';

function DevopsServicesCarousel1() {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <div>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        forwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '30px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`>`}</span>,
        }}
        backwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '20px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`<`}</span>,
        }}
        responsiveProps={[
          {
            itemsToShow: 3,
            itemsToScroll: 3,
            minWidth: 768,
          },
        ]}
        speed={400}
        easing="linear"
      >
        
        <div style={{ width: 300, height: 560}}>
        <div class="card-body cloud-card-body-colors1">
              <h5 class="card-title">DevOps <br/>HyperCare <br/>Program </h5>
              <ul class="card-text1">
              <li>Provide and manage infrastructure for DevOps solutions.</li>
              <li>Apply continuous integration and  deployment to optimize software solutions.</li>
              <li>Promote collaboration between development and operations teams for smoother workflows.</li>
              {/* <li>Ensure consistency and reliability of deployments using versioning.</li> */}
              <li>Support and Maintenance.</li>
              </ul>
            </div>
        </div>
        <div style={{ width: 300, height: 560}}>
        <div class="card-body cloud-card-body-colors1"  >
            <h5 class="card-title">Effective DevOps Implementation framework</h5>
              <ul class="card-text1">
              <li>Valuable across various industries, including healthcare, finance, law.</li>
              <li>Particularly valuable in the healthcare, finance, and law industries.</li>
              <li>Involves automation of build, test, deployment, and monitoring processes.</li>
              <li>Ensures reliable and consistent data for testing and deployment.</li>
                          
                                                       
                </ul>
            </div>
        </div>
        <div style={{ width: 300, height: 560 }}>
        <div class="card-body cloud-card-body-colors1"  >
             
             <h5 class="card-title">DevOps for Contract / Document Extraction using NLP Framework</h5>
             <ul class="card-text1">
             <li>Manual contract handling is lengthy, expensive, and <br/>risky</li>
             <li>Contract abstraction extracts crucial terms, clauses, dates, and relevant data</li>
             <li>Incorporates iterative model improvements for better extraction accuracy.</li>
             <li>Seamlessly integrates with existing data processing pipelines.</li>

               </ul>
              </div>
        </div>
        <div style={{ width: 300, height: 560}}>
        <div class="card-body cloud-card-body-colors1" >
              <h5 class="card-title"> DevOps Conversational AI</h5>
              <ul class="card-text1">
              <li>DevOps enhances Conversational AI's  to identify customer business needs.</li>
              <li>Conversational AI powered by DevOps creates intelligent digital assistants.</li>
              <li>DevOps methodologies enable an agile approach to assistant development.</li>
             
               </ul>
            </div>
        </div>
        <div style={{ width: 300, height: 560 }}>
        <div class="card-body cloud-card-body-colors1" >
              <h5 class="card-title">Intelligent<br/> Automation</h5>
              <ul class="card-text1">
              
                     <li>DevOps facilitates intelligent enterprise consulting for automation strategies.</li>
      <li>Leverages Data Science Technologies like R and Python to plan the automation journey.</li>  
           <li>DevOps orchestrates the implementation of intelligent bots.</li>
  <li>Integrates automation platforms for seamless bot deployment.</li>                                                
                                                          
                                                          
              
              </ul>
            </div>
        </div>
        <div style={{ width: 300, height: 560 }}>
        <div class="card-body cloud-card-body-colors1">
              <h5 class="card-title">DevOps <br/>Strategy</h5>
      <ul class="card-text1">
        <li>Assess development and operations needs/goals</li>
        <li>Define success criteria and key performance indicators (KPIs)</li>
        <li>Implement, deploy, and integrate software applications</li>
        <li>Integrate DevOps practices into business processes</li>
        <li>Optimize application performance and security</li>
        <li>Provide training, support</li>
      </ul>
           </div>
        </div>
    
      </ReactSimplyCarousel>
    </div>
  );
}

export default DevopsServicesCarousel1;