// import React from 'react';
// import './Testimonials.css';
// // import './Test.css';


// const Testimonials = () => {
//   return (
//       <div className='testimonials2'>


//     <div className="container-xl">
// 	<div className="row">
//                   <div className="col-lg-8 mx-auto">
//                       <div className='bi-service'>
//                           <h1 className='test'>Testimonials</h1>
//                       </div>

// 			<div id="myCarousel" className="carousel slide" data-ride="carousel">

// 				<ol className="carousel-indicators">
// 					<li data-target="#myCarousel" data-slide-to="0" className="active"></li>
// 					<li data-target="#myCarousel" data-slide-to="1"></li>
// 					<li data-target="#myCarousel" data-slide-to="2"></li>
// 				</ol>

// 				<div className="carousel-inner" style={{padding:"5%"}}>
// 					<div className="carousel-item active">
// 						<div className="img-box"><img src="./images/michael.jpg" alt=""/></div>
// 						<p className="testimonial">We trust SSS to deliver Technical Solutions that meet our Stringent business needs & always in a timely & Cost efficiency manner. SSS has shown a great commitment to customer satisfaction and it is a pleasure to work with them.</p>
// 						<p className="overview"><b>Gerry Mitchell</b></p>
// 							  </div>
// 							  <div className="carousel-item">
// 						<div className="img-box"><img src="./images/Kranthi.png" alt=""/></div>
// 								  <p className="testimonial" style={{fontSize:"18px"}}>
// 									  <p style={{textAlign:"justify"}}><strong style={{fontSize:"20px",color:"#FC766A"}}>RED APRICOT</strong> had the pleasure of partnering with SSS on multiple engagements for development and enhancement of <strong>JUSTPERFORM</strong> product, and the experience exceeded all expectations in performance, Innovation, Quick Turnaround in Solution Provisioning.</p><p>
// SSS commitment is unparalleled, evident in the seamless and innovative solutions they brought to our project.  SSS client not only met our technical requirements with finesse but also demonstrated a keen understanding of our business goals, Ability to deliver beyond expectations make them an invaluable partner.</p>

// <p><strong style={{fontSize:"20px",color:" #FC766A"}}>RED APRICOT</strong> partnership left a lasting positive impression.</p>

// <p>I recommend SSS collaboration for steer into solutions during Challenging situations.</p></p>
// 								  <p className="overview"><b>Kranthi Kumar</b></p>
// 								  <p className="overview"><b>Founder & Chief Innovation Officer</b></p>
// 							  </div>
// 							  {/* <div className="carousel-item ">
// 						<div class="img-box"><img src="./images/michael.jpg" alt=""/></div>
// 						<p class="testimonial">We trust SSS to deliver Technical Solutions that meet our Stringent business needs & always in a timely & Cost efficiency manner. SSS has shown a great commitment to customer satisfaction and it is a pleasure to work with them.</p>
// 						<p class="overview"><b>Gerry Mitchell</b></p>
// 					</div> */}

// 				</div>

// 				{/* <a class="carousel-control-prev" href="#myCarousel" data-slide="prev">
// 					<i class="fa fa-angle-left"></i>
// 				</a>
// 				<a class="carousel-control-next" href="#myCarousel" data-slide="next">
// 					<i class="fa fa-angle-right"></i>
// 				</a> */}
//               <a className="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
//     <i className="fa fa-angle-left"></i>
// </a>
// <a className="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
//     <i className="fa fa-angle-right"></i>
// </a>

// 			</div>
// 		</div>
// 	</div>
// </div>



//     </div>
//   )

// }

// export default Testimonials



// // 	  <div classNameName='container-fluid'>
// // 	  <section className="testimonial text-center" style={{marginBottom:"10px"}}>
// //         <div className="container">

// //           <div className='bi-service'>
// //                            <h1 className='test'>Testimonials</h1>
// //                        </div>
// // 				  <div id="testimonial4" className="carousel slide testimonial4_indicators testimonial4_control_button thumb_scroll_x swipe_x" data-ride="carousel" data-pause="hover" data-interval="5000" data-duration="2000"

// // 				  >

// //             <div className="carousel-inner" role="listbox">
// //               <div className="carousel-item active">
// //                 <div className="testimonial4_slide" style={{paddingBottom:"10%"}}>
// //                   <img src="./images/michael.jpg" className="img-circle img-responsive" alt="Client 1" />
// //                   <p>We trust SSS to deliver Technical Solutions that meet our Stringent business needs & always in a timely & Cost efficiency manner. SSS has shown a great commitment to customer satisfaction and it is a pleasure to work with them. </p>
// // 							  {/* <h4></h4> */}
// // 							  	<p class="overview"><b>Gerry Mitchell</b></p>
// //                 </div>
// //               </div>
// //               <div className="carousel-item">
// //                 <div className="testimonial4_slide">
// //                   <img src="./images/Kranthi.png" className="img-circle img-responsive" alt="Client 2" />
// //                  {/* <p style={{lineHeight:"24px",textAlign:"justify",wordSpacing:"-1px"}}>
// // 									  <p style={{textAlign:"justify",wordSpacing:"-2px"}}><strong style={{fontSize:"20px",color:"#FC766A"}}>RED APRICOT</strong> had the pleasure of partnering with SSS on multiple engagements for development and enhancement of <strong>JUSTPERFORM</strong> product, and the experience exceeded all expectations in performance, Innovation, Quick Turnaround in Solution Provisioning.</p><p>
// // SSS commitment is unparalleled, evident in the seamless and innovative solutions they brought to our project.  SSS client not only met our technical requirements with finesse but also demonstrated a keen understanding of our business goals, Ability to deliver beyond expectations make them an invaluable partner.</p>

// // <p style={{marginBottom:"20px"}}><strong style={{fontSize:"20px",color:" #FC766A"}}>RED APRICOT</strong> partnership left a lasting positive impression.</p>

// //                     <p>I recommend SSS collaboration for steer into solutions during Challenging situations.</p>
// //                     </p>
// // 								  <p class="overview"><b>Kranthi Kumar</b></p>
// //                     <p class="overview"><b>Founder & Chief Innovation Officer</b></p> */}
// //                   <div  style={{ lineHeight: "28px",  color:"white",textAlign:"justify",justifyContent:"flex-start" }}>
// //     <div   class="justify font" style={{fontSize:"20px",marginBottom:"5px"}}>
// //         <strong style={{fontSize: "20px", color: "#FC766A"}}>RED APRICOT</strong> had the pleasure of partnering with SSS on multiple engagements for development and enhancement of <strong>JUSTPERFORM</strong> product, and the experience exceeded all expectations in performance, innovation, quick turnaround in solution provisioning.
// //     </div>
// //     <div  class="justify font" style={{fontSize:"20px",marginBottom:"5px"}}>
// //         SSS commitment is unparalleled, evident in the seamless and innovative solutions they brought to our project. SSS client not only met our technical requirements with finesse but also demonstrated a keen understanding of our business goals. The ability to deliver beyond expectations makes them an invaluable partner.
// //     </div>
// //     <div   class="justify font" style={{marginBottom: "5px",color:"white",fontSize:"20px "}}>
// //         <strong style={{fontSize: "20px", color: "#FC766A"}}>RED APRICOT</strong> partnership left a lasting positive impression.
// //     </div>
// //     <div   class="justify font" style={{fontSize:"20px"}}>
// //         I recommend SSS collaboration for steering into solutions during challenging situations.
// //     </div>
// // </div>
// // <p class="overview"><b>Kranthi Kumar</b></p>
// // <p class="overview"><b>Founder & Chief Innovation Officer</b></p>


// //                   {/* <h4>Client 2</h4> */}
// //                 </div>
// //               </div>

// //             </div>
// //             <a className="carousel-control-prev"  data-slide="prev" style={{zIndex:"0"}}>
// //               <span className="carousel-control-prev-icon"></span>
// //             </a>
// //             <a className="carousel-control-next"  data-slide="next" style={{zIndex:"0"}}>
// //               <span className="carousel-control-next-icon"></span>
// //             </a>
// //           </div>
// //         </div>
// //       </section>
// // 	  </div>





// import React from 'react';
// import './Testimonials.css';

// const Testimonials = () => {
//   return (
//     <div className='testimonials2'>
//       <div className="container-xl">
//         <div className="row">
//           <div className="col-lg-8 mx-auto">
//             <div className='bi-service'>
//               <h1 className='test'>Testimonials</h1>
//             </div>

//             <div id="myCarousel" className="carousel slide" data-ride="carousel">
//               <ol className="carousel-indicators">
//                 <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
//                 <li data-target="#myCarousel" data-slide-to="1"></li>
//                 {/* <li data-target="#myCarousel" data-slide-to="2"></li> */}
//               </ol>

//               <div className="carousel-inner" style={{ padding: "5%" }}>
//                 <div className="carousel-item active">
//                   <div className="img-box"><img src="./images/michael.jpg" alt="" /></div>
//                   <p className="testimonial">We trust SSS to deliver Technical Solutions that meet our Stringent business needs & always in a timely & Cost efficiency manner. SSS has shown a great commitment to customer satisfaction and it is a pleasure to work with them.</p>
//                   <p className="overview"><b>Gerry Mitchell</b></p>
//                 </div>
//                 <div className="carousel-item">
//                   <div className="img-box"><img src="./images/Kranthi.png" alt="" /></div>
//                   <p className="testimonial" style={{ fontSize: "18px" }}>
//                     <p style={{ textAlign: "justify" }}><strong style={{ fontSize: "20px", color: "#FC766A" }}>RED APRICOT</strong> had the pleasure of partnering with SSS on multiple engagements for development and enhancement of <strong>JUSTPERFORM</strong> product, and the experience exceeded all expectations in performance, Innovation, Quick Turnaround in Solution Provisioning.</p><p>
//                       SSS commitment is unparalleled, evident in the seamless and innovative solutions they brought to our project.  SSS client not only met our technical requirements with finesse but also demonstrated a keen understanding of our business goals, Ability to deliver beyond expectations make them an invaluable partner.</p>

//                     <p><strong style={{ fontSize: "20px", color: " #FC766A" }}>RED APRICOT</strong> partnership left a lasting positive impression.</p>

//                     <p>I recommend SSS collaboration for steer into solutions during Challenging situations.</p></p>
//                   <p className="overview"><b>Kranthi Kumar</b></p>
//                   <p className="overview"><b>Founder & Chief Innovation Officer</b></p>
//                 </div>
//               </div>

//               {/* <a className="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
//                 <span className="carousel-control-prev-icon" aria-hidden="true"></span>
//                 <span className="sr-only">Previous</span>
//               </a>
//               <a className="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
//                 <span className="carousel-control-next-icon" aria-hidden="true"></span>
//                 <span className="sr-only">Next</span>
//               </a> */}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Testimonials;


// import React from 'react';
// import './Testimonials.css';

// const Testimonials = () => {
//   return (
//     <div className='testimonials2'>
//       <div className="container-xl">
//         <div className="row">
//           <div className="col-lg-8 mx-auto">
//             <div className='bi-service'>
//               <h1 className='test'>Testimonials</h1>
//             </div>

//             <div id="myCarousel" className="carousel slide" data-ride="carousel">
//               <ol className="carousel-indicators">
//                 <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
//                 <li data-target="#myCarousel" data-slide-to="1"></li>
//                 {/* <li data-target="#myCarousel" data-slide-to="2"></li> */}
//               </ol>

//               <div className="carousel-inner" style={{ padding: "5%" }}>
//                 <div className="carousel-item active">
//                   <div className="img-box"><img src="./images/michael.jpg" alt="" /></div>
//                   <p className="testimonial">We trust SSS to deliver Technical Solutions that meet our Stringent business needs & always in a timely & Cost efficiency manner. SSS has shown a great commitment to customer satisfaction and it is a pleasure to work with them.</p>
//                   <p className="overview"><b>Gerry Mitchell</b></p>
//                 </div>
//                 <div className="carousel-item">
//                   <div className="img-box"><img src="./images/Kranthi.png" alt="" /></div>
//                   <p className="testimonial" style={{ fontSize: "18px" }}>
//                     <p style={{ textAlign: "justify" }}><strong style={{ fontSize: "20px", color: "#FC766A" }}>RED APRICOT</strong> had the pleasure of partnering with SSS on multiple engagements for development and enhancement of <strong>JUSTPERFORM</strong> product, and the experience exceeded all expectations in performance, Innovation, Quick Turnaround in Solution Provisioning.</p><p>
//                       SSS commitment is unparalleled, evident in the seamless and innovative solutions they brought to our project.  SSS client not only met our technical requirements with finesse but also demonstrated a keen understanding of our business goals, Ability to deliver beyond expectations make them an invaluable partner.</p>

//                     <p><strong style={{ fontSize: "20px", color: " #FC766A" }}>RED APRICOT</strong> partnership left a lasting positive impression.</p>

//                     <p>I recommend SSS collaboration for steer into solutions during Challenging situations.</p></p>
//                   <p className="overview"><b>Kranthi Kumar</b></p>
//                   <p className="overview"><b>Founder & Chief Innovation Officer</b></p>
//                 </div>
//               </div>

//               <a className="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
//                 <span className="carousel-control-prev-icon" aria-hidden="true"></span>
//                 <span className="sr-only">Previous</span>
//               </a>
//               <a className="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
//                 <span className="carousel-control-next-icon" aria-hidden="true"></span>
//                 <span className="sr-only">Next</span>
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Testimonials;


import { useEffect } from 'react';
import React  from 'react';
import './Testimonials.css';
import { Link } from 'react-router-dom';


const Testimonials = () => {

  return (
    <div className='testimonials2'>
      <div className="container-xl">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <div className='bi-service'>
              <h1 className='test' style={{color:"white"}}>Testimonials</h1>
            </div>

            <div id="myCarousel" className="carousel slide" data-ride="carousel">
              <ol className="carousel-indicators">
                <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                <li data-target="#myCarousel" data-slide-to="1"></li>
                {/* <li data-target="#myCarousel" data-slide-to="2"></li> */}
              </ol>

              <div className="carousel-inner" style={{ padding: "5%" }}>
                {/* <div className="carousel-item active">
                  <div className="img-box"><img src="./images/michael.jpg" alt="" /></div>
                  <p className="testimonial">We trust SSS to deliver Technical Solutions that meet our Stringent business needs & always in a timely & Cost efficiency manner. SSS has shown a great commitment to customer satisfaction and it is a pleasure to work with them.</p>
                  <p className="overview"><b>Gerry Mitchell</b></p>
                </div> */}
                <div className="carousel-item active">
                  <div className="img-box"><img src="./images/Kranthi.png" alt="" /></div>
                  <p className="testimonial" style={{ fontSize: "18px" }}>
                    <p style={{ textAlign: "justify" }}><strong style={{ fontSize: "20px", color: "#FC766A" }}>RED APRICOT</strong> had the pleasure of partnering with&nbsp;<strong style={{ fontSize: "20px", color: " #FC766A" }}>SSS</strong> &nbsp;on multiple engagements for development and enhancement of <strong>JUSTPERFORM</strong> product, and the experience exceeded all expectations in performance, Innovation, Quick Turnaround in Solution Provisioning.</p><p>
                    &nbsp;<strong style={{ fontSize: "20px", color: " #FC766A" }}>SSS</strong> &nbsp;commitment is unparalleled, evident in the seamless and innovative solutions they brought to our project.  SSS client not only met our technical requirements with finesse but also demonstrated a keen understanding of our business goals, Ability to deliver beyond expectations make them an invaluable partner.</p>

                    <p><strong style={{ fontSize: "20px", color: " #FC766A" }}>RED APRICOT</strong> partnership with &nbsp;<strong style={{ fontSize: "20px", color: " #FC766A" }}>SSS</strong> &nbsp;left a lasting positive impression.</p>

                    <p>I recommend SSS collaboration for steer into solutions during Challenging situations.</p></p>
                  <p className="overview"><b>Kranthi Kumar</b></p>
                  <p className="overview"><b>Founder & Chief Innovation Officer</b></p>
                  <Link to="https://www.linkedin.com/in/kranthi-justperform/">
                  <img src="https://static.vecteezy.com/system/resources/previews/018/930/587/non_2x/linkedin-logo-linkedin-icon-transparent-free-png.png" alt="HTML tutorial"
                    style={{ width: "11%", height: "10%" }}

  />
                  </Link>
                </div>
                <div className="carousel-item ">
                  <div className="img-box"><img src="./images/gerry.jpg" alt="" /></div>
                  <p className="testimonial" style={{ fontSize: "18px" }}>
                    <p style={{ textAlign: "justify" }}><strong style={{ fontSize: "20px", color: "#FC766A" }}>VALET WIRELESS INC,</strong> Over the years, we've had the privilege of collaborating with a diverse range of businesses, helping them achieve their goals through innovative and tailored IT solutions.</p>
                    {/* <p>
                      SSS commitment is unparalleled, evident in the seamless and innovative solutions they brought to our project.  SSS client not only met our technical requirements with finesse but also demonstrated a keen understanding of our business goals, Ability to deliver beyond expectations make them an invaluable partner.</p> */}

                    <p><strong style={{ fontSize: "20px", color: " #FC766A" }}>VALET WIRELESS INC,</strong> The innovation brought by &nbsp;<strong style={{ fontSize: "20px", color: " #FC766A" }}>SSS</strong>&nbsp;in defining the systematic and fool proof process and implementation of the same with cutting edge technologies are a great value add for our business</p>

                    <p>I recommend&nbsp;<strong style={{ fontSize: "20px", color: " #FC766A" }}>SSS</strong>&nbsp;collaboration for steer into solutions during Challenging situations.</p></p>
                  <p className="overview"><b>Gerry Skipwith</b></p>
                  <p className="overview"><b>President and Dealer Principal of VALET Wireless Inc.</b></p>
                  {/* <Link to="default.asp">
                   <img src="https://cdn-icons-png.flaticon.com/512/3536/3536394.png" alt="linkedin tutorial"
                    style={{ width: "13%", height: "13%" }}

  /> </Link> */}
  
  <Link to="https://www.linkedin.com/in/gerryskipwith/">
                  <img src="https://static.vecteezy.com/system/resources/previews/018/930/587/non_2x/linkedin-logo-linkedin-icon-transparent-free-png.png" alt="HTML tutorial"
                    style={{ width: "11%", height: "10%" }}

  />
                  </Link>
                </div>
              </div>

              {/* Remove or comment out the carousel control buttons */}
              <a className="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonials;
