import { useState } from 'react';
import ReactSimplyCarousel from 'react-simply-carousel';
import './Carousel.css';

function ManangedServicesCarousel() {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <div>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        forwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '30px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`>`}</span>,
        }}
        backwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '20px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`<`}</span>,
        }}
        responsiveProps={[
          {
            itemsToShow: 3,
            itemsToScroll: 3,
            minWidth: 780,
          },
        ]}
        speed={400}
        easing="linear"
      >

        <div style={{ width: 310, height: 650 }}>
        <div class="card bi-card-size" >
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/Process_development/deployment.png" class="rpa-img-top" alt="..." />

    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Process Development Services</h5>
      <ul class="card-text" >
        <li>Cell line selection and characterization</li>
        <li>Cell line quality assessment</li>
        <li>Culture media evaluation and optimization</li>
        <li>Batch, fed-batch, or perfusion process development</li>
        <li>Seed train/inoculum development</li>
        <li>Scale-up and viral clearance</li>
       </ul> </div>
  </div>
        </div>
        <div style={{ width: 310, height: 650}}>
        <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/Process_development/Big-Data-Maintenance-Support.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black">Process Development Maintanance Support</h5>
      <ul class="card-text" >
        <li>Training and Knowledge Transfer</li>
        <li>Performance monitoring</li>
        <li>Performance Analysis</li>
        <li>Documentation Maintenance</li>
        <li>Compliance and Regulation</li>
        <li>Continuous Monitoring</li>
        <li>Technology Updates</li>
        <li>Collaboration and Communication</li>
       </ul>
        </div>
  </div>
        </div>
        <div style={{ width: 310, height: 650}}>
        <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/Process_development/process-testing.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Process Development <br/>Testing and Validation</h5>
      <ul class="card-text" >
       <li>Test the newly designed process in a controlled environment or with
         a pilot group to identify any issues or gaps.</li>

       <li>Break down the process into its individual components and units for testing.</li>
       <li>Integration Testing</li>
       <li>Error Handling and Exception Testing</li>
       </ul>
    </div>
  </div>
        </div>
        <div style={{ width: 310, height: 650}}>
        <div class="card bi-card-size" >
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/Process_development/process-testing.png" class="rpa-img-top" alt="..."
     />
    <div class="card-body">
    <h5 class="card-title text-body-color-black" >Process Development <br/>Testing and Validation</h5>
      <ul class="card-text" >
       <li>Test the newly designed process in a controlled environment or with
         a pilot group to identify any issues or gaps.</li>

       <li>Break down the process into its individual components and units for testing.</li>
       <li>Integration Testing</li>
       <li>Error Handling and Exception Testing</li>
       </ul>

    </div>
  </div>
        </div>
        <div style={{ width: 310, height: 650 }}>
        <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/Process_development/Big-Data-Maintenance-Support.png" class="rpa-img-top" alt="..." />
    <div class="card-body" >
    <h5 class="card-title text-body-color-black">Process Development Maintanance Support </h5>
      <ul class="card-text" >
        <li>Training and Knowledge Transfer</li>
        <li>Performance monitoring</li>
        <li>Performance Analysis</li>
        <li>Documentation Maintenance</li>
        <li>Compliance and Regulation</li>
        <li>Continuous Monitoring</li>
        <li>Technology Updates</li>
        <li>Collaboration and Communication</li>


       </ul>
        </div>
  </div>
        </div>

        <div style={{ width: 310, height: 650 }}>
        <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/Process_development/BI-Consulting-Services-2.png" class="rpa-img-top" alt="..."/>
    <div class="card-body">
      <h5 class="card-title text-body-color-black">Process Development Consulting Services</h5>
      <ul class="card-text">
        <li>Initial Assessment</li>
        <li>Outline the scope, objectives, and approach of the consulting engagement.</li>
        <li>Process Analysis and Mapping</li>
        <li>Performance Metrics and Measurement</li>
        <li>Sustainability and Scalability Planning</li>
        <li>Redesign and Optimization</li>
       </ul>
    </div>
  </div>
        </div>

      </ReactSimplyCarousel>
    </div>
  );
}

export default ManangedServicesCarousel;
