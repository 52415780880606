import React from "react";
import "./Services.css";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <div>
      <section
        id="services"
        class="services"
        style={{ backgroundColor: "rgb(8,86,144)", padding: "2%" }}
      >
        <div class="container">
          <div class="section-title aos-init aos-animate" data-aos="fade-up">
            {/* <h2 style={{color:"ActiveBorder",color:"white"}}>Our Services</h2> */}
            <div class="bi-service">
              <h1 style={{ color: "ActiveBorder", color: "white" }}>
                What we do
              </h1>
            </div>
          </div>

          <div class="row">
            <div
              onclick=""
              class="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"
              style={{ cursor: "pointer", marginTop: "10px" }}
            >
              <div
                class="icon-box aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div class="icon">
                  <i class="bx bx-qr-scan"></i>
                </div>
                <h4 class="title">
                  <Link to="/data-engineering">Data Engineering Services</Link>
                </h4>
                <p class="description">
                  Navigate complexities and identify growth opportunities with
                  our clear roadmap of success.
                </p>
                <br />
                <br />
                <br />
                <Link
                  to="/data-engineering"
                  style={{
                    position: "absolute",
                    right: "20px",
                    bottom: "20px;",
                    color: "#0d6efd",
                  }}
                >
                  Learn more
                </Link>
              </div>
            </div>

            <div
              onclick=""
              class="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"
              style={{ cursor: "pointer", marginTop: "10px" }}
            >
              <div
                class="icon-box aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="icon">
                  <i class="bx bx-code-alt"></i>
                </div>
                <h4 class="title">
                  <Link to="/data-analytics">Data Analytics Services</Link>
                </h4>
                <p class="description">
                  SSS in Transform data into actionable insights to empower
                  advanced conversational AI platforms with comprehensive
                  solutions.
                </p>
                <br />
                <br />
                <Link
                  to="/data-analytics"
                  style={{
                    position: "absolute",
                    right: "20px",
                    bottom: "20px",
                    color: "#0d6efd",
                  }}
                >
                  Learn more
                </Link>
              </div>
            </div>

            <div
              onclick=""
              class="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"
              style={{ cursor: "pointer", marginTop: "10px" }}
            >
              <div
                class="icon-box aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div class="icon">
                  <i class="bx bxs-brain"></i>
                </div>
                <h4 class="title">
                  <Link to="/cloud-data-services">Data Cloud Services</Link>
                </h4>
                <p class="description">
                  SSS offers Cloud Services, uniting proven capabilities for the
                  construction, modernization, integration, and management of
                  your Multi-Cloud infrastructure and applications.
                </p>
                <br />
                <br />
                <Link
                  to="/cloud-data-services"
                  style={{
                    position: "absolute",
                    right: "20px",
                    bottom: "20px",
                    color: "#0d6efd",
                  }}
                >
                  Learn more
                </Link>
              </div>
            </div>

            <div
              onclick=""
              class="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"
              style={{ cursor: "pointer", marginTop: "10px" }}
            >
              <div
                class="icon-box aos-init"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div class="icon">
                  <i class="bx bx-hive"></i>
                </div>
                <h4 class="title">
                  <Link to="/data-science">Data Science/ AI / ML</Link>
                </h4>
                <p class="description">
                  SSS has harnessed the power of Artificial Intelligence and
                  Machine Learning through DecisionHUB to offer cutting-edge
                  AI+ML capabilities to businesses, featuring Vertical
                  Expertise, Cloud AI Services, Pre-built Models, and various
                  other products.
                </p>
                <br />
                <br />
                <Link
                  to="/data-science"
                  style={{
                    position: "absolute",
                    right: "20px",
                    bottom: "20px",
                    color: "#0d6efd",
                  }}
                >
                  Learn more
                </Link>
              </div>
            </div>

            <div
              onclick=""
              class="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"
              style={{ cursor: "pointer", marginTop: "10px" }}
            >
              <div
                class="icon-box aos-init"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div class="icon">
                  <i class="bx bxs-analyse"></i>
                </div>
                <h4 class="title">
                  <Link to="/app-development">App Development</Link>
                </h4>
                <p class="description">
                  Strategic planning, comprehensive training, smooth transition,
                  effective change management, and robust governance for
                  seamless operations.
                </p>
                <br />
                <br />
                <Link
                  to="/app-development"
                  style={{
                    position: "absolute",
                    right: "20px",
                    bottom: "20px",
                    color: "#0d6efd",
                  }}
                >
                  Learn more
                </Link>
              </div>
            </div>

            <div
              onclick=""
              class="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"
              style={{ cursor: "pointer", marginTop: "10px" }}
            >
              <div
                class="icon-box aos-init"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div class="icon">
                  <i class="bx bx-badge-check"></i>
                </div>
                <h4 class="title">
                  <Link to="/process-development">Process Development</Link>
                </h4>
                <p class="description">
                  SSS provide From analyzing your data landscape to implementing
                  robust governance frameworks and maximize its value.
                </p>
                <br />
                <br />
                <Link
                  to="/process-development"
                  style={{
                    position: "absolute",
                    right: "20px",
                    bottom: "20px",
                    color: "#0d6efd",
                  }}
                >
                  Learn more
                </Link>
              </div>
            </div>

            <div
              onclick="gotoURL('cloud-services.php')"
              class="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"
              style={{ cursor: "pointer", marginTop: "10px" }}
            >
              <div
                class="icon-box aos-init"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div class="icon">
                  <i class="bx bx-cog"></i>
                </div>
                <h4 class="title">
                  <Link to="/managed-services">Managed Services</Link>
                </h4>
                <p class="description">
                  Decision Minds provide Planning, training, transition, change
                  management, and governance for optimized operations..
                </p>
                <br />
                <br />
                <Link
                  to="/managed-services"
                  style={{
                    position: "absolute",
                    right: "20px",
                    bottom: "20px",
                    color: "#0d6efd",
                  }}
                >
                  Learn more
                </Link>
              </div>
            </div>
            <div
              onclick=""
              class="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"
              style={{ cursor: "pointer", marginTop: "10px" }}
            >
              <div
                class="icon-box aos-init"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div class="icon">
                  <i class="bx bx-cloud"></i>
                </div>
                <h4 class="title">
                  <Link to="/devops-services">DevOps</Link>
                </h4>
                <p class="description">
                  SSS provide Cloud Services that bring together proven
                  capabilities to build, modernize, Integrate and manage your
                  Multi-Cloud infrastructure and Applications.
                </p>
                <br />
                <br />
                <Link
                  to="/devops-services"
                  style={{
                    position: "absolute",
                    right: "20px",
                    bottom: "20px",
                    color: "#0d6efd",
                  }}
                >
                  Learn more
                </Link>
              </div>
            </div>
            <div
              onclick=""
              class="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0"
              style={{ cursor: "pointer", marginTop: "10px" }}
            >
              <div
                class="icon-box aos-init"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div class="icon">
                  {" "}
                  <i class="bx bx-cloud-lightning"></i>
                </div>
                <h4 class="title">
                  <Link to="/cloud-migration">Cloud Migration Services</Link>
                </h4>
                <p class="description">
                  Enhance reliability, functionality, and performance while
                  reducing testing time and costs for seamless customer
                  experiences.
                </p>
                <br />
                <br />
                <Link
                  to="/cloud-migration"
                  style={{
                    position: "absolute",
                    right: "20px",
                    bottom: "20px",
                    color: "#0d6efd",
                  }}
                >
                  Learn more
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
