import React from "react";
import "./Careers.css";

import { Container, Row, Col } from "react-bootstrap";
// import Gallery from '../components/Gallery';
import ContactUss from "../components/ContactUss";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const Careers = () => {
  return (
    <div
      className="container-fluid serviceDisplay"
      style={{ backgroundColor: "#f5faff", marginTop: "15vh" }}
    >
      <img
        src="https://sssbi-images.s3.ap-south-1.amazonaws.com/Employee+Images/careers+2.jpg"
        alt="Nature"
        style={{ width: "100%" }}
      />

      <div className="container-fluid">
        <div className="row">
          {/* <div className="text-center" style={{ color: "rgb(2, 199, 210)" }}>
            <h3 className="what">What are we about?</h3>
          </div> */}
           <div className="bi-service">

           <h1> What are we about
        </h1>
        </div>
          <div className="col-md-3 col-sm-12"></div>
          <div className="col-md-6 col-sm-12">
            <div className="fusion-text-6">
              <p className="text-center2 mb-4">
                Get ready for an infectious wave of enthusiasm, meaningful
                challenges, and a journey of continuous learning at SSS. We
                embrace the philosophy of swift failure and rapid learning,
                propelling us forward with each experience.
              </p>
              <p className="text-center2 mb-4">
                Within our vibrant SSS community, we'll encounter individuals
                known as 'SSSians' who pour their hearts into crafting the
                perfect dish at our monthly potlucks or engage in lively debates
                over the finest Nolan movie during lunch breaks. Here, every
                moment is an opportunity for enjoyment.
              </p>
              <p className="text-center2 mb-4">
                We're a team of passionate individuals deeply devoted to our
                craft, driven by an unwavering affection for what we do. The
                pride we feel for our achievements resonates within every aspect
                of our journey.
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-12"></div>
        </div>
      </div>

      <div className="key-values-section">
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={8} className="text-center">
              {/* <h2 className="section-title" style={{ color: "#02c7d2" }}>
                Our Key Values
              </h2> */}
              <div className="bi-service">

<h1>
 Our Key Values
</h1>
</div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={3} md={6} className="value-card">
              <img
                src="https://sssbi-images.s3.ap-south-1.amazonaws.com/careers/ambition.png"
                alt="Ambition"
                className="value-icon"
              />
              <h3 className="value-title">AMBITION</h3>
              <p className="value-description">
                Empowering individuals to scale new heights and accomplish their
                ambitious objectives.
              </p>
            </Col>

            <Col lg={3} md={6} className="value-card">
              <img
                src="https://sssbi-images.s3.ap-south-1.amazonaws.com/careers/teamwork.png"
                alt="Teamwork"
                className="value-icon"
              />
              <h3 className="value-title">TEAMWORK</h3>
              <p className="value-description">
                Working together to attain exceptional outcomes and bolster each
                other's triumphs.
              </p>
            </Col>

            <Col lg={3} md={6} className="value-card">
              <img
                src="https://sssbi-images.s3.ap-south-1.amazonaws.com/careers/growth.png"
                alt="Growth"
                className="value-icon"
              />
              <h3 className="value-title">GROWTH</h3>
              <p className="value-description">
                Offering ongoing learning and growth prospects to unlock your
                complete potential.
              </p>
            </Col>

            <Col lg={3} md={6} className="value-card">
              <img
                src="https://sssbi-images.s3.ap-south-1.amazonaws.com/careers/community.png"
                alt="Community"
                className="value-icon"
              />
              <h3 className="value-title">COMMUNITY</h3>
              <p className="value-description">
                Cultivating an inclusive and supportive community dedicated to
                making a positive impact together.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <div
        className="fusion-builder-row fusion-row fusion-flex-align-items-stretch fusion-flex-justify-content-space-evenly fusion-flex-content-wrap"
        style={{ backgroundColor: "#f5faff" }}
      >
        <div className="fusion-layout-column fusion_builder_column fusion-builder-column-14 fusion_builder_column_1_1 1_1 fusion-flex-column">
          <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-justify-content-flex-start fusion-content-layout-column">
            <div className="fusion-text fusion-text-12 fusion-text-no-margin innerGradientTitle">
              {/* <h2
                className="text-center mb-3"
                style={{
                  fontSize: "35px",
                  lineHeight: "1.2",
                  color: "rgb(2, 199, 210)",
                }}
              >
                Perks and Benefits
              </h2> */}
              <div className="bi-service">

<h1>
 Perks and Benefits
</h1>
</div>
            </div>
            <div className="fusion-text fusion-text-13 text-center">
              <p className="text-secondary">
                In an era of fierce competition and evolving landscapes,
                businesses must harness the power of data analysis to enhance
                decision-making speed and accuracy. This strategic approach not
                only ensures competitiveness in the present but also prepares
                businesses to thrive in the future.
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-justify-content-flex-start fusion-content-layout-column">
                <div className="fusion-image-element text-center">
                  <span className="fusion-imageframe imageframe-none imageframe-7 hover-type-none">
                    <img
                      src="https://sssbi-images.s3.ap-south-1.amazonaws.com/careers/targetbonus.png"
                      alt="Targeted Bonus Program"
                      title="Targeted Bonus Program"
                      className="img-responsive wp-image-1747 lazyautosizes lazyloaded"
                      srcSet="https://sssbi-images.s3.ap-south-1.amazonaws.com/careers/targetbonus.png"
                      sizes="100px"
                      style={{ width: "40%" }}
                    />
                  </span>
                </div>

                <div
                  className="fusion-text fusion-text-14"
                  style={{
                    textAlign: "center",
                    color: "black",
                    marginTop: "10px",
                    fontFamily: "AmsiPro-Regular",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "21px",
                  }}
                >
                  <p>Targeted Bonus Program</p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-justify-content-flex-start fusion-content-layout-column">
                <div className="fusion-image-element text-center">
                  <span className="fusion-imageframe imageframe-none imageframe-7 hover-type-none">
                    <img
                      src="https://sssbi-images.s3.ap-south-1.amazonaws.com/careers/healthcare.png"
                      alt="Health Care Program"
                      title="Health Care"
                      className="img-responsive wp-image-1747 lazyautosizes lazyloaded"
                      srcSet="https://sssbi-images.s3.ap-south-1.amazonaws.com/careers/healthcare.png"
                      sizes="100px"
                      style={{ width: "40%" }}
                    />
                  </span>
                </div>

                <div
                  className="fusion-text fusion-text-14"
                  style={{
                    textAlign: "center",
                    color: "black",
                    marginTop: "10px",
                    fontFamily: "AmsiPro-Regular",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "21px",
                  }}
                >
                  <p>Health Care</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="fusion-column-wrapper fusion-column-has-shadow fusion-flex-justify-content-flex-start fusion-content-layout-column">
                <div className="fusion-image-element text-center">
                  <span className="fusion-imageframe imageframe-none imageframe-7 hover-type-none">
                    <img
                      src="https://sssbi-images.s3.ap-south-1.amazonaws.com/careers/competitive+salary.png"
                      alt="competitive salary Program"
                      title="Competitive Salary"
                      className="img-responsive wp-image-1747 lazyautosizes lazyloaded"
                      srcSet="https://sssbi-images.s3.ap-south-1.amazonaws.com/careers/competitive+salary.png"
                      sizes="100px"
                      style={{ width: "40%" }}
                    />
                  </span>
                </div>

                <div
                  className="fusion-text fusion-text-14"
                  style={{
                    textAlign: "center",
                    color: "black",
                    marginTop: "10px",
                    fontFamily: "AmsiPro-Regular",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "21px",
                  }}
                >
                  <p>Competitive Salary</p>
                </div>
              </div>
            </div>

            <div className="col-md-3"></div>
          </div>
        </div>

        <div className="text-center">
          <Link
            className="fusion-button button-flat fusion-button-default-size button-custom fusion-button-default button-1 fusion-button-default-span startNowButton custom-button"
            target="_self"
            to="/"
          >
            <span className="fusion-button-text">Back To Home</span>
            <i
              className="fa-long-arrow-alt-right fas button-icon-right"
              aria-hidden="true"
            ></i>
          </Link>
        </div>
      </div>

      {/* <ContactUss /> */}

      <Footer />
    </div>
  );
};

export default Careers;
