




import React, { useState } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
  faPinterest,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
    const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(true);
  const validateEmail = () => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isValidEmail = regex.test(email);
    setIsValid(isValidEmail);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
    const images = [
    "https://sssbi-images.s3.ap-south-1.amazonaws.com/gallery/events-celebrations/Sankranti.png",
    "https://sssbi-images.s3.ap-south-1.amazonaws.com/gallery/events-celebrations/Sankranti+1.png",
    'https://sssbi-images.s3.ap-south-1.amazonaws.com/gallery/events-celebrations/DIWALI+(1).jpg',
    "https://sssbi-images.s3.ap-south-1.amazonaws.com/gallery/events-celebrations/Indepedence+day.png",
    'https://sssbi-images.s3.ap-south-1.amazonaws.com/gallery/events-celebrations/Birthday+Celebrations+2.png',
    "https://sssbi-images.s3.ap-south-1.amazonaws.com/gallery/events-celebrations/Diwali.png",
    'https://sssbi-images.s3.ap-south-1.amazonaws.com/gallery/events-celebrations/Diwali+2.png',
    'https://sssbi-images.s3.ap-south-1.amazonaws.com/gallery/events-celebrations/Dasara+Celebrations+copy.png',
    //  'https://sssbi-images.s3.ap-south-1.amazonaws.com/gallery/events-celebrations/Birthday+celebrations.png'


  ];

  const openLightbox = (index) => {
    setIsOpen(true);
    setPhotoIndex(index);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  return (

    <div>
  <footer>
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="footer-widget col-12 col-md-6 col-lg-4 first">
            <h3>About us</h3>
            <p><Link to ="">Srinivasan Software Solutions</Link> is committed to delivering fast, flexible, and powerful solutions by following best implementation strategies and practices with the best user experience.</p>
            <ul class="list-unstyled">
                  <li>
                    <i class="fa-solid fa-map-marker"></i>18-1-49, 1st Floor, Star Plaza, K.T. Road, Tirupati, Andhra Pradesh, India.</li>
                      <li>
                    <i class="fa-solid fa-map-marker"></i>238 Fleetwood Dr Oshawa, L1K3E7, Ontario, Canada</li>
              <li class="number"><i class="fa-solid fa-phone"></i> (+91) 0877-2233733, 720 721 3437</li>
              <li><i class="fa-solid fa-envelope"></i> info@sssbitech.com</li>
              {/* <li><i class="fa-brands fa-skype"></i> SSS</li> */}
            </ul>
          </div>

          <div class="footer-widget col-12 col-md-6 col-lg-4">
            <h3>Photo Gallery</h3>
            <div class="row">
              {images.map((image, index) => (
                <div key={index} class="col-6 col-md-3">
                  <div class="gallery glightbox" onClick={() => openLightbox(index)}>
                    <img src={image} alt={`Gallery Image ${index}`} />
                  </div>
                </div>
              ))}
                </div>

          </div>

          <div class="footer-widget col-12 col-md-12 col-lg-4">
            <h3>Newsletter Registration</h3>
            <p>Subscribe today to receive the latest <strong>SSS</strong> news via email. You may unsubscribe from this service at any time.</p>
            <form method="get">
              <div class="input-group newsletter" >
                <label class="sr-only" for="subscribe-email" style={{height:'10px'}}>Enter your email...</label>
                <input type="email" class="form-control" id="subscribe-email" placeholder="Enter your email..."style={{height:'46px'}} />
                <span class="input-group-btn">
                  <button type="submit" class="btn" style={{ paddingBottom: "9px" }}><i class="fa-solid fa-paper-plane" style={{paddingTop:"12px",textAlign:"center",justifyContent:"center"}}></i></button>
                </span>
              </div>
            </form>
            {/* <p class="newsletter-desc"><strong>QUICK LINKS</strong> </p> */}
          </div>
        </div>
      </div>
    </div>

    <div class="footer-bottom">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <ul class="list-inline social">
              <li class="list-inline-item">
                <Link to="https://www.facebook.com/srinivasansoftwaresolutionsPvt.Ltd?__hstc=38642556.ecd9f67d91817388a3cf5ae23855f7f1.1461569992317.1464353863423.1464581077900.32&__hssc=38642556.10.1464581077900&__hsfp=781976375">
                  <i class="fa-brands fa-facebook"></i>
                </Link>
              </li>
              <li class="list-inline-item">
                <Link to="https://www.twitter.com/jamesqquick">
                  <i class="fa-brands fa-twitter"></i>
                </Link>
              </li>
              <li class="list-inline-item">
                <Link to="https://in.linkedin.com/company/srinivasansoftwaresolutions">
                  <i class="fa-brands fa-linkedin"></i>
                </Link>
              </li>
              <li class="list-inline-item">
                <Link to="https://www.youtube.com/watch?v=RlleTJnVSqo">
                  <i class="fa-brands fa-youtube"></i>
                </Link>
              </li>
              <li class="list-inline-item">
                <Link to="https://www.instagram.com/srinivasansoftwaresolutions/">
                  <i class="fa-brands fa-instagram"></i>
                </Link>
              </li>
            </ul>
          </div>
          <div class="col-12 credits">
            <p>
              Copyright © 2009&nbsp;
              <Link to="http://www.sssbi.com">
                <p class="copyright-style">
                  <u>SSS</u>
                </p>
              </Link>
              . All Rights Reserved |{" "}
              <Link to="/terms-of-use" target="_blank" class="copyright-style">
                <u>
                  <p>Terms of Use</p>
                </u>
              </Link>{" "}
              |{" "}
              <Link to="/privacy-policy" target="_blank" class="copyright-style" >
                <u>
                  <p>Privacy policy</p>
                </u>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>

    <Link to="https://api.whatsapp.com/send?phone=[7207213437]&amp;text=hello" class="whatsappButton" id="whatsappButton" style={{ display: "inline", opacity: "1.0798" }}><i class="fa-brands fa-whatsapp"></i></Link>

    <Link to="#" class="scrollToTop" id="scrollToTop" style={{ display: "inline", opacity: "1.12235;" }}><i class="fa-solid fa-angle-up"></i></Link>
  </footer>

  {isOpen && (
    <Lightbox
      mainSrc={images[photoIndex]}
      nextSrc={images[(photoIndex + 1) % images.length]}
      prevSrc={images[(photoIndex + images.length - 1) % images.length]}
      onCloseRequest={closeLightbox}
      onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
      onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
    />
  )}
</div>

  );
};


export default Footer;

