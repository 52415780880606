
import React from 'react'
import './ServicesStyle.css';
import Footer from '../components/Footer';
import TechnologyList from '../components/TechnologyList';
import ProcessDevelopmentCarousel from './ProcessDevelopmentCarousel';
import ProcessDevelopmentCarousel1 from './ProcessDevelopmentCarousel1';
import { Link } from 'react-router-dom';

const ProcessDevelopment = () => {
  return (
    <div>
{/*
  <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/Process_development/process-development.jpg" alt="Nature" class="service-header-img"/>
  <div class="container-fluid">

  <div class="text-whitedata">
  <h1 class="text-size-head-black" style={{fontSize:"44px"}}>Process Development</h1>
<h4 class="text-size-head-black-h4" >Transforming Workflows, Elevating Efficiency</h4>
  </div>
  </div> */}

  <div className="container-fluid serviceDisplay"  style={{marginTop:'15vh'}}>
  <div className='row  ' >
    <div className='col'>
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/Service+page+sliders/Website+Sliders+Service+Page-07.jpg" alt="Nature" class="service-header-imgs-service"/>
    </div>
  </div>
</div>

<div class="container-fluid ">
  <div class="text-whitedata whitedata">
  <h1 class="text-size-head-white serviceDisplay-tag" >Process Development</h1>
<h4 class="text-size-head-white-h4 serviceDisplay-tags" >Transforming Workflows, Elevating Efficiency</h4>
  </div>
 </div>


<div class="container">
    <div class="row" >
      <div class="insight-matters"> <h1>Insights that matter</h1>
        <p class="governance-data-size">Certainly, process development is a crucial aspect of business operations that involves creating, refining,
            and optimizing workflows to achieve higher efficiency, better quality, and improved outcomes.</p>
            <p class="governance-data-size"> SSS Dealing with a process development initiative at a software and services (SSS) company involves a systematic approach to assess,
                 design, implement, and optimize workflows that align with the company's goals. </p>
             {/* <div class="bt-start-now">   <a class="btn-start" type="button" value="START NOW" placeholder="START NOW" href="/contact" target=""><p>START NOW</p></a>
            </div> */}
            <div className="text-center">
      <Link
        className="fusion-button button-flat fusion-button-default-size button-custom fusion-button-default button-1 fusion-button-default-span startNowButton custom-button"
        target="_self"
        to="/contact"
      >
        <span className="fusion-button-text">START NOW</span>
        {/* <i className="fa-long-arrow-alt-right fas button-icon-right" aria-hidden="true"></i> */}
      </Link>
            </div>

          </div> </div>
</div>

<div class="container">
<div class="row">
    <div class="governance-data">
        <h1>Streamlining Operations: Unleashing Efficiency Through Process Development</h1>

    <p class="governance-data-size" >Process development seeks to streamline workflows, eliminate redundancies, and reduce inefficiencies. By doing so, organizations
              can complete tasks more quickly and with fewer resources, leading to improved productivity. </p>
              <h6 class="challenges-size">Process development, while highly beneficial, can come with its own set of challenges and Unlocking the benefits of process development can have a transformative impact on
             an organization's operations, efficiency, and overall success:</h6>

        </div>


<div class="col-md-6">
    <div class="challenges">
        {/* <h6 class="challenges-size">Process development, while highly beneficial, can come with its own set of challenges:</h6> */}

        <ul>
       <p style={{marginTop:"17px"}}> <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img" />
       Resistance to Change
    </p>
   <p><img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img" />
      Lack of Clarity
    </p>
    <p><img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img" />
       Customization Challenges
    </p>
    <p> <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img" />
       Maintaining Flexibility
    </p>
   <p> <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img" />
      Managing Complexity
    </p>
    <p> <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img" />
      Time and Resource Constraints
    </p>
</ul>
</div></div>
<div class="col-md-6 ">
    <div class="interactive">
        {/* <h6 class="challenges-size">Unlocking the benefits of process development can have a transformative impact on
             an organization's operations, efficiency, and overall success.:</h6> */}
       <br/>
        <ul>
       <p> <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img" />
      Enhanced Efficiency
    </p>
   <p> <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img" />
       Agility and Adaptability
    </p>
    <p><img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img" />
       Innovation Enablement
    </p>
    <p><img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img" />
      Continuous Improvement Culture
    </p>
   <p> <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img" />
      Transparency and Accountability
    </p>
    <p> <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/footer_logo.gif" alt="" class="logo-img" />
       Data-Driven Insights
</p><br/>
</ul>

  </div></div>

  <div class="container-fluid">
  <div class="row service-row1" >
<div class="bigdata">
    <h1>Process Development Service Offerings</h1>
</div>
<ProcessDevelopmentCarousel/>
</div></div>

   {/* <div class="container-fluid">
  <div class="row service-row1" >
<div class="bigdata">
    <h1>Process Development Service Offerings</h1>
</div>

<div class="slide-show">
<div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">

    <div class="carousel-item active">
      <div class="card-group service-card-group" >
      <div class="card bi-card-size" >
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/Process_development/deployment.png" class="rpa-img-top" alt="..." />

    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Process Development Services</h5>
      <ul class="card-text" >
        <li>Cell line selection and characterization</li>
        <li>Cell line quality assessment</li>
        <li>Culture media evaluation and optimization</li>
        <li>Batch, fed-batch, or perfusion process development</li>
        <li>Seed train/inoculum development</li>
        <li>Scale-up and viral clearance</li>
       </ul> </div>
  </div>
  <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/Process_development/Big-Data-Maintenance-Support.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black">Process Development Maintanance Support</h5>
      <ul class="card-text" >
        <li>Training and Knowledge Transfer</li>
        <li>Performance monitoring</li>
        <li>Performance Analysis</li>
        <li>Documentation Maintenance</li>
        <li>Compliance and Regulation</li>
        <li>Continuous Monitoring</li>
        <li>Technology Updates</li>
       </ul>
        </div>
  </div>
  <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/Process_development/process-testing.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Process Development Testing and Validation</h5>
      <ul class="card-text" >
       <li>Test the newly designed process in a controlled environment or with
         a pilot group to identify any issues or gaps.</li>

       <li>Break down the process into its individual components and units for testing.</li>
       <li>Integration Testing</li>
       <li>Error Handling and Exception Testing</li>
       </ul>
    </div>
  </div>


      </div>
    </div>


    <div class="carousel-item">
      <div class="card-group service-card-group" >
      <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/Process_development/Big-Data-Maintenance-Support.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
    <h5 class="card-title text-body-color-black">Process Development Maintanance Support </h5>
      <ul class="card-text" >
        <li>Training and Knowledge Transfer</li>
        <li>Performance monitoring</li>
        <li>Performance Analysis</li>
        <li>Documentation Maintenance</li>
        <li>Compliance and Regulation</li>
        <li>Continuous Monitoring</li>
        <li>Technology Updates</li>
       </ul>
        </div>
  </div>
  <div class="card bi-card-size" >
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/Process_development/process-testing.png" class="rpa-img-top" alt="..."
     />
    <div class="card-body">
    <h5 class="card-title text-body-color-black" >Process Development Testing and Validation</h5>
      <ul class="card-text" >
       <li>Test the newly designed process in a controlled environment or with
         a pilot group to identify any issues or gaps.</li>

       <li>Break down the process into its individual components and units for testing.</li>
       <li>Integration Testing</li>
       <li>Error Handling and Exception Testing</li>
       </ul>

    </div>
  </div>
  <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/Process_development/BI-Consulting-Services-2.png" class="rpa-img-top" alt="..."/>
    <div class="card-body">
      <h5 class="card-title text-body-color-black">Process Development Consulting Services</h5>
      <ul class="card-text">
        <li>Initial Assessment</li>
        <li>Outline the scope, objectives, and approach of the consulting engagement.</li>
        <li>Process Analysis and Mapping</li>
        <li>Performance Metrics and Measurement</li>
        <li>Sustainability and Scalability Planning</li>
        <li>Redesign and Optimization</li>
       </ul>
    </div>
  </div>


      </div>
    </div>



  </div>
  <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div></div>
</div></div>  */}

<div class="container">
  <div class="row">
<div class="mg-management">
    <h1>Process Development- Programs | Solutions | Accelerators</h1>
</div>
<ProcessDevelopmentCarousel1/>
</div></div>


{/* <div class="container">
  <div class="row">
<div class="mg-management">
    <h1>Process Development- Programs | Solutions | Accelerators</h1>
</div>

<div id="myCarousel" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <div class="row" >
        <div class="col-lg-4" >
          <div class="card text ">
            <div class="card-body cloud-card-body-colors1" >
              <h5 class="card-title">Process Development Advisory Program </h5>
              <ul class="card-text">
              <li>Assessment and Diagnosis</li>
              <li>Customized Strategy Development</li>
              <li>Advisory Team Formation</li>
              <li>Process Analysis and Redesign</li>
              <li>Performance Metrics and Measuremen</li>
              <li>Regulatory Compliance Considerations</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4" >
          <div class="card text">
            <div class="card-body cloud-card-body-colors1"  >
              <h5 class="card-title">Process Development Implementation Program</h5>
              <ul class="card-text">
                <li>Detailed Process Documentation</li>
                <li>Technology Integration</li>
                <li>Project Kickof</li>
                <li>Performance Measurement and KPIs</li>
                <li>Ongoing Support and Monitoring</li>
                <li>Performance Measurement and KPIs</li>
                </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card text">
            <div class="card-body cloud-card-body-colors1"  >
              <h5 class="card-title">Process Development Managed Services </h5>
              <ul class="card-text">
                <li>Regulatory Compliance and Industry Standards</li>
                <li>Data-Driven Insights and Reporting</li>
                <li>Testing, Validation, and Performance Measurement</li>
                <li>Process Analysis and Design</li>
                <li>Assessment and Strategy Development</li>
                <li>Pilot Implementation</li>
                </ul>
               </div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-item ">
      <div class="row" >
        <div class="col-lg-4">
          <div class="card text " >
            <div class="card-body cloud-card-body-colors1">
            <h5 class="card-title">Process Development Implementation Program</h5>
              <ul class="card-text">
                <li>Detailed Process Documentation</li>
                <li>Technology Integration</li>
                <li>Project Kickof</li>
                <li>Performance Measurement and KPIs</li>
                <li>Ongoing Support and Monitoring</li>
                <li>Performance Measurement and KPIs</li>
                </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card text">
            <div class="card-body cloud-card-body-colors1"  >
              <h5 class="card-title">Process Development Managed Services </h5>
              <ul class="card-text">
                <li>Regulatory Compliance and Industry Standards</li>
                <li>Data-Driven Insights and Reporting</li>
                <li>Testing, Validation, and Performance Measurement</li>
                <li>Process Analysis and Design</li>
                <li>Assessment and Strategy Development</li>
                <li>Pilot Implementation</li>
                </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4" >
          <div class="card text ">
            <div class="card-body cloud-card-body-colors1" >
              <h5 class="card-title">Process Development Accelerators</h5>
              <ul class="card-text">
                <li>Process Templates and Frameworks</li>
                <li>Change Management Playbooks</li>
                <li>Workflow Automation Platforms</li>
                <li>Continuous Improvement Toolkits</li>
                <li>Collaboration and Communication Platforms</li>
                <li>Standardized Reporting Templates</li>
               </ul>
           </div>
          </div>
        </div>
      </div>
    </div>


  </div>
  <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
</div></div> */}

<div class="container">
    <div class="row" style={{marginTop:"0px",marginBottom:"30px"}}>
    <div class="bi-service">
    <h1>Our Technology Expertises</h1>
</div>


              <TechnologyList/>
    </div>
</div>


</div></div>




      <Footer/>



    </div>
  )
}

export default ProcessDevelopment
