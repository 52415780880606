import { useState } from 'react';
import ReactSimplyCarousel from 'react-simply-carousel';
import './Carousel.css';

function CloudDataCarousel1() {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <div>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        forwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '30px',
            flexDirection: 'row',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`>`}</span>,
        }}
        backwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '20px',
            flexDirection: 'row',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`<`}</span>,
        }}
        responsiveProps={[
          {
            itemsToShow: 3,
            itemsToScroll: 3,
            minWidth: 790,
          },
        ]}
        speed={400}
        easing="linear"
      >
        {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}
        <div style={{ width: 300, height: 550,flexDirection:"row" }}>
        <div class="card-body cloud-card-body-colors1" >
              <h5 class="card-title">Cloud Data Strategy Roadmap Initiative​ </h5>
              <ul class="card-text1">
              <li>Data Infrastructure and Storage Optimizatio​n</li>
              <li>Data Integration and Streamlined Processing</li>
              <li>Data Governance and Security Enhancement​</li>
              <li>Analytics and Business Intelligence Empowerment ​</li>
              <li>Advanced Analytics and Machine Learning Integration​</li>
              <li>Cloud Provider and Technology Selection</li>
              </ul>
            </div>
            </div>
        <div style={{ width: 300, height: 550}}>
        <div class="card-body cloud-card-body-colors1"  >
            <h5 class="card-title">Cloud Data Platform Deployment Initiative​</h5>
              <ul class="card-text1">
              <li>Comprehensive Data Landscape Assessment and Cloud Platform Selection</li>
              <li>Leverage a Range of Cloud Service Models</li>
              <li>Efficient Deployment of Cloud-Based BI, ETL, and Data Lake Solutions</li>
              <li>Configuration, Deployment, and Seamless Data Migration​</li>
              <li>Data Quality Assurance and Validation Measures​</li>
                
                </ul>
            </div>
        </div>
        <div style={{ width: 300, height: 550}}>
        <div class="card-body cloud-card-body-colors1"  >
             
              <h5 class="card-title">Cloud Data Quality Assurance Initiative​ </h5>
              <ul class="card-text1">
              <li>Deployment and Ongoing Suppor​</li>
              <li>Monitoring and Incident Management​</li>
              <li>Issue Resolution and Root Cause Analysis​</li>
              <li>Performance Analysis and Optimization</li>
              <li>Stakeholder Communication​</li>
              <li>Access to Experienced Resources​</li>

                </ul>
               </div>
        </div>
        <div style={{ width: 300, height: 550 }}>
        <div class="card-body cloud-card-body-colors1" >
            <h5 class="card-title">Cloud Data Platform Deployment Initiative​</h5>
              <ul class="card-text1">
              <li>Comprehensive Data Landscape Assessment and Cloud Platform Selection</li>
              <li>Leverage a Range of Cloud Service Models</li>
              <li>Efficient Deployment of Cloud-Based BI, ETL, and Data Lake Solutions</li>
              <li>Configuration, Deployment, and Seamless Data Migration​</li>
              <li>Data Quality Assurance and Validation Measures​</li>
                
                </ul>
            </div>
        </div>
        <div style={{ width: 300, height: 550}}>
        <div class="card-body cloud-card-body-colors1"  >
             
              <h5 class="card-title">Cloud Data Quality Assurance Initiative​ </h5>
              <ul class="card-text1">
              <li>Deployment and Ongoing Suppor​</li>
              <li>Monitoring and Incident Management​</li>
              <li>Issue Resolution and Root Cause Analysis​</li>
              <li>Performance Analysis and Optimization</li>
              <li>Stakeholder Communication​</li>
              <li>Access to Experienced Resources​</li>


                </ul>
            </div>

        </div>
        <div style={{ width: 300, height: 550 }}>
        <div class="card-body cloud-card-body-colors1" >
            <h5 class="card-title">Legacy System Transformation Initiative​</h5>
              <ul class="card-text1">
             <li>Determine the Appropriate Technology Stack</li>
             <li>Conduct Thorough Assessment of Legacy Systems​</li>
             <li>Transfer Enterprise Data Warehousing (EDW) to the Cloud</li>
             <li>Reconfigure ETL (Extract, Transform, Load) Processes</li>
             <li>Establish Modernization Approach and Data Migration Strategy​</li>
        
          

              </ul>
           </div>
        </div>
    
      </ReactSimplyCarousel>
    </div>
  );
}

export default CloudDataCarousel1;