

import React, { useEffect } from 'react';
import InstagramBlog from './InstragramBlog';

const FacebookBlog = () => {
  useEffect(() => {
    const script = document.createElement('script');
    // script.src = "https://i1.wp.com/www.sssbi.com/wp-content/plugins/easy-facebook-likebox/easy-facebook-likebox/public/assets/images/loader.gif?ssl=1";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup: Remove the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className='container' style={{justifyContent:"center",textAlign:"center"}}>
      <div className='row'>
        <div className='col'>
    <div className="fobox">
      <h5 className="fobox-title" style={{paddingTop:"50px",paddingLeft:"-20px",fontWeight:"bold"}}>Facebook Feeds</h5>
      <div className="focontent">
        <div id="fb-root" className="fb_reset">
          <div style={{ position: "absolute", top: "-10000px", width: "0px", height: "0px" }}>
            <div></div>
          </div>
        </div>
        <div className="efbl-like-box 1">
          <noscript>
            {/* <img
              className="efbl-loader"
              src="https://i1.wp.com/www.sssbi.com/wp-content/plugins/easy-facebook-likebox/easy-facebook-likebox/public/assets/images/loader.gif?ssl=1"
              data-recalc-dims="1"
              alt="Loader"
            /> */}
          </noscript>
          <div
            className="fb-page fb_iframe_widget"
            data-animclass="fadeIn"
            data-href="https://www.facebook.com/srinivasansoftwaresolutionsPvt.Ltd"
            data-hide-cover="false"
            data-width="300"
            data-height="300"
            data-show-facepile="true"
            data-show-posts="true"
            data-adapt-container-width="true"
            data-hide-cta="false"
            data-small-header="true"
            fb-xfbml-state="rendered"
            fb-iframe-plugin-query="adapt_container_width=true&amp;app_id=&amp;container_width=253&amp;height=300&amp;hide_cover=false&amp;hide_cta=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FsrinivasansoftwaresolutionsPvt.Ltd&amp;locale=en_US&amp;sdk=joey&amp;show_facepile=true&amp;show_posts=true&amp;small_header=true&amp;width=300"
          >
            <span style={{ verticalAlign: "bottom", width: "250px", height: "450px" }}>
              <iframe
                name="f2fd68df088a5b"
                width="300px"
                height="450px"
                data-testid="fb:page Facebook Social Plugin"
                title="fb:page Facebook Social Plugin"
                frameBorder="0"
                allowTransparency="true"
                allowFullScreen="true"
                scrolling="yes"
                allow="encrypted-media"
                src="https://www.facebook.com/v2.6/plugins/page.php?adapt_container_width=true&amp;app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df33733a2ad1fec4%26domain%3Dwww.sssbi.com%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fwww.sssbi.com%252Ff221446448871ec%26relation%3Dparent.parent&amp;container_width=280&amp;height=400&amp;hide_cover=false&amp;hide_cta=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FsrinivasansoftwaresolutionsPvt.Ltd&amp;locale=en_US&amp;sdk=joey&amp;show_facepile=true&amp;show_posts=true&amp;small_header=true&amp;width=350"
                style={{ border: "none", visibility: "visible", overflow: "auto", width: "270px", height: "480px" }}
                id="iFrameResizer3"
                className=""
              ></iframe>
            </span>
          </div>
        </div>
         {/* <InstagramBlog/>  */}
      </div>
    </div></div></div></div>
  );
};

export default FacebookBlog;

