import React from 'react'
import './Solutions.css';
import Footer from '../components/Footer';

const Manufacturing= () => {
  return (
    <div>
        {/* <div class="container-fluid"> */}
  <img src="../images/solutions/manufacturing-img.jpeg" alt="Nature" class="service-header-img"/>
  <div class="text-whitedata">
  <h1 class="text-size-head-white">Manufacturing <br/>
Intelligence</h1>
<h4 >Transforming Manufacturing through Intelligent Analytics</h4>
  </div>
{/* </div> */}

<div class="container">
    <div class="row" style={{color:"rgb(125, 136, 155)",marginTop:"40px"}}>
      <div class="insight-matter"> <h1>What we do</h1>
        <p>Manufacturing intelligence solutions are crucial for organizations to optimize manufacturing processes, 
            reduce costs, improve quality control, and support informed decision-making. ​​​​​</p>
    <p>SSS Manufacturing intelligence solution leverages the power of data analytics 
        for businesses to improve their efficiency, profitability, and overall performance.​</p>
    </div> </div> 
</div>


<div class="container-fluid key-benefits-bg-color">
    <div class="row">
        <div class="solutions-head">
            <h1>Key Benefits</h1>
        </div>
        <div class="col-md-3 key-margin3 row-center">
            <img src="./images/solutions/Remote-Monitoring-5.png" alt="" class="key-benifits-img3"></img>
            <p class="key-benifits-p">Real-time monitoring​​</p>
        </div>
        <div class="col-md-3 key-margin3 row-center">
        <img src=" ./images/solutions/Supply-Chain.png" alt="" class="key-benifits-img3"></img>
        <p class="key-benifits-p">Supply chain optimization​​​</p>
        </div>
        <div class="col-md-3 key-margin3 row-center">
        <img src="./images/solutions/Process-efficiency.png" alt="" class="key-benifits-img3"></img>
        <p class="key-benifits-p">Process optimization​</p>
        </div>
        <div class="col-md-3 key-margin3 row-center">
        <img src="./images/solutions/Decision-Making.png" alt="" class="key-benifits-img3"></img>
        <p class="key-benifits-p">Data driven decision-making support​​​</p>
        </div>
        <div class="col-md-3 key-margin3 row-center">
        <img src="./images/solutions/Quality-Control-1.png" alt="" class="key-benifits-img3"></img>
        <p class="key-benifits-p">Quality control​​​​</p>
        </div>
        
        <div class="col-md-3 key-margin3 row-center">
        <img src="./images/solutions/Cost-Reduction.png" alt="" class="key-benifits-img3"></img>
        <p class="key-benifits-p">Cost reduction​​​​</p>
        </div>
        <div class="col-md-3 key-margin3 row-center">
        <img src="./images/solutions/Predictive-Analysis-4.png" alt="" class="key-benifits-img3"></img>
        <p class="key-benifits-p">Predictive analytics​​​​</p>
        </div>
    </div>
      </div>
       <Footer/>
      
    </div>
  )
}

export default Manufacturing
