// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import ReactPlayer from 'react-player';
// import './HomeVideo.css';

// const HomeVideo = () => {
//   return (
  
//       <div className='container-fluid video-container'>
//   <ReactPlayer 
//   url="https://sssbi-images.s3.ap-south-1.amazonaws.com/Logos/Home.mp4" type="video/mp4"
//   width="100%"
//   height="100%"
//               controls
//               playing muted loop />
// </div>
//   )
// }

// export default HomeVideo


// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import ReactPlayer from 'react-player';
// import './HomeVideo.css';

// const HomeVideo = () => {
//   return (
//     <div className='container-fluid video-container'>
//       <ReactPlayer 
//         url="https://sssbi-images.s3.ap-south-1.amazonaws.com/Logos/Home.mp4"
//         type="video/mp4"
//         width="100%"
//         height="100%"
//         playing
//         muted
//         loop
//       />
//     </div>
//   );
// }

// export default HomeVideo;



import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactPlayer from 'react-player';
import './HomeVideo.css';

const HomeVideo = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div className='container-fluid video-container' onClick={togglePlay}>
      {!isPlaying && (
        <div className="play-overlay">
          <svg className="play-button" viewBox="0 0 85 100" fill="#ffffff">
            <polygon points="0 0 0 100 85 50"></polygon>
          </svg>
        </div>
      )}
      <ReactPlayer 
        url="https://sssbi-images.s3.ap-south-1.amazonaws.com/Logos/Home.mp4"
        type="video/mp4"
        width="100%"
        height="100%"
        playing={isPlaying}
        muted
        loop
      />
    </div>
  );
}

export default HomeVideo;
