import React from 'react'
import './Solutions.css';
import Footer from '../components/Footer';

const BigDataManaged = () => {
  return (
    <div>
      <img src="../images/solutions/big-data-managed.jpg" alt="Nature" class="service-header-img"/>
        <div class="container-fluid">
  {/* <img src="../images/solutions/big-data-managed.jpg" alt="Nature" class="service-header-img"/> */}
  
        <div class="text-whitedata">
  <h1 class="text-size-head-white">Big Data Managed<br/>
Services Program​</h1>
<h4 >Experience Seamless Management with SSS Big Data Managed Services Program</h4>
  </div>
</div>

<div class="container">
    <div class="row" style={{color:"rgb(125, 136, 155)",marginTop:"40px"}}>
      <div class="insight-matter"> <h1>What we do</h1>
        <p>By outsourcing big data infrastructure and operations to a partner service provider, organizations can focus on their core business functions while
             leveraging the partner team’s expertise and resources to improve their big data capabilities.
​​​​​</p>
    <p>SSS Big Data Managed Services Program provides organizations with a cost-effective and scalable way to manage 
        their big data operations that can be tailored to meet specific business needs​</p>
    </div> </div> 
</div>
<div class="container">
<div class="row">
    <div class="solutions-head">
        <h1>Key Features</h1>
    </div>
    <div class="col-md-3"><p class="key-features-p">Platform administration for data lake, data mart & data warehouse​​​</p></div>
    <div class="col-md-3"><p class="key-features-p">Security patch<br/> Implementation​​​​</p></div>
    <div class="col-md-3"><p class="key-features-p">Version Upgrade & Health checks​​​​</p></div>
    <div class="col-md-3"><p class="key-features-p">Backup &<br/> Recovery​​</p></div>
    <div class="col-md-3"><p class="key-features-p">Performance<br/> Monitoring​​​​</p></div>
    <div class="col-md-3"><p class="key-features-p">Big Data Management<br/> & Reporting​​​</p></div>
<div class="col-md-3"><p class="key-features-p">Solution setup &<br/> support​​​​</p></div>
<div class="col-md-3"><p class="key-features-p">Customized development<br/> services​​​​</p></div>
    
</div>
</div>

<div class="container-fluid key-benefits-bgcolor">
    <div class="row ">
        <div class="solutions-head">
            <h1>Key Benefits</h1>
        </div>
        <div class="col-md-6 key-margin1 row-center2">
            <img src="./images/solutions/Performance-2.png" alt="" class="key-benifits-img"></img>
            <p class="key-benifits-p">Improved performance, availability,<br/> and security of the big <br/>data environment​</p>
        </div>
        <div class="col-md-6 key-margin1 row-center2">
        <img src=" ./images/solutions/Advanced-Visualization.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Advanced analytics expertise<br/> and specialized big data<br/> tools capabilities ​</p>
        </div>
          </div>
      </div>
      

       <Footer/>
      
    </div>
  )
}

export default BigDataManaged
