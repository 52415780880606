import React from 'react'
import './Solutions.css';
import Footer from '../components/Footer';

const MaterialInflation = () => {
  return (
    <div>
        <div class="container-fluid">
  <img src="../images/solutions/material-inflation.jpg" alt="Nature" class="service-header-img"/>
  <div class="text-whitedata">
  <h1 class="text-size-head-white">Material Inflation <br/>
Analysis</h1>
<h4 >Optimizing Sustainability with Material Inflation Analysis</h4>
  </div>
</div>

<div class="container">
    <div class="row" style={{color:"rgb(125, 136, 155)",marginTop:"40px"}}>
      <div class="insight-matter"> <h1>What we do</h1>
        <p>Material inflation analyser is crucial for organizations to stay ahead of the curve and 
            ensure long-term sustainability and success. SSS Material Inflation Analyzer 
            helps businesses manage costs, optimize inventory levels, improve supply chain efficiency and make informed pricing decisions.

​​​​​</p>
    {/* <p>SSS Cloudera CDP Migration Program helps organizations gain access to new features and
         functionalities that enhance their big data experience.​</p> */}
    </div> </div> 
</div>
<div class="container">
<div class="row">
    <div class="solutions-head">
        <h1>Key Features</h1>
    </div>
    <div class="col-md-4"><p class="key-features-p">Real-time
data analysis​​</p></div>
    <div class="col-md-4"><p class="key-features-p">Data
visualization​​​</p></div>
    <div class="col-md-4"><p class="key-features-p">Historical
data tracking​​​</p></div>
    <div class="col-md-4"><p class="key-features-p">Customizable
reporting​​</p></div>
    <div class="col-md-4"><p class="key-features-p">Automated
alerts​​​</p></div>
    <div class="col-md-4"><p class="key-features-p">Supplier
comparisont​​​</p></div>
    
</div>
</div>

<div class="container-fluid key-benefits-bg-color">
    <div class="row">
        <div class="solutions-head">
            <h1>Key Benefits</h1>
        </div>
        <div class="col-md-2 key-margin1 row-center">
            <img src="./images/solutions/Cost-Management.png" alt="" class="key-benifits-img"></img>
            <p class="key-benifits-p">Cost management</p>
        </div>
        <div class="col-md-2 key-margin1 row-center">
        <img src=" ./images/solutions/Price-Strategy.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Profitable pricing strategy</p>
        </div>
        <div class="col-md-2 key-margin1 row-center">
        <img src="./images/solutions/Market-Competitiveness.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Market competitiveness</p>
        </div>
        <div class="col-md-2 key-margin1 row-center">
        <img src="./images/solutions/Inventory-Management.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Inventory management​​</p>
        </div>
        <div class="col-md-2 key-margin1 row-center">
        <img src="./images/solutions/Supply-Chain.png" alt="" class="key-benifits-img"></img>
        <p class="key-benifits-p">Supply chain optimization​​</p>
        </div>
    </div>
</div>
      

       <Footer/>
    </div>
  )
}

export default MaterialInflation
