import { useState } from 'react';
import ReactSimplyCarousel from 'react-simply-carousel';
import './Carousel.css';

function DataAnalyticsCarousel() {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <div>
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        forwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '30px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`>`}</span>,
        }}
        backwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          style: {
            alignSelf: 'center',
            background: 'black',
            border: 'none',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
            fontSize: '20px',
            height: 40,
            lineHeight: 1,
            textAlign: 'center',
            width: 40,
          },
          children: <span>{`<`}</span>,
        }}
        responsiveProps={[
          {
            itemsToShow: 3,
            itemsToScroll: 3,
            minWidth: 780,
          },
        ]}
        speed={400}
        easing="linear"
      >
        {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}
        <div style={{ width: 310, height: 680 }}>
        <div class="card bi-card-size" >
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/dataScience/Data-Science-Advisory-Services.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Data Analytics Strategy Guidance</h5>
      <ul class="card-text" >
        <li>Craft an Analytics Strategy</li>
        <li>Identify Use Cases</li>
        <li>Tailor a Data Analytics Roadmap</li>
        <li>Build a Data Analytics Framework</li>
        <li>Explore Data and Select Relevance</li>
        <li>Create Scalable Data Analytics Processes</li>
       </ul> </div>
  </div>
        </div>
        <div style={{ width: 310, height: 680}}>
        <div class="card bi-card-size" >
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/dataScience/Advance-Analytics-Services.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black">Innovative Data Analytics Offerings</h5>
      <ul class="card-text" >
      <li>Develop analytics strategy</li>
      <li>Install and configure the analytics platform</li>
      <li>Develop and deploy exploratory analytics models</li>
      <li>Implement predictive analytics solutions</li>
   <li>Create an analytical platform for customer insights and a 360-degree view</li>
       </ul>
        </div>
  </div>
        </div>
        <div style={{ width: 310, height: 680}}>
        <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/dataScience/AI-ML-Development-1.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Data Analytics Development Services</h5>
      <ul class="card-text" >
      <li>Offer Customized Customer Experience Solutions</li>
      <li>Tailor AI Solutions to Individual Customer Requirements</li>
      <li>Integrate Chatbots and Automated Assistants for Communication</li>
      <li>Leverage Enterprise Application Engineering for Data Extraction</li>
       </ul>
    </div>
  </div>
        </div>
        <div style={{ width: 310, height: 680 }}>
        <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/dataScience/Advance-Analytics-Services.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
    <h5 class="card-title text-body-color-black">Innovative Data Analytics Offerings</h5>
      <ul class="card-text" >
      <li>Develop analytics strategy</li>
      <li>Install and configure the analytics platform</li>
      <li>Develop and deploy exploratory analytics models</li>
      <li>Implement predictive analytics solutions</li>
   <li>Create an analytical platform for customer insights and a 360-degree view</li>
   <li>Augmented Analytics</li>
       </ul>
        </div>
  </div>
        </div>
        <div style={{ width: 310, height: 680}}>
        <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/dataScience/AI-ML-Development-1.png" class="rpa-img-top" alt="..." />
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Data Analytics Development Services</h5>
      <ul class="card-text" >
      <li>Offer Customized Customer Experience Solutions</li>
      <li>Tailor AI Solutions to Individual Customer Requirements</li>
      <li>Integrate Chatbots and Automated Assistants for Communication</li>
      <li>Leverage Enterprise Application Engineering for Data Extraction</li>
       </ul>
    </div>
  </div>

        </div>
        <div style={{ width: 310, height: 680 }}>
        <div class="card bi-card-size">
    <img src="https://sssbi-images.s3.ap-south-1.amazonaws.com/services/dataScience/Managed-Services.png" class="rpa-img-top" alt="..."/>
    <div class="card-body">
      <h5 class="card-title text-body-color-black" >Managed Data Analytics Services</h5>
      <ul class="card-text">
      <li>Offer Engineering Support to Customers</li>
      <li>Conduct Thorough Testing to Identify Bugs</li>
      <li>Collaborate with the Engineering Team to Report Bugs, Update, and Prepare Documentation</li>
      <li>Administer Data Analytics Products and Services</li>
      <li>Prescriptive Analytics</li>
       </ul>
    </div>
  </div>

        </div>

      </ReactSimplyCarousel>
    </div>
  );
}

export default DataAnalyticsCarousel;
