import React from 'react';
import styled from 'styled-components';
import ContactForm from './ContactForm';
import RobotCheckbox from './RobotCheckbox';
import SubmitButton from './SubmitButton';

const ContactUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  background: linear-gradient(180deg, #014962 0%, #02877b 100%);
`;

const ContactUs = () => {
  return (
    <div>
      <ContactUsContainer>
        <div className="text-white">
          <h2 className="text-center1" style={{ fontSize: "50px" }}>
            Contact Us
          </h2>
          <p className="text-center contact-details">
            <span className="contact-location text-center1">
              <strong>India</strong> – Tirupati, Andhra Pradesh
            </span>
          </p>
        </div>
        <ContactForm />
       
      </ContactUsContainer>
    </div>
  );
};

export default ContactUs;
