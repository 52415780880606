import React, { useEffect } from 'react';
// import Swiper from 'swiper';
// import 'swiper/swiper-bundle.min.css'; 
import './Why.css';

const Why = () => {
  
  return (
      <div>
          

          <section class="content__section section--no-padding section--perks perks why-choose-us"><div class="perks__viewport viewport">
              <div class="perks__header section__header"><h2>What do you get by choosing us?</h2></div><div class="perks__body section__body">
                  <div class="perks__slider swiper-slider grid--four-columns ovee-component" data-swiper-perks="" data-bp="(max-width: 1279px)">
                      {/* <div class="swiper-slider__pagination is-clickable"><button type="button" class="pagination__item">1</button><button type="button" class="pagination__item">2</button><button type="button" class="pagination__item">3</button><button type="button" class="pagination__item">4</button><button type="button" class="pagination__item">5</button><button type="button" class="pagination__item">6</button><button type="button" class="pagination__item">7</button><button type="button" class="pagination__item">8</button><button type="button" class="pagination__item">9</button><button type="button" class="pagination__item">10</button><button type="button" class="pagination__item">11</button><button type="button" class="pagination__item">12</button><button type="button" class="pagination__item">13</button><button type="button" class="pagination__item">14</button><button type="button" class="pagination__item">15</button><button type="button" class="pagination__item">16</button><button type="button" class="pagination__item">17</button><button type="button" class="pagination__item">18</button><button type="button" class="pagination__item">19</button><button type="button" class="pagination__item">20</button><button type="button" class="pagination__item">21</button><button type="button" class="pagination__item">22</button><button type="button" class="pagination__item">23</button><button type="button" class="pagination__item">24</button><button type="button" class="pagination__item">25</button></div> */}
                      <div class="perks__item perks-item swiper-slider__slide slider__slide swiper-slide" data-slide-number="0" ><div class="perks-item__icon">
                          <img class="lazy ovee-component entered loaded" data-lazy-load="" data-src="/user/themes/softwaremill/assets/svg/join-us/snwuhgmjimdlg9r.svg?g-bad3cb52" data-ll-status="loaded" src='images/why choose/1.jpg'/></div>
                          <h4 class="perks-item__title">Software experts with a business mindset</h4><div class="perks-item__desc"><p>Technology decisions are an ongoing process. Our engineers take into account the cost effectiveness and business impact when taking care of your project.</p></div></div><div class="perks__item perks-item swiper-slider__slide slider__slide swiper-slide" data-slide-number="1" ><div class="perks-item__icon">
                          <img class="lazy ovee-component entered loaded" data-lazy-load="" data-src="/user/themes/softwaremill/assets/svg/join-us/mupistdxkcxhq8k.svg?g-bad3cb52" data-ll-status="loaded" src='images/why choose/2.jpg'/></div>
                          <h4 class="perks-item__title">Scale with the right technology</h4><div class="perks-item__desc"><p>Custom software must always be designed for a specific set of business needs. We have years of commercial experience with various technologies and can choose the right tech stack for your project.</p></div></div><div class="perks__item perks-item swiper-slider__slide slider__slide swiper-slide" data-slide-number="2" ><div class="perks-item__icon"><img class="lazy ovee-component entered loaded" data-lazy-load="" data-src="/user/themes/softwaremill/assets/svg/join-us/8mzgvojvhlfkz2e.svg?g-bad3cb52" data-ll-status="loaded" src='images/why choose/3.jpg'/></div>
                          <h4 class="perks-item__title">Quality software product</h4><div class="perks-item__desc"><p>You get an efficient and scalable digital product. During our cooperation, we make sure the final solution is a maintainable and working software that brings real value. </p></div></div><div class="perks__item perks-item swiper-slider__slide slider__slide swiper-slide" data-slide-number="3" ><div class="perks-item__icon">
                          <img class="lazy ovee-component entered loaded" data-lazy-load="" data-src="/user/themes/softwaremill/assets/svg/join-us/skcq4qauzdxfjlg.svg?g-bad3cb52" data-ll-status="loaded" src='images/why choose/4.jpg'/></div>
                          <h4 class="perks-item__title">Software project rescue</h4><div class="perks-item__desc"><p>Mistakes happen. Need to rescue your software? We will assist you with identifying the problem, gathering information, and recommending solutions for your project in need.</p></div></div><div class="perks__item perks-item swiper-slider__slide slider__slide swiper-slide" data-slide-number="4" ><div class="perks-item__icon"><img class="lazy ovee-component entered loaded" data-lazy-load="" data-src="/user/themes/softwaremill/assets/svg/join-us/sdrup8q7xzftcxk.svg?g-bad3cb52" data-ll-status="loaded" src='images/why choose/5.jpg'/></div>
                          <h4 class="perks-item__title">Trusted tech partner</h4><div class="perks-item__desc"><p>Our organization is based on trust. We don't have managers to manage people, we create rules together. We cultivate relationships with clients in the same transparent and honest way.</p></div></div><div class="perks__item perks-item swiper-slider__slide slider__slide swiper-slide" data-slide-number="5" ><div class="perks-item__icon"><img class="lazy ovee-component entered loaded" data-lazy-load="" data-src="/user/themes/softwaremill/assets/svg/join-us/tsp91lgwcejlaix.svg?g-bad3cb52" data-ll-status="loaded" src='images/why choose/6.jpg'/></div>
                          <h4 class="perks-item__title">No red tape</h4><div class="perks-item__desc"><p>Your time is valued and your business goals are our goals. We are organizationally efficient, well-organized, and have a quick decision-making process based on trust and ownership. </p></div></div><div class="perks__item perks-item swiper-slider__slide slider__slide swiper-slide" data-slide-number="6" ><div class="perks-item__icon"><img class="lazy ovee-component entered loaded" data-lazy-load="" data-src="/user/themes/softwaremill/assets/svg/join-us/pjm4wchffnlink9.svg?g-bad3cb52" data-ll-status="loaded" src='images/why choose/7.jpg'/></div><h4 class="perks-item__title">Security in good hands</h4><div class="perks-item__desc"><p>We build security into every software, none of our work has ever been leaked or hacked. The integrity and confidentiality of your data, as well as the security of the infrastructure is our priority. </p></div></div><div class="perks__item perks-item swiper-slider__slide slider__slide swiper-slide" data-slide-number="7" ><div class="perks-item__icon">
                          <img class="lazy ovee-component entered loaded" data-lazy-load="" data-src="/user/themes/softwaremill/assets/svg/join-us/q8fcioefupr42n9.svg?g-bad3cb52" data-ll-status="loaded" src='images/why choose/8.jpg'/></div><h4 class="perks-item__title">Project kick-off at your HQ</h4><div class="perks-item__desc"><p>We can establish common goals and the purpose of the project at your HQ. It’s not uncommon that we visit our clients worldwide and the start of a project is a great opportunity to do that.</p></div></div><div class="perks__item perks-item swiper-slider__slide slider__slide swiper-slide" data-slide-number="8" ><div class="perks-item__icon"><img class="lazy ovee-component entered loaded" data-lazy-load="" data-src="/user/themes/softwaremill/assets/svg/join-us/gh9xvabdun7refq.svg?g-bad3cb52" data-ll-status="loaded" src='images/why choose/9.jpg'/></div><h4 class="perks-item__title">Individual approach</h4><div class="perks-item__desc"><p>Our team is available and engaged throughout the project to get to know your input, address your concerns and make sure everyone is on the same page. We proactively work to nail down your requirements.</p></div></div><div class="perks__item perks-item swiper-slider__slide slider__slide swiper-slide" data-slide-number="9" ><div class="perks-item__icon">
                          <img class="lazy ovee-component entered loaded" data-lazy-load="" data-src="/user/themes/softwaremill/assets/svg/join-us/n28mhc1yxrvhwgz.svg?g-bad3cb52" data-ll-status="loaded" src='images/why choose/10.jpg'/></div>
                          <h4 class="perks-item__title">13 years of commercial experience</h4><div class="perks-item__desc"><p>We take pride in the quality of our code and we believe in software craftsmanship. Just last year, we helped 20 organisations build efficient and scalable products, with our tech stack being mainly Java, Scala, Akka, Kafka, but also many other technologies.</p></div></div><div class="perks__item perks-item swiper-slider__slide slider__slide swiper-slide" data-slide-number="10" ><div class="perks-item__icon"><img class="lazy ovee-component entered loaded" data-lazy-load="" data-src="/user/themes/softwaremill/assets/svg/join-us/8nbdl9eewos7hps.svg?g-bad3cb52" data-ll-status="loaded" src='images/why choose/11.jpg'/></div><h4 class="perks-item__title">Avg project duration of 7 years</h4><div class="perks-item__desc"><p>Our dev talent brings in software craftsmanship, enthusiasm and dedication even to the most arduous IT tasks. We love what we do, clients feel it. Our business partnerships last 7 years on average.</p></div></div>
                      <div class="perks__item perks-item swiper-slider__slide slider__slide swiper-slide" data-slide-number="11" ><div class="perks-item__icon"><img class="lazy ovee-component entered loaded" data-lazy-load="" data-src="/user/themes/softwaremill/assets/svg/join-us/xlsxfpy5ko1ectq.svg?g-bad3cb52" data-ll-status="loaded" src='images/why choose/12.jpg'/></div><h4 class="perks-item__title">Engaged developers</h4><div class="perks-item__desc"><p>We create the best workplace for engineers. Empowerment and trust we share results in higher satisfaction. Our team brings in creativity, efficiency and dedication. On average, developers work with us for 4 years. </p></div></div><div class="perks__item perks-item swiper-slider__slide slider__slide swiper-slide" data-slide-number="12" ><div class="perks-item__icon"><img class="lazy ovee-component entered loaded" data-lazy-load="" data-src="/user/themes/softwaremill/assets/svg/join-us/cpwvj6qp0evd93s.svg?g-bad3cb52" data-ll-status="loaded" src='images/why choose/13.jpg'/></div><h4 class="perks-item__title">You know who you work with</h4><div class="perks-item__desc"><p>Talk to the development team directly on your project Slack channel! We have daily contact, explaining the details of our work, sharing our knowledge and experience with your team. </p></div></div><div class="perks__item perks-item swiper-slider__slide slider__slide swiper-slide" data-slide-number="13" ><div class="perks-item__icon"><img class="lazy ovee-component entered loaded" data-lazy-load="" data-src="/user/themes/softwaremill/assets/svg/join-us/vbzsjqe68bok9yp.svg?g-bad3cb52" data-ll-status="loaded" src='images/why choose/14.jpg'/></div><h4 class="perks-item__title">Knowledge transfer to your team</h4>
                          <div class="perks-item__desc"><p>Discovering, collating and sharing knowledge across SoftwareMill and with our clients, is a natural way we operate. Growing together technically is the secret sauce clients value about the cooperation with us. </p></div></div><div class="perks__item perks-item swiper-slider__slide slider__slide swiper-slide" data-slide-number="14" ><div class="perks-item__icon">
                          <img class="lazy ovee-component entered loaded" data-lazy-load="" data-src="/user/themes/softwaremill/assets/svg/join-us/ywnucsaejxpov6j.svg?g-bad3cb52" data-ll-status="loaded" src='images/why choose/15.jpg'/></div><h4 class="perks-item__title">Committed team</h4><div class="perks-item__desc"><p>Our developers are supporting you on a daily basis with all the domain expertise that you need to make a project work. With empathy, trust, assertiveness, and focus on communication we can make great things together!</p></div></div><div class="perks__item perks-item swiper-slider__slide slider__slide swiper-slide" data-slide-number="15" ><div class="perks-item__icon"><img class="lazy ovee-component entered loaded" data-lazy-load="" data-src="/user/themes/softwaremill/assets/svg/join-us/cnkbmcq6rqiw0mf.svg?g-bad3cb52" data-ll-status="loaded" src='images/why choose/16.jpg'/></div><h4 class="perks-item__title">Certified dev talent</h4><div class="perks-item__desc">
                          <p>We passed +60 certifications from the hottest IT skills: Apache Kafka, Apache Cassandra, Reactive Architecture, Data Engineering, Java programming, Google Cloud, AWS Cloud, Kubernetes, Tensorflow. </p></div></div><div class="perks__item perks-item swiper-slider__slide slider__slide swiper-slide" data-slide-number="16" ><div class="perks-item__icon"><img class="lazy ovee-component entered loaded" data-lazy-load="" data-src="/user/themes/softwaremill/assets/svg/join-us/9bf54mlvw2peilw.svg?g-bad3cb52" data-ll-status="loaded" src='images/why choose/17.jpg'/></div><h4 class="perks-item__title">Recognized tech experts</h4><div class="perks-item__desc"><p>We are IT experts eager to share our know how on various tech events. In 2021 alone, we talked at DevoXX Poland, Build Stuff in Vilnius, ScalaCON, Scala Love in the City and Confitura.</p></div></div><div class="perks__item perks-item swiper-slider__slide slider__slide swiper-slide" data-slide-number="17" >
                          <div class="perks-item__icon"><img class="lazy ovee-component entered loaded" data-lazy-load="" data-src="/user/themes/softwaremill/assets/svg/join-us/uh95zueriaqnbfw.svg?g-bad3cb52" data-ll-status="loaded" src='images/why choose/18.jpg'/></div><h4 class="perks-item__title">Remote work precursors</h4><div class="perks-item__desc"><p>Need a helping hand and advice on transitioning a business to a remote model? We've been a remote-first company from day one and built proven remote plays to organize and accelerate your team’s work.</p></div></div><div class="perks__item perks-item swiper-slider__slide slider__slide swiper-slide" data-slide-number="18" ><div class="perks-item__icon"><img class="lazy ovee-component entered loaded" data-lazy-load="" data-src="/user/themes/softwaremill/assets/svg/join-us/numk7igahdxwj4z.svg?g-bad3cb52" data-ll-status="loaded" src='images/why choose/19.jpg'/></div><h4 class="perks-item__title">More than a working software</h4><div class="perks-item__desc">
                          <p>With our cross-domain expertise you're ready to embrace innovation. Gain a partner that shares cutting edge tech know-how, knows how to manage distributed projects and has a proven setup of remote communication. </p></div></div><div class="perks__item perks-item swiper-slider__slide slider__slide swiper-slide" data-slide-number="19" ><div class="perks-item__icon"><img class="lazy ovee-component entered loaded" data-lazy-load="" data-src="/user/themes/softwaremill/assets/svg/join-us/9mbw2qf81h6uvrg.svg?g-bad3cb52" data-ll-status="loaded" src='images/why choose/20.jpg'/></div><h4 class="perks-item__title">Self-organized team</h4><div class="perks-item__desc"><p>Our self-organised teams are used to taking an active role in shaping your company's technical core, as well as putting in the center of their efforts your business success and growth.</p></div></div><div class="perks__item perks-item swiper-slider__slide slider__slide swiper-slide" data-slide-number="20" ><div class="perks-item__icon"><img class="lazy ovee-component entered loaded exited" data-lazy-load="" data-src="/user/themes/softwaremill/assets/svg/join-us/4xaw6nwot8hiozj.svg?g-bad3cb52" data-ll-status="entered" src='images/why choose/21.jpg'/></div><h4 class="perks-item__title">Transparent communication</h4><div class="perks-item__desc"><p>Transparency, visibility, and seamless exchange of information. It’s much faster and easier to communicate, make decisions and discuss anything when our developers speak with you directly.</p></div></div><div class="perks__item perks-item swiper-slider__slide slider__slide swiper-slide" data-slide-number="21" >
                          <div class="perks-item__icon"><img class="lazy ovee-component entered loaded exited" data-lazy-load="" data-src="/user/themes/softwaremill/assets/svg/join-us/b6g3qmv0qmhzjr1.svg?g-bad3cb52" data-ll-status="entered" src='images/why choose/22.jpg'/></div><h4 class="perks-item__title">Agile software development</h4><div class="perks-item__desc"><p>We understand that only true cooperation will result in working software satisfying your needs. In our work, we follow agile methodologies – short 2-week sprints, clear deliverables, and visible progress.</p></div></div><div class="perks__item perks-item swiper-slider__slide slider__slide swiper-slide" data-slide-number="22" ><div class="perks-item__icon"><img class="lazy ovee-component entered loaded exited" data-lazy-load="" data-src="/user/themes/softwaremill/assets/svg/join-us/igj1f7dyszkymwu.svg?g-bad3cb52" data-ll-status="entered" src='images/why choose/23.jpg'/></div><h4 class="perks-item__title">A dedicated Delivery Manager</h4><div class="perks-item__desc"><p>We have an experienced team of Business Reps, who will guide your project to the right direction. Your dedicated Delivery Manager will do everything to maximise the development process efficiency.</p></div></div><div class="perks__item perks-item swiper-slider__slide slider__slide swiper-slide" data-slide-number="23" >
                          <div class="perks-item__icon"><img class="lazy ovee-component entered loaded exited" data-lazy-load="" data-src="/user/themes/softwaremill/assets/svg/join-us/qltxwu5yg9obcem.svg?g-bad3cb52" data-ll-status="entered" src='images/why choose/24.jpg'/></div><h4 class="perks-item__title">Support of DevOps</h4><div class="perks-item__desc"><p>Build secure software from the start. Develop, test, and release software faster and more reliably with our DevOps talents.</p></div></div><div class="perks__item perks-item swiper-slider__slide slider__slide swiper-slide" data-slide-number="24" ><div class="perks-item__icon"><img class="lazy ovee-component entered loaded exited" data-lazy-load="" data-src="/user/themes/softwaremill/assets/svg/join-us/ywpchrtdz5i47u9.svg?g-bad3cb52" data-ll-status="entered" src='images/why choose/25.jpg'/></div><h4 class="perks-item__title">Support of QA</h4><div class="perks-item__desc"><p>We develop use-cases and test scenarios, perform functional, security, performance and automated software testing on all applications we build for clients.</p></div></div></div></div></div></section>
    </div>
  )
}

export default Why